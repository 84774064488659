const translation = {
  attributes: {
    attribute: "Attributo",
    attributes: "Attributi",
    label: "Etichetta",
    value: "Valore",
    type: "Tipo",
    types: {
      text: "Testo",
      number: "Numero",
      date: "Data",
      time: "Ora",
      select: "Seleziona",
      errors: {
        number: "Il valore deve essere un numero valido",
        date: "Il valore deve essere una data valida",
        time: "Il valore deve essere un orario valido",
      },
    },
  },
  activities: {
    activity: "Attività",
    order: {
      link: {
        opened: "Collegamento per l’ordine n. {{number}} aperto",
      },
      accepted: "L’ordine n. {{number}} è stato accettato",
      rejected: "L’ordine n. {{number}} è stato rifiutato",
      changed: "L’ordine n. {{number}} è stato modificato",
      field: {
        changed:
          "$t({{field_t_key}}) è stato modificato da $t({{before}}, '') $t({{suffix}}, '') a $t({{after}}, '') $t({{suffix}}, '')",
      },
      replaced:
        "Il numero d'ordine {{number}} è stato sostituito dall'acquirente. La nuova versione è disponibile al seguente<a class='btn btn-xs btn-light' alt='link' href='{{host.url}}ui/orders/{{descendant_slug}}'><i class='fa fa-link'></i> Link</a>",
      replaces:
        "Il numero d'ordine {{number}} è stato sostituito dall'acquirente. La versione precedente è disponibile al seguente<a class='btn btn-xs btn-light' alt='link' href='{{host.url}}ui/orders/{{ancestor_slug}}'><i class='fa fa-link'></i> Link</a>",
      canceled: "L’ordine n. {{number}} è stato annullato dall’acquirente",
      upload: "{{filename}} è stato caricato",
      upload_delete: "{{filename}} è stato eliminato",
      allowances_and_charges: {
        added:
          "{{value}} {{suffix}} $t({{aoc_t_key}}) con $t({{seq_t_key}}) n. {{seq}} e $t(common.note) $t({{note}}, 'common.empty')",
      },
      item: {
        changed:
          "L’articolo n. {{number}} nella posizione {{line}} è stato modificato",
        field: {
          changed:
            "$t({{field_t_key}}) è stato modificato da $t({{before}}, '') $t({{suffix}}, '') a $t({{after}}, '') $t({{suffix}}, '')",
        },

        partial: {
          added:
            "Consegna parziale modificata nella posizione $t({{pos}}, '') $t(common.note) $t({{note}}, 'common.empty') $t({{quantity}}, '') $t({{suffix}}, '') in $t({{delivery_at}}, '')",
        },
        allowances_and_charges: {
          added:
            "{{value}} {{suffix}} $t({{aoc_t_key}}) con $t({{seq_t_key}}) n. {{seq}} applicato a $t({{apply_t_key}}) con $t(common.note) $t({{note}}, 'common.empty')",
        },
        attributes: {
          added:
            "È stato aggiunto l'attributo {{label}} con il valore {{value}}",
          changed:
            "È stato modificato l'attributo {{label}} con il valore {{value}}",
          deleted:
            "È stato eliminato l'attributo {{label}} con il valore {{value}}",
        },
      },
      notification: {
        resend:
          "La notifica dell'ordine è stata reinviata al destinatario {{recipient}} ",
      },
    },
  },
  despatch_advices: {
    shipmentNumber: "ID spedizione",
    shipmentUrl: "URL di tracciamento",
    delivery_address: "Consegna",
    order_items: "Ordina articoli",
    addresses: "Indirizzi",
    delete_despatch_advice: "Eliminare gli avvisi di spedizione?",
    despatch_advices: "Avvisi di spedizione",
    despatch_advice: "Avviso di spedizione",
    number: "Numero ASN",
    delivery_date: "Data di consegna",
    ship_date: "Data di spedizione",
    supplier: "Fornitore",
    buyer: "Acquirente",
    description: "Descrizione",
    item_number: "N. articolo",
    unit: "Unità",
    quantity: "Quantità",
    create_by_items: "Crea per Articoli",
    create_by_delivery_date: "Crea per Data di consegna",
    create_empty: "Crea vuoto",
    item: "Articolo",
    items: "Articoli",
    create_new: "Nuovo",
    reference: "Riferimento",
    reference_date: "Data riferimento",
    help: {
      1: "Articoli restanti per la consegna",
      2: "Articoli confermati per la consegna",
      3: "Quantità di questo avviso di spedizione",
    },
    permissions: {
      edit: {
        false: "Questo avviso di spedizione è bloccato per la modifica",
      },
    },
  },
  orders: {
    orders: "Ordini",
    orders_overview: "Panoramica degli ordini",
    overview: {
      responses: "Conferme",
      tab: {
        info: "Il campo della ricerca consente di eseguire ricerche in base al numero dell’ordine o al numero dell’ordine del fornitore. Le azioni disponibili sono visualizzazione, inoltro e reinvio dell'ordine.",
        info_auth:
          "Il campo di ricerca consente di effettuare una ricerca in base al numero d'ordine, al numero d'ordine del fornitore o al nome del fornitore. Le azioni consentono di visualizzare, inoltrare o inviare nuovamente l'ordine.",
      },
      menu: {
        overview: "Panoramica degli ordini",
        all: "Tutto",
        due: "Scaduto",
        pending: "In sospeso",
        state: "Stato",
        rejected: "Rifiutato",
        accepted: "Accettato",
        replaced: "Sostituito",
        canceled: "Annullato",
        open_order: "Visualizza ordine",
        resent_notification: "Reinvio notifica dell'ordine",
        supplier_recipients: "Destinatario",
      },
      resend_notification: "Notifica dell'ordine",
    },
    permissions: {
      edit: {
        false: "Questo ordine è bloccato per la modifica",
      },
    },
    changes: {
      replaced: {
        current:
          "Questo ordine è stato modificato dall’acquirente. <br/>L’ordine originale è ancora disponibile in sola lettura tramite il menu Attività.",
        ancestor:
          "Questo ordine è stato sostituito da un nuovo ordine dell’acquirente.",
      },
      canceled: "Questo ordine è stato annullato dall’acquirente.",
    },
    items: "Ordina articoli",
    order: "Ordine",
    number: "N. ordine",
    date: "Data ordine",
    supplier: "Fornitore",
    supplier_order_number: "N. ordine fornitore",
    buyer: "Acquirente",
    invoice_address: "Fattura",
    delivery_address: "Consegna",
    description: "Descrizione",
    item_number: "N. articolo",
    unit: "Unità",
    quantity: "Quantità",
    sum: "Somma",
    total: "Totale",
    net: "Netto",
    gross: "Lordo",
    price: "Prezzo",
    price_unit: "Unità di prezzo",
    total_quantity: "Quantità totale",

    partial_deliveries: "Consegna parziale",
    partial_delivery_at: "Consegna parziale",
    partial_delivery_title:
      "Consegna parziale dell’articolo nella riga {{line}} con numero",
    item_edit_modal_header_title: "Articolo nella riga {{line}} con numero",
    delivery_date: "Data di consegna",

    confirm_order: "Desideri confermare l’ordine?",
    reject_order: "Desideri rifiutare l’ordine?",
    response: "Risposta",

    change_delivery_date:
      "Modificare la data di consegna originale {{original_start_date}}",
    change_delivery_date_hover: "Modifica data di consegna",

    please_enter_supplier_order_number:
      "Immettere il numero dell’ordine del fornitore",

    order_response_created:
      "La conferma dell’ordine è stata creata correttamente",

    notifications: {
      title: "Notifiche",
      type: "Tipo",
      address: "Indirizzo",
      subject: "Oggetto",
      status: "Stato",
      sent: "Inviato",
      delivered: "Consegnato",
      description: "Descrizione",
      info: "Confermare o modificare il destinatario della notifica dell'ordine.",
      resend: "Reinviare la notifica dell'ordine?",
      resend_alert:
        "È stata inviata correttamente una nuova notifica per l'ordine {{number}}",
    },

    demo: {
      title:
        "Ti interessa una soluzione compatta per i tuoi processi di ordinazione?",
      description:
        "Attraverso un esempio di ordine, il portale WebConnect ti dimostra quanto sia facile utilizzare il servizio di e-procurement e gestire gli ordini all’interno della sua interfaccia ordinata. I tuoi fornitori possono vedere senza difficoltà i prodotti che sono stati ordinati, lasciare messaggi e confermare/rifiutare ogni ordine o anche apportare modifiche alle singole posizioni.\n" +
        "Concediti un momento e guarda con i tuoi occhi quanto sia facile e comodo da usare il portale WebConnect: il servizio è veloce, automatico, estremamente facile da utilizzare e non richiede un login.",

      form: {
        footer: "Username: demo, Password: demo",
        title:
          "È sufficiente inserire l’indirizzo e-mail* per accedere alla demo live di Retarus WebConnect for Suppliers:",
        email: "Il tuo indirizzo e-mail",
      },

      ol: {
        title:
          "Durante la dimostrazione verranno inviati tre messaggi al tuo indirizzo e-mail di registrazione: ",
        1: "Un esempio di e-mail d’ordine, che illustra come i fornitori ricevono gli ordini che sono stati effettuati.",
        2: "Un messaggio di conferma, come quello che ricevono i tuoi fornitori una volta che hanno modificato o confermato l’ordine nel portale WebConnect.",
        3: "Un esempio di messaggio che spiega il monitoraggio degli ordini aperti in un periodo di tempo definito. Nell’applicazione effettiva del servizio, saranno i tuoi dipendenti appartenenti all’ufficio acquisti a ricevere queste e-mail.",
        footer:
          "Ci auguriamo che tu possa trarre il meglio da WebConnect for Suppliers!",
      },

      footer: {
        title:
          "Per qualsiasi domanda su WebConnect for Suppliers, non esitare a <0>contattarci</0> in qualsiasi momento.",
      },

      quote: {
        1: "*L’indirizzo e-mail fornito verrà utilizzato esclusivamente ai fini della dimostrazione del servizio WebConnect for Suppliers di Retarus e non verrà salvato o utilizzato per scopi di marketing.",
      },

      items: {
        1: {
          unit: "Pezzo",
          description:
            "Cinghia trapezoidale ad alte prestazioni SUPER XE-POWER PRO XPA 1180 Lw (1135 Li / 1198 La) a fianchi aperti, dentellata",
        },
        2: {
          unit: "Pezzo",
          description:
            "Giunzione flessibile per cinghie trapezoidali aperte Z/10",
        },
        3: {
          unit: "Pezzo",
          description:
            "Cinghie trapezoidali classiche DIN2215 / ISO4184 A55 13 x 1400 Li (1430 Lw / 1450 La) coperte; costante definita (S=C PLUS)",
        },
        4: {
          unit: "Pezzo",
          description: "Cinghie trapezoidali aperte DIN2216, forate A/13",
        },
      },
    },

    uploads: {
      click_me: "Fai clic qui per caricare i file",
      delete_btn: "Elimina file caricato",
    },

    vat: "IVA",
    vat_id: "Partita IVA",

    item: "Articolo",

    allowances_and_charges: "Detrazioni e spese",
    amount: "Importo",
  },
  form: {
    message_placeholder: "Lascia un messaggio...",
    confirm: "Conferma",
    confirmed: "Confermato",
    reject: "Rifiuta",
    rejected: "Rifiutato",
    save: "Salva",
    cancel: "Annulla",
    send: "Invia",

    placeholder: {
      email: "Il tuo indirizzo e-mail",
    },
  },

  common: {
    demo: "Demo",
    from: "Da",
    to: "a",

    overview: "Panoramica",
    actions: "Azioni",
    modified: "Modificato",
    reload: "Ricarica",
    restore: "Ripristina",
    advised: "Consigliato",
    current: "Corrente",
    ordered: "Ordinato",
    confirmed: "Confermato",
    delivered: "Consegnato",
    not_available: "N/D",
    add: "Aggiungi",
    search: "Cerca",
    committed: "Confermati",
    remaining: "Restanti",
    delete: "Elimina",
    completed: "Completati",
    change_lang: "Cambia lingua",
    date: "Data",
    note: "Nota",
    attachments: "Download",
    uploads: "Upload",
    at: "il",
    print: "Stampa",
    imprint: "Imprint",
    privacy_policy: "Informativa sulla privacy",
    number: "Numero",
    additional: "Aggiuntivo",
    original: "Originale",
    value: "Valore",
    empty: "Vuoto",
    allowance: "Detrazione",
    charge: "Spesa",
    subtotal: "Subtotale",

    sequence: "Sequenza",
    description: "Descrizione",
    apply: "Applica",
    type: "Tipo",
    per: "per",
    discount: "Sconto",
    freight_charge: "Spesa di trasporto",
    edit: "Modifica",
    please_enter: "Immettere",
    ean: "EAN",
    draft: "Bozza",
    number_of_decimal_places: "Numero di cifre decimali",
    submit: "Invia",
  },

  errors: {
    not_found: "Risorsa non trovata",
    token_expired:
      "Il collegamento non è più valido. Accedere nuovamente alla panoramica degli ordini attraverso un ordine individuale.",
    rate_limit_reached: "Limite tariffa raggiunto",
    email: "{{attr}} deve corrispondere a un indirizzo e-mail valido",
    url: "{{attr}} deve corrispondere a un URL valido",
    uuid: "{{attr}} deve corrispondere a un UUID valido",
    lowercase: "{{attr}} deve essere in lettere minuscole",
    uppercase: "{{attr}} deve essere in lettere maiuscole",
    integer: "{{attr}} deve essere un numero intero",
    positive: "{{attr}} deve essere un numero positivo",
    negative: "{{attr}} deve essere un numero negativo",
    matches: "{{attr}} deve corrispondere allo schema {{value}}",
    length: "{{attr}} deve essere di {{value}} caratteri",
    eq: "{{attr}} deve essere uguale a {{value}}",
    gt: "{{attr}} deve essere superiore a {{value}}",
    gte: "{{attr}} deve essere superiore o uguale a {{value}}",
    lte: "{{attr}} deve essere inferiore o uguale a {{value}}",
    valid: "{{type}} valido richiesto",
    required: "{{attr}} è richiesto",
    irregular: {
      "too many files": "Troppi file",
    },
  },
};

export default translation;

import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

export default function Language() {
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);

  const codes = ["de", "en", "it", "es", "fr", "th"]
    .filter((code) => !i18n.language.startsWith(code))
    .map((code) => (
      <li key={code}>
        <button
          className="btn"
          style={{ lineHeight: 0 }}
          onClick={() => i18n.changeLanguage(code) && setShow((p) => !p)}
        >
          <i
            className={`flag-icon flag-icon-${code === "en" ? "gb" : code} m-n`}
          />
        </button>
      </li>
    ));

  return (
    <Fragment>
      <button
        onClick={() => setShow((p) => !p)}
        //onBlur={() => setShow(p => !p)}
        data-toggle="tooltip"
        title={t("common.change_lang")}
        className="btn"
      >
        <i
          className={`flag-icon flag-icon-${
            i18n.language.substring(0, 2) === "en"
              ? "gb"
              : i18n.language.substring(0, 2)
          }`}
          style={{ fontSize: "1.5rem" }}
        />
      </button>
      <ul
        className={`dropdown-menu ${show ? "show" : ""} text-center`}
        style={{ minWidth: "3rem", fontSize: "1.5rem", right: "unset" }}
      >
        {codes}
      </ul>
    </Fragment>
  );
}

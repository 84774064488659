const translation = {
  attributes: {
    attribute: "Atributo",
    attributes: "Atributos",
    label: "Etiqueta",
    value: "Valor",
    type: "Tipo",
    types: {
      text: "Texto",
      number: "Número",
      date: "Fecha",
      time: "Hora",
      select: "Seleccionar",
      errors: {
        number: "El valor debe ser un número válido",
        date: "El valor debe ser una fecha válida",
        time: "El valor debe ser una hora válida",
      },
    },
  },
  activities: {
    activity: "Actividades",
    order: {
      link: {
        opened: "Se ha abierto el enlace al n.º de pedido {{number}}",
      },
      accepted: "Se ha aceptado el n.º de pedido {{number}}",
      rejected: "Se ha rechazado el n.º de pedido {{number}}",
      changed: "Se ha modificado el n.º de pedido {{number}}",
      field: {
        changed:
          "$t({{field_t_key}}) modificado de $t({{before}}, '') $t({{suffix}}, '') a $t({{after}}, '') $t({{suffix}}, '')",
      },
      replaced:
        'El número de pedido {{number}} ha sido sustituido por comprador. Nueva versión disponible en el siguiente enlace<a class="btn btn-xs btn-light" alt="link" href="{{host.url}}ui/orders/{{descendant_slug}}"><i class="fa fa-link"></i> Link</a>',
      replaces:
        'El número de pedido {{number}} ha sido sustituido por el comprador. Versión anterior disponible en el siguiente enlace<a class="btn btn-xs btn-light" alt="link" href="{{host.url}}ui/orders/{{ancestor_slug}}"><i class="fa fa-link"></i> Link</a>',
      canceled: "El comprador ha cancelado el n.º de pedido {{number}}",
      upload: "{{filename}} cargado",
      upload_delete: "{{filename}} eliminado",
      allowances_and_charges: {
        added:
          "{{value}} {{suffix}} $t({{aoc_t_key}}) con $t({{seq_t_key}}) n.º {{seq}} y $t(common.note) $t({{note}}, 'common.empty')",
      },
      item: {
        changed:
          "Se ha modificado el n.º de artículo {{number}} en la posición {{line}}",
        field: {
          changed:
            "$t({{field_t_key}}) modificado de $t({{before}}, '') $t({{suffix}}, '') a $t({{after}}, '') $t({{suffix}}, '')",
        },

        partial: {
          added:
            "Entrega parcial modificada en la posición $t({{pos}}, '') $t(common.note) $t({{note}}, 'common.empty') $t({{quantity}}, '') $t({{suffix}}, '') en $t({{delivery_at}}, '')",
        },
        allowances_and_charges: {
          added:
            "{{value}} {{suffix}} $t({{aoc_t_key}}) con $t({{seq_t_key}}) n.º {{seq}} aplicada en $t({{apply_t_key}}) con $t(common.note) $t({{note}}, 'common.empty')",
        },
        attributes: {
          added: "Se ha añadido el atributo {{label}} con valor {{value}}",
          changed: "Se ha modificado el atributo {{label}} con valor {{value}}",
          deleted: "Se ha eliminado el atributo {{label}} con valor {{value}}",
        },
      },
      notification: {
        resend:
          "Se ha enviado una notificación del pedido al destinatario {{recipient}} ",
      },
    },
  },
  despatch_advices: {
    shipmentNumber: "ID del envío",
    shipmentUrl: "URL de seguimiento",
    delivery_address: "Entrega",
    order_items: "Solicitar artículos",
    addresses: "Direcciones",
    delete_despatch_advice: "¿Eliminar avisos de expedición?",
    despatch_advices: "Avisos de expedición",
    despatch_advice: "Aviso de expedición",
    number: "Número ASN",
    delivery_date: "Fecha de entrega",
    ship_date: "Fecha de envío",
    supplier: "Proveedor",
    buyer: "Comprador",
    description: "Descripción",
    item_number: "N.º de artículo",
    unit: "Unidad",
    quantity: "Cantidad",
    create_by_items: "Crear por artículos",
    create_by_delivery_date: "Crear por fecha de entrega",
    create_empty: "Crear vacío",
    item: "Artículo",
    items: "Artículos",
    create_new: "Nuevo",
    reference: "Referencia",
    reference_date: "Fecha de referencia",
    help: {
      1: "Artículos restantes para la entrega",
      2: "Artículos confirmados para la entrega",
      3: "Cantidad para este aviso de expedición",
    },
    permissions: {
      edit: {
        false: "Este aviso de expedición está bloqueado para edición",
      },
    },
  },
  orders: {
    orders: "Pedidos",
    orders_overview: "Información general de pedidos",
    overview: {
      responses: "Confirmaciones",
      tab: {
        info: "El campo de búsqueda permite buscar por número de pedido o número de pedido del proveedor. Las acciones permiten ver, redireccionar o reenviar el pedido.",
        info_auth:
          "El campo de búsqueda permite buscar por número de pedido, número de pedido del proveedor o nombre del proveedor. Las acciones le permiten ver, reenviar o reenviar el pedido.",
      },
      menu: {
        overview: "Información general de pedidos",
        all: "Todos",
        due: "Caducado",
        pending: "Pendiente",
        state: "Estado",
        rejected: "Rechazado",
        accepted: "Aceptado",
        replaced: "Sustituido",
        canceled: "Cancelado",
        open_order: "Ver pedido",
        resent_notification: "Reenviar notificación del pedido",
        supplier_recipients: "Destinatario",
      },
      resend_notification: "Notificación del pedido",
    },
    permissions: {
      edit: {
        false: "Este pedido está bloqueado para edición",
      },
    },
    changes: {
      replaced: {
        current:
          "Este pedido ha sido modificado por el comprador. <br/>El pedido original sigue estando disponible con acceso de solo lectura a través del menú Actividades.",
        ancestor:
          "Este pedido ha sido sustituido por un nuevo pedido del comprador.",
      },
      canceled: "Este pedido ha sido cancelado por el comprador.",
    },
    items: "Solicitar artículos",
    order: "Pedido",
    number: "N.º de pedido",
    date: "Fecha de pedido",
    supplier: "Proveedor",
    supplier_order_number: "N.º de pedido de proveedor",
    buyer: "Comprador",
    invoice_address: "Factura",
    delivery_address: "Entrega",
    description: "Descripción",
    item_number: "N.º de artículo",
    unit: "Unidad",
    quantity: "Cantidad",
    sum: "Suma",
    total: "Total",
    net: "Neto",
    gross: "Bruto",
    price: "Precio",
    price_unit: "Precio unitario",
    total_quantity: "Cantidad total",

    partial_deliveries: "Entrega parcial",
    partial_delivery_at: "Entrega parcial",
    partial_delivery_title:
      "Entrega parcial del artículo en la línea {{line}} con número",
    item_edit_modal_header_title: "Artículo en la línea {{line}} con número",
    delivery_date: "Fecha de entrega",

    confirm_order: "¿Está seguro de que desea confirmar el pedido?",
    reject_order: "¿Está seguro de que desea rechazar el pedido?",
    response: "Responder",

    change_delivery_date:
      "Modificar fecha de entrega original {{original_start_date}}",
    change_delivery_date_hover: "Modificar fecha de entrega",

    please_enter_supplier_order_number:
      "Introduzca el número de pedido del proveedor",

    order_response_created:
      "La confirmación del pedido se ha creado correctamente",

    notifications: {
      title: "Notificaciones",
      type: "Tipo",
      address: "Dirección",
      subject: "Asunto",
      status: "Estado",
      sent: "Enviado",
      delivered: "Entregado",
      description: "Descripción",
      info: "Confirme o modifique el destinatario de la notificación del pedido.",
      resend: "¿Seguro que desea reenviar la notificación del pedido?",
      resend_alert:
        "Se ha enviado correctamente una nueva notificación del pedido {{number}}",
    },

    demo: {
      title:
        "¿Está interesado en una solución compacta para sus procesos de pedido?",
      description:
        "Utilizando un pedido como muestra, le demostraremos lo fácil que es utilizar el servicio de aprovisionamiento electrónico y gestionar sus pedidos dentro de su ordenada interfaz a través del portal WebConnect. Sus proveedores pueden ver cómodamente los productos que se han pedido, dejar mensajes y tener la posibilidad de confirmar/rechazar cada pedido o incluso realizar cambios en posiciones individuales\n" +
        "Tómese un momento para ver lo fácil y cómodo que es el portal WebConnect, con un servicio rápido, automatizado, extremadamente fácil de usar y que no requiere un inicio de sesión.",

      form: {
        footer: "Username: demo, Password: demo",
        title:
          "Introduzca su dirección de correo electrónico* para acceder a la demostración en directo de WebConnect for Suppliers de Retarus:",
        email: "Su dirección de correo electrónico",
      },

      ol: {
        title:
          "Como parte de la demostración, le enviaremos tres mensajes a la dirección de correo electrónico que ha registrado: ",
        1: "Un ejemplo de un correo electrónico de pedido, que ilustra cómo sus proveedores reciben los pedidos que se han realizado.",
        2: "Un mensaje de confirmación como el que reciben sus proveedores una vez que han editado o confirmado el pedido en el portal WebConnect.",
        3: "Un ejemplo de un mensaje que muestra el seguimiento de los pedidos abiertos dentro de un periodo de tiempo definido. En la aplicación real de este servicio, sus empleados del departamento de compras recibirán estos correos electrónicos.",
        footer: "Le deseamos mucho éxito con WebConnect for Suppliers.",
      },

      footer: {
        title:
          "Si tiene más preguntas sobre WebConnect for Suppliers, no dude en ponerse en <0>contacto con nosotros</0> en cualquier momento.",
      },

      quote: {
        1: "Puede estar seguro de que utilizamos su dirección de correo electrónico exclusivamente para realizar demostraciones de WebConnect for Suppliers de Retarus; no se guardará ni se utilizará con fines de marketing.",
      },

      items: {
        1: {
          unit: "Pieza",
          description:
            "Correa trapezoidal de alto rendimiento SUPER XE-POWER PRO XPA 1180 Lw (1135 Li / 1198 La) borde sin tratar, dentado moldeado",
        },
        2: {
          unit: "Pieza",
          description:
            "Conector flexible para correas trapezoidales abiertas Z/10",
        },
        3: {
          unit: "Pieza",
          description:
            "Correas trapezoidales clásicas DIN2215 / ISO4184 A55 13 x 1400 Li (1430 Lw / 1450 La) revestidas; tarifa constante (S=C PLUS)",
        },
        4: {
          unit: "Pieza",
          description:
            "Correas trapezoidales abiertas DIN2216, perforadas A/13",
        },
      },
    },

    uploads: {
      click_me: "Haga clic aquí para cargar los archivos",
      delete_btn: "Borrar el archivo cargado",
    },

    vat: "IVA",
    vat_id: "Número de IVA",

    item: "Artículo",

    allowances_and_charges: "Descuentos y recargos",
    amount: "Cantidad",
  },
  form: {
    message_placeholder: "Deje su mensaje...",
    confirm: "Confirmar",
    confirmed: "Confirmado",
    reject: "Rechazar",
    rejected: "Rechazado",
    save: "Guardar",
    cancel: "Cancelar",
    send: "Enviar",

    placeholder: {
      email: "Su dirección de correo electrónico",
    },
  },

  common: {
    demo: "Demo",
    from: "De",
    to: "a",

    overview: "Información general",
    actions: "Acciones",
    modified: "Modificado",
    reload: "Volver a cargar",
    restore: "Restaurar",
    advised: "Avisado",
    current: "Actual",
    ordered: "Pedido",
    confirmed: "Confirmado",
    delivered: "Entregado",
    not_available: "N/A",
    add: "Agregar",
    search: "Buscar",
    committed: "Confirmados",
    remaining: "Restantes",
    delete: "Eliminar",
    completed: "Completados",
    change_lang: "Cambiar idioma",
    date: "Fecha",
    note: "Nota",
    attachments: "Descargas",
    uploads: "Cargas",
    at: "el",
    print: "Imprimir",
    imprint: "Pie de imprenta",
    privacy_policy: "Política de privacidad",
    number: "Número",
    additional: "Adicional",
    original: "Original",
    value: "Valor",
    empty: "Vacío",
    allowance: "Descuento",
    charge: "Recargo",
    subtotal: "Subtotal",

    sequence: "Secuencia",
    description: "Descripción",
    apply: "Aplicar",
    type: "Tipo",
    per: "por",
    discount: "Rebaja",
    freight_charge: "Costes de transporte",
    edit: "Editar",
    please_enter: "Introduzca",
    ean: "EAN",
    draft: "Borrador",
    number_of_decimal_places: "Número de posiciones decimales",
    submit: "Enviar",
  },

  errors: {
    not_found: "Recurso no encontrado",
    token_expired:
      "El enlace ya no es válido. Vuelva a acceder a la información general de pedidos desde un pedido individual.",
    rate_limit_reached: "Límite de tarifa alcanzado",
    email:
      "{{attr}} debe corresponder con una dirección de correo electrónico válida",
    url: "{{attr}} debe corresponder con una URL válida",
    uuid: "{{attr}} debe corresponder con una UUID válida",
    lowercase: "{{attr}} debe escribirse en minúsculas",
    uppercase: "{{attr}} debe escribirse en mayúsculas",
    integer: "{{attr}} debe ser un número entero",
    positive: "{{attr}} debe ser un número positivo",
    negative: "{{attr}} debe ser un número negativo",
    matches: "{{attr}} debe corresponder con el esquema {{value}}",
    length: "{{attr}} debe contener {{value}} caracteres",
    eq: "{{attr}} debe ser igual que {{value}}",
    gt: "{{attr}} debe ser mayor que {{value}}",
    gte: "{{attr}} debe ser mayor o igual que {{value}}",
    lte: "{{attr}} debe ser menor o igual que {{value}}",
    valid: "Se requiere {{type}} válido",
    required: "Se requiere {{attr}}",
    irregular: {
      "too many files": "Para muchos archivos",
    },
  },
};

export default translation;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { DEV_MODE } from "../../constants";
import { ModalFooter } from "../../layout/Modal";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { formatNumber } from "../../translations/intl";
import { changeDesadvItem } from "./ducks/itemSlices";

const createInitialValues = (item) => ({
  number: item.number,
  //quantity: formatNumber(item.quantity.current, 0, 20, false).replace(",", "."),
  quantity: formatNumber(item.quantity.current, 0),
  error: null,
});

const createValidateSchema = (item, t) =>
  Yup.object().shape({
    number: Yup.string()
      .trim()
      .max(
        64,
        t("errors.lte", {
          attr: t("despatch_advices.item_number"),
          value: 256,
        })
      )
      .required(
        t("errors.required", {
          attr: t("despatch_advices.item_number"),
        })
      ),
    //row: Yup.date().required(),
    quantity: Yup.number()
      //.required(t("errors.required", {attr: t("despatch_advices.quantity")}))
      .moreThan(0)
      .max(
        Number(item.quantity.order.remaining) + Number(item.quantity.current),
        t("errors.lte", {
          attr: t("despatch_advices.quantity"),
          value:
            Number(item.quantity.order.remaining) +
            Number(item.quantity.current),
        })
      )
      .typeError(t("errors.valid", { type: t("common.number") })),
    //quantity: Yup.number()
    //.required(t("errors.required", {attr: t("despatch_advices.quantity")}))
    //.max(
    //    item.quantity.remaining,
    //    t("errors.lte", {
    //        attr: t("despatch_advices.quantity"),
    //        value: item.quantity.remaining,
    //    })
    //)
  });

export default function DespatchAdviceItemEditTab({ desadv, item, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (values, { setErrors }) => {
    const resultAction = await dispatch(
      changeDesadvItem({
        slug: desadv.slug,
        item: item,
        quantity: values.quantity,
      })
    );
    if (changeDesadvItem.fulfilled.match(resultAction)) {
      onClose();
    } else {
      if (resultAction.payload) {
        setErrors({ error: resultAction.payload.message });
      }
    }
  };

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={createInitialValues(item)}
      onSubmit={handleSubmit}
      validationSchema={createValidateSchema(item, t)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form>
          <ModalBody>
            <div className="container">
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  <span className="text-danger">*</span>{" "}
                  {t("despatch_advices.item_number")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={"input-group " + (errors.number && "has-error")}
                  >
                    <Field
                      name="number"
                      disabled={true}
                      className={`form-control ${
                        errors.number ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="number"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("despatch_advices.quantity")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={
                      "input-group " + (errors.reference && "has-error")
                    }
                  >
                    <Field
                      name="quantity"
                      className={`form-control ${
                        errors.quantity ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="quantity"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
                <div
                  className="p-1 col-1 text-right my"
                  data-toggle="tooltip"
                  title={t("despatch_advices.help.1")}
                >
                  <span className="font-italic">
                    ( {t("common.remaining")}:{" "}
                    {formatNumber(item.quantity.order.remaining, 0)}
                    {" | "}
                    {t("common.committed")}:{" "}
                    {formatNumber(item.quantity.order.committed, 0)} )
                  </span>
                </div>
              </div>
            </div>
            {DEV_MODE && (
              <pre
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}

            {!!errors.error && (
              <div className="alert alert-danger m-t-sm">{errors.error}</div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="btn-group">
              <button className="btn btn-primary" type={"submit"}>
                <i className="fa fa-save" /> {t("form.save")}
              </button>
              <button className="btn btn-danger" onClick={() => onClose()}>
                <i className="fa fa-close" /> {t("form.cancel")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

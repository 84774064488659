/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createDespatchAdvices } from "../despatch_advices/ducks/slice";
import { useEffect } from "react";
import { fetchBySlug } from "./ducks/slice";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";

export default function OrderDespatchAdvices() {
  const { t } = useTranslation();
  const { slug } = useParams();
  const history = useHistory();

  const order = useSelector((state) => state.orders.entities[slug]);

  const dispatch = useDispatch();

  if (!order._features.despatch_advice) {
    <span>Feature is not available</span>;
  }

  useEffect(() => {
    dispatch(fetchBySlug({ slug }));
  }, []);

  const handleSubmit = async (create_by_delivery_date, create_empty) => {
    const resultAction = await dispatch(
      createDespatchAdvices({
        order_slug: order.slug,
        create_by_delivery_date: create_by_delivery_date,
        create_empty: create_empty,
      }),
    );
    if (createDespatchAdvices.fulfilled.match(resultAction)) {
      history.push(`/despatch_advices/${resultAction.payload[0].slug}`);
    }
  };

  if (!order) {
    return <Spinner />;
  }

  return (
    <div className="text-center">
      {order.state === "BUYER_REPLACED" && (
        <div className="col-md-12 m-t-sm">
          <div className="alert alert-danger">
            {t("orders.changes.replaced.ancestor")}
          </div>
        </div>
      )}
      {order.state === "BUYER_CANCELED" && (
        <div className="col-md-12 m-t-sm">
          <div className="alert alert-danger">
            {t("orders.changes.canceled")}
          </div>
        </div>
      )}

      <div className="btn-group">
        <button
          className="btn btn-outline-success"
          type={"button"}
          disabled={!order._gui.permission.edit}
          onClick={() => handleSubmit(true, false)}
        >
          {t("despatch_advices.create_by_delivery_date")}
        </button>
        <button
          className="btn btn-outline-success"
          type={"button"}
          disabled={!order._gui.permission.edit}
          onClick={() => handleSubmit(false, false)}
        >
          {t("despatch_advices.create_by_items")}
        </button>

        <button
          className="btn btn-outline-success"
          type={"button"}
          disabled={!order._gui.permission.edit}
          onClick={() => handleSubmit(false, true)}
        >
          {t("despatch_advices.create_empty")}
        </button>
      </div>
    </div>
  );
}

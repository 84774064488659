import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import request from "../../../requests";

export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password, otp }, { rejectWithValue }) => {
    try {
      const response = await request
        .post("/api/v1/auth/login")
        .field("email", email)
        .field("password", password)
        .field("otp", otp);
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  const response = await request.post("/api/v1/auth/logout");
  return response.body;
});

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async ({ email, otp }, { rejectWithValue }) => {
    try {
      const response = await request
        .post("/api/v1/auth/forgot_password")
        .field("email", email)
        .field("otp", otp);
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ password, password_confirm, otp, token }, { rejectWithValue }) => {
    try {
      const response = await request
        .post(`/api/v1/auth/reset_password?t=${token}`)
        .field("password", password)
        .field("password_confirm", password_confirm)
        .field("otp", otp);

      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const fetchMe = createAsyncThunk("auth/fetchMe", async () => {
  const response = await request.get("/api/v1/auth/me").accept("json");
  return response.body;
});

export const fetchAuthUrl = createAsyncThunk("auth/fetchAuthUrl", async ({state}) => {
  const response = await request.get(`/api/v1/auth/url?state=${state}`).accept("json");
  return response.body;
});

const initialState = {
  me: null,
  pending: true,
  url: null,
};

export const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    //[login.pending]: (state, action) => {},
    [login.fulfilled]: (state, action) => {
      state.me = action.payload;
    },
    //[logout.pending]: (state, action) => {},
    [logout.fulfilled]: (state, action) => {
      state.me = null;
      state.pending = false;
    },
    [logout.rejected]: (state, action) => {
      state.me = null;
    },
    [fetchMe.fulfilled]: (state, action) => {
      state.me = action.payload;
      state.pending = false;
    },
    [fetchMe.rejected]: (state, action) => {
      state.me = null;
      state.pending = false;
    },
    [fetchAuthUrl.fulfilled]: (state, action) => {
      state.url = action.payload.url
    }
  },
});

export const isAuthenticated = createSelector(
  (state) => state.auth,
  (auth) => !!auth.me && !auth.pending && auth.me?.active && !auth.me?.anonymous
);

export const selectMe = createSelector(
    (state) => state.auth,
    (auth) => auth.me
  );

export const selectAuthenticationUrl = createSelector(
    (state) => state.auth,
    (auth) => auth.url
  );

export const hasRole = (roles) =>
  createSelector(
    (state) => state.auth,
    (auth) => auth.me.roles.includes(roles)
  );

const reducer = slice.reducer;
export default reducer;

import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {logout} from "./ducks/slice";

export default function Logout() {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logout()).then(() => (window.location = "/ui/auth"));
  }, []);

  return null

}
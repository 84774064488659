import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../translations/intl";
import retarusLogo from "../../logo.png";
import { Link, useHistory, useLocation } from "react-router-dom";
import querystring from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { fetchSome, FILTERS, selectOrders } from "./ducks/slice";
import OrdersModal from "./OrdersModal";

export default function Orders({
  onSetLogo,
  onSetPrint,
  onSetSupplierCorrelationLink,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = querystring.parse(search);
  const currentFilter = queryParams?.filter || FILTERS.PENDING;
  const [q, setQ] = useState("");
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [alerts, setAlerts] = useState([]);

  const { orders, total } = useSelector(selectOrders());

  const sid = queryParams?.sid;
  const limit = parseInt(queryParams.limit) || 10;
  const pages = Math.ceil(total / limit);
  const page = parseInt(queryParams.page) || 0;
  const offset = page * limit;

  const handleFilterTabChange = (filterName) => {
    const newQueries = { filter: filterName, sid: queryParams.sid };
    history.push({ search: querystring.stringify(newQueries) });
  };

  useEffect(() => {
    onSetLogo({ href: null, img: retarusLogo });
  }, []);

  useEffect(() => {
    onSetPrint(true);
    onSetSupplierCorrelationLink(null);
  }, [queryParams.sid]);

  useEffect(() => {
    const inner = async () => {
      const resultAction = await dispatch(
        fetchSome({
          supplier_correlation_ids: Array.isArray(queryParams.sid)
            ? queryParams.sid
            : [queryParams.sid],
          q: q,
          filter: currentFilter,
          limit: limit,
          offset: offset,
        }),
      );
      if (fetchSome.rejected.match(resultAction)) {
        if (resultAction.payload) {
          setAlerts((prev) => [
            {
              msg: resultAction.payload.t.id,
              args: resultAction.payload.t.args,
              type: "warning",
            },
          ]);
        }
      }
    };

    inner();
  }, [search, q]);

  useEffect(() => {
    if (orders.length > 0) {
      onSetLogo({ href: orders[0].logo.href, img: orders[0].logo.img });
    }
  }, [orders.length > 0]);

  return (
    <div className="row">
      {!!selectedOrder && (
        <OrdersModal
          order={selectedOrder}
          onClose={() => setSelectedOrder(null)}
        />
      )}
      {alerts.map((a) => (
        <div className="col offset-lg-2 col-lg-8">
          <div className={`alert alert-${a.type}`}>
            {t(a.msg, { ...a.args })}
          </div>
        </div>
      ))}
      <div className="col offset-lg-2 col-lg-8">
        <div className="">
          <h2>{t("orders.orders_overview")}</h2>
          <p>{t("orders.overview.tab.info")}</p>
          <div className="input-group">
            <input
              type="text"
              placeholder={`${t("common.search")} ${t("orders.order")} `}
              className="input form-control"
              onChange={(e) => {
                e.preventDefault();
                setQ(e.target.value);
              }}
              value={q}
            />
            <span className="input-group-append">
              <button type="button" className="btn btn btn-primary">
                <i className="fa fa-search" /> {t("common.search")}
              </button>
            </span>
          </div>
          <div className="tabs-container m-t-sm">
            <nav className="nav nav-tabs" role="tablist">
              {Object.values(FILTERS).map((tab) => (
                <a
                  key={tab}
                  className={`text-dark nav-item nav-link ${
                    tab === currentFilter ? "active" : ""
                  }`}
                  data-toggle="tab"
                  onClick={() => handleFilterTabChange(tab)}
                  role="tab"
                >
                  {t(`orders.overview.menu.${tab}`)}
                </a>
              ))}
            </nav>
            <div className="tab-content">
              <div id="tab-1" className="tab-pane active">
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table table-striped table-hover text-nowrap">
                      <thead>
                        <tr>
                          <th>{t("orders.number")}</th>
                          <th>{t("orders.supplier_order_number")}</th>
                          <th>{t("orders.date")}</th>
                          <th>{t("orders.buyer")}</th>
                          <th>{t("orders.total")}</th>
                          <th>
                            {t("orders.overview.menu.supplier_recipients")}
                          </th>
                          <th>{t("common.modified")}</th>
                          <th>{t("orders.overview.menu.state")}</th>
                          <th width="1">{t("common.actions")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orders.map((order) => {
                          const optLatestResponse =
                            order.responses.length > 0
                              ? order.responses[order.responses.length - 1]
                              : null;
                          const isReplaced = order.state === "BUYER_REPLACED";
                          const isCanceled = order.state === "BUYER_CANCELED";
                          return (
                            <tr key={order.slug}>
                              <td>
                                <Link to={`/orders/${order.slug}`}>
                                  {order.number}
                                </Link>
                              </td>
                              <td>
                                {!!order.supplier_order_number &&
                                  order.supplier_order_number}
                              </td>
                              <td>{!!order.date && order.date.date}</td>
                              <td>{order.parties?.buyer.name}</td>
                              <td>
                                {formatCurrency(
                                  order.summary.total,
                                  order.currency.code,
                                )}
                              </td>
                              <td>
                                <ul className="list-unstyled">
                                  {order.supplier_mail_to_recipients.map(
                                    (r, i) => (
                                      <li key={i}>{r}</li>
                                    ),
                                  )}
                                </ul>
                              </td>
                              <td>
                                {order.is_changed && (
                                  <i className="fa fa-check" />
                                )}
                              </td>
                              <td>
                                {isReplaced && (
                                  <span className="label label-warning">
                                    {t("orders.overview.menu.replaced")}
                                  </span>
                                )}
                                {isCanceled && (
                                  <span className="label label-warning">
                                    {t("orders.overview.menu.canceled")}
                                  </span>
                                )}
                                {!optLatestResponse &&
                                  !isReplaced &&
                                  !isCanceled && (
                                    <span className="label label-warning">
                                      {order.is_due
                                        ? t("orders.overview.menu.due")
                                        : t("orders.overview.menu.pending")}
                                    </span>
                                  )}
                                {!!optLatestResponse &&
                                  !isReplaced &&
                                  !isCanceled && (
                                    <span
                                      className={`label label-${
                                        optLatestResponse.accepted
                                          ? "primary"
                                          : "danger"
                                      }`}
                                    >
                                      {t(
                                        `orders.overview.menu.${
                                          optLatestResponse.accepted
                                            ? "accepted"
                                            : "rejected"
                                        }`,
                                      )}
                                    </span>
                                  )}
                              </td>
                              <td>
                                <div className="btn-group btn-group-sm">
                                  <Link
                                    to={`/orders/${order.slug}`}
                                    className="btn btn-default"
                                    data-toggle="tooltip"
                                    title={t("orders.overview.menu.open_order")}
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Link>
                                  <button
                                    type="button"
                                    className="btn btn-default"
                                    data-toggle="tooltip"
                                    title={t(
                                      "orders.overview.menu.resent_notification",
                                    )}
                                    //onClick={() => handleResendNotification(o)}
                                    onClick={() => setSelectedOrder(order)}
                                  >
                                    <i className="fa fa-envelope-o"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="me-md-auto text-md-left text-center mb-2 mb-md-0">
                      {limit * page} {t("common.to")}{" "}
                      {Math.min(limit * (page + 1), total)} {t("common.from")}{" "}
                      {total} {t("orders.orders")}
                    </div>
                    <ul className="pagination mb-0 justify-content-center ml-auto">
                      <li className={`page-item ${page === 0 && " active"}`}>
                        <Link
                          className={`page-link `}
                          to={`/orders?page=0&limit=${limit}&filter=${currentFilter}&q=${q}&sid=${sid}`}
                        >
                          1
                        </Link>
                      </li>
                      {pages > 8 && (
                        <li className={`page-item ${page < 1 && "disabled"}`}>
                          <Link
                            className={`page-link `}
                            to={`/orders?page=${
                              page - 1
                            }&limit=${limit}&filter=${currentFilter}&q=${q}&sid=${sid}`}
                          >
                            {"<"}
                          </Link>
                        </li>
                      )}
                      {Array(pages)
                        .fill(0)
                        .map((_, i) => (
                          <li
                            key={`page-${i}`}
                            className={`page-item ${page === i && " active"}`}
                          >
                            <Link
                              className="page-link"
                              to={`/orders?page=${i}&limit=${limit}&filter=${currentFilter}&q=${q}&sid=${sid}`}
                            >
                              {i + 1}
                            </Link>
                          </li>
                        ))
                        .slice(
                          Math.max(Math.min(page - 4, pages - 8), 1),
                          Math.min(Math.max(8, page + 5), pages - 1),
                        )}
                      {pages > 8 && (
                        <li
                          className={`page-item ${
                            page + 1 > pages - 1 && "disabled"
                          }`}
                        >
                          <Link
                            className={`page-link `}
                            to={`/orders?page=${
                              page + 1
                            }&limit=${limit}&filter=${currentFilter}&q=${q}&sid=${sid}`}
                          >
                            {">"}
                          </Link>
                        </li>
                      )}
                      {pages > 1 && (
                        <li
                          className={`page-item ${
                            page == pages - 1 && " active"
                          }`}
                        >
                          <Link
                            className="page-link"
                            to={`/orders?page=${
                              pages - 1
                            }&limit=${limit}&filter=${currentFilter}&q=${q}&sid=${sid}`}
                          >
                            {pages}
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import request from "../../requests";
import { useDispatch } from "react-redux";
import { upsertOrder } from "./ducks/slice";
import { useTranslation } from "react-i18next";

export default function Upload({ slug, accept, maxFiles = 10 }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [error, setError] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    accept: accept,
    maxSize: 1024 * 1024 * 20,
    maxFiles: maxFiles,
    onDrop: (acceptedFiles, fileRejections) => {
      const req = request.post(`/api/v1/orders/${slug}/uploads`).accept("json");
      acceptedFiles.forEach((file) => req.attach(file.name, file));
      req.on("progress", (e) => {
        setProgress((e.percent - 0.1).toFixed(1));
      });
      req.then((res) => {
        //props.onFilesUploaded(folderPath);
        setProgress(0);
        dispatch(upsertOrder(res.body));
        if (fileRejections.length === 0) {
          setError(null);
        } else {
          setError(
            <ul>
              {fileRejections.map((f) =>
                f.errors
                  .map((e) => e.message.toLocaleLowerCase())
                  .map((m) => (
                    <li>{t(`errors.irregular.${m}`.toLocaleLowerCase())}</li>
                  ))
              )}
            </ul>
          );
        }
      });
    },
  });

  const [progress, setProgress] = useState(0);
  return (
    <div className="white-bg m-t-sm">
      <div className="p-h-xs">
        <div className="text-center dropzone" {...getRootProps()}>
          {progress === 0 && <small>{t("orders.uploads.click_me")}</small>}
          {progress > 0 && (
            <div className="progress">
              <div
                style={{ width: `${progress}%` }}
                aria-valuemax="100"
                aria-valuemin="0"
                aria-valuenow="50"
                role="progressbar"
                className="progress-bar bg-danger"
              >
                <span className="text-center m-l-xs">{progress}%</span>
              </div>
            </div>
          )}
          <input {...getInputProps()} />
        </div>
      </div>
      {!!error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
}

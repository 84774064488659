/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React, { useEffect } from "react";
import "./theme/style.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { useBodyClass } from "./theme/helper";

import AppUnauth from "./AppUnauth";
import AppAuth from "./AppAuth";
import {
  fetchAuthUrl,
  fetchMe,
  isAuthenticated,
  logout,
  selectAuthenticationUrl,
  selectMe,
} from "./features/auth/ducks/slice";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "./components/Spinner";

function ProtectedRoute({ children, ...rest }) {
  const dispatch = useDispatch();

  const isAuth = useSelector(isAuthenticated);
  const authUrl = useSelector(selectAuthenticationUrl);
  const me = useSelector(selectMe);

  useEffect(() => {
    dispatch(fetchMe());
  }, []);

  useEffect(() => {
    dispatch(fetchAuthUrl({ state: window.location.href }));
  }, []);

  if (!authUrl) {
    return <Spinner />;
  }

  if (!me) {
    return <Spinner />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuth ? children : (window.location = authUrl);
      }}
    />
  );
}

export default function App() {
  const dispatch = useDispatch();

  useBodyClass(["mailedi"]);

  return (
    <Router basename="/ui">
      <Switch>
        <Route path="/auth">
          <ProtectedRoute>
            <AppAuth />
          </ProtectedRoute>
        </Route>
        <Route>
          <AppUnauth />
        </Route>
      </Switch>
    </Router>
  );
}

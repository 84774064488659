import {createSlice, createAsyncThunk, createSelector} from '@reduxjs/toolkit'


const initialState = {
    menu: {
        left: {
            minimized: false
        },
        top: {}
    }
};

export const slice = createSlice({
    name: "nav",
    initialState,
    reducers: {
        toggleMenuLeft(state, action) {
            state.menu.left = {...state.menu.left, minimized: !state.menu.left.minimized}
        }
    },
});

const {actions, reducer} = slice;

export const {toggleMenuLeft} = actions;
export default reducer;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

import { useTranslation } from "react-i18next";

import Modal, { ModalHeader } from "../../layout/Modal";
import { Tabs, Tab } from "react-bootstrap";

//import OrderEditTab from "./OrderEditTab";
import OrderAllowancesAndChargesTab from "./OrderAllowancesAndChargesTab";
import OrderEditTab from "./OrderEditTab";
import OrderItemsTab from "./OrderItemsTab";

export const TABS = {
  EDIT: "edit",
  ALLOWANCE_AND_CHARGE: "allowance_and_charge",
  ITEMS: "items",
};

function OrderEdit({ order, onClose }) {
  const { t } = useTranslation();

  return (
    <Modal>
      <ModalHeader style={{ borderBottom: "5px", padding: "15px" }}>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div>
          {t("orders.order")}:{" "}
          <h4 style={{ display: "inline" }}>{order.number}</h4>
        </div>
      </ModalHeader>
      <Tabs defaultActiveKey={TABS.EDIT} id="order" transition={false}>
        <Tab eventKey={TABS.EDIT} title={t("orders.order")}>
          <OrderEditTab order={order} onClose={onClose} />
        </Tab>
        <Tab
          eventKey={TABS.ALLOWANCE_AND_CHARGE}
          title={t("orders.allowances_and_charges")}
        >
          {order.allowances_and_charges_allowed && (
            <OrderAllowancesAndChargesTab order={order} onClose={onClose} />
          )}
        </Tab>
        <Tab eventKey={TABS.ITEMS} title={`${t("orders.items")}`}>
          <OrderItemsTab order={order} onClose={onClose} />
        </Tab>
      </Tabs>
    </Modal>
  );
}

export default OrderEdit;

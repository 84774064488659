/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";

const adapter = createEntityAdapter({ sortComparer: (a, b) => b.id - a.id });
const initialState = adapter.getInitialState();

export const slice = createSlice({
  name: "activities",
  initialState: initialState,
  reducers: {
    upsertActivities: (state, action) => {
      adapter.upsertMany(state, action.payload);
    },
  },
});

export const selectors = adapter.getSelectors((state) => state.orders);
export const { upsertActivities } = slice.actions;
const reducer = slice.reducer;
export default reducer;

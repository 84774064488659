/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import request from "../../../requests";
import { upsertDespatchAdvice } from "./slice";

const adapter = createEntityAdapter();
const initialState = adapter.getInitialState();

export const deleteDesadvItem = createAsyncThunk(
  "despatch_advice_item/deleteDesadvItem",
  async ({ slug, item }, { despatch, rejectWithValue }) => {
    try {
      const response = await request
        .delete(`/api/v1/despatch_advices/${slug}/items/${item.id}`)
        .set("Accept", "application/json");
      return item.id;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const changeDesadvItem = createAsyncThunk(
  "despatch_advice_item/changeDesadvItem",
  async ({ slug, item, quantity }, { rejectWithValue }) => {
    try {
      const response = await request
        .put(`/api/v1/despatch_advices/${slug}/items/${item.id}`)
        .send({ quantity: quantity })
        .set("Accept", "application/json");
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const slice = createSlice({
  name: "despatch_advice_items",
  initialState: initialState,
  reducers: {
    upsertDespatchAdviceItem: adapter.upsertOne,
    upsertDespatchAdviceItems: adapter.upsertMany,
  },
  extraReducers: {
    [deleteDesadvItem.fulfilled]: (state, action) => {
      adapter.removeOne(state, action.payload);
    },
    [changeDesadvItem.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
  },
});

export const selectors = adapter.getSelectors(
  (state) => state.despatch_advice_items
);

export const { upsertDespatchAdviceItems } = slice.actions;
const reducer = slice.reducer;
export default reducer;

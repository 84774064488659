/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

export const DEV_MODE = process.env.NODE_ENV !== 'production';
export const FEATURES = {
    ORDER: {
        PARTIAL_DELIVERY: true,
        BARCODE_IMAGE: true,
    }
};
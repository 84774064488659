/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React, { useEffect } from "react";
import { useParams, Switch, Route, Redirect } from "react-router-dom";
import TabLink from "../../components/TabLink";
import Order from "./Order";
import { useTranslation } from "react-i18next";
import OrderActivity from "./OrderActivity";
import OrderNotification from "./OrderNotification";
import { useDispatch, useSelector } from "react-redux";
import { fetchBySlug } from "./ducks/slice";
import retarusLogo from "../../logo.png";
import OrderDespatchAdvices from "./OrderDespatchAdvices";
import Spinner from "../../components/Spinner";

function OrderView({
  onSetLogo,
  onNotFound,
  onSetPrint,
  onSetSupplierCorrelationLink,
}) {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const order = useSelector((state) => state.orders.entities[slug]);

  useEffect(() => {
    dispatch(fetchBySlug({ slug }))
      .then((res) => {
        // after first fetch set language by order language
        if (!order && !!res.payload.language) {
          i18n.changeLanguage(res.payload.language);
        }
      })
      .catch((res) => {
        onNotFound();
      });
  }, []);

  useEffect(() => {
    if (!!order) {
      onSetLogo(!!order.logo?.img ? order.logo : { img: retarusLogo });
      onSetPrint(true);
      onSetSupplierCorrelationLink(
        !!order ? order.supplier_correlation_link : null,
      );
    }
  }, [order]);

  if (!order) {
    return <Spinner />;
  }

  return (
    <div className="container">
      <div className="row m-t-n">
        <div className="col-sm p-0">
          <div className="tabs-container">
            <ul className="nav nav-tabs text-nowrap">
              <TabLink to={`/orders/${slug}/details`}>
                {t("orders.order")}
              </TabLink>
              {/* If feature is enabled and length is > 0 we link to first despatch advice */}
              {order._features.despatch_advice &&
                order.despatch_advices.length > 0 && (
                  <TabLink
                    to={`/despatch_advices/${order.despatch_advices[0].slug}`}
                  >
                    {t("despatch_advices.despatch_advices")}{" "}
                  </TabLink>
                )}
              {/* If feature is enabled and length is 0 we link to despatch advice creation page */}
              {order._features.despatch_advice &&
                order.despatch_advices.length === 0 && (
                  <TabLink to={`/orders/${slug}/despatch_advices`}>
                    {t("despatch_advices.despatch_advices")}{" "}
                  </TabLink>
                )}
              <TabLink to={`/orders/${slug}/activity`}>
                {t("activities.activity")}
                <span className="badge badge-warning badge-sm m-l-xs">
                  Beta
                </span>
              </TabLink>
              <TabLink to={`/orders/${slug}/notifications`}>
                {t("orders.notifications.title")}
              </TabLink>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active">
                <div className="panel-body">
                  <Switch>
                    <Route exact path="/orders/:slug/details">
                      <Order />
                    </Route>

                    <Route exact path="/orders/:slug/activity">
                      <OrderActivity />
                    </Route>

                    <Route exact path="/orders/:slug/notifications">
                      <OrderNotification />
                    </Route>

                    <Route exact path="/orders/:slug/despatch_advices">
                      <OrderDespatchAdvices />
                    </Route>

                    {/* default redirect */}
                    <Redirect to={`/orders/:slug/details`} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderView;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import request from "../../../requests";
import { upsertDespatchAdviceItems } from "./itemSlices";

const adapter = createEntityAdapter({
  selectId: (desadv) => desadv.slug,
});
const initialState = adapter.getInitialState();

export const fetchDesadvBySlug = createAsyncThunk(
  "despatch_advices/fetchBySlug",
  async ({ slug }, { dispatch, rejectWithValue }) => {
    try {
      const response = await request
        .get(`/api/v1/despatch_advices/${slug}`)
        .accept("json");

      const desadv = response.body;
      dispatch(upsertDespatchAdviceItems(desadv.items));
      return { ...desadv, items: desadv.items.map((i) => i.id) };
    } catch (e) {
      //if (!e.response) {
      throw e;
      //}
      //return rejectWithValue(e.response.body);
    }
  }
);

export const createDespatchAdvices = createAsyncThunk(
  "despatch_advices/createDespatchAdvices",
  async (
    { order_slug = "", create_by_delivery_date = false, create_empty = false },
    { rejectWithValue }
  ) => {
    try {
      const response = await request
        .post(`/api/v1/despatch_advices`)
        .field("order_slug", !!order_slug ? order_slug : "")
        .field("create_by_delivery_date", create_by_delivery_date)
        .field("create_empty", create_empty)
        .accept("json");
      return response.body.results;
    } catch (e) {
      //if (!e.response) {
      throw e;
      //}
      //return rejectWithValue(e.response.body);
    }
  }
);

export const deleteDesadv = createAsyncThunk(
  "despatch_advices/deleteDesadv",
  async ({ slug }, { rejectWithValue }) => {
    try {
      const response = await request
        .delete(`/api/v1/despatch_advices/${slug}`)
        .set("Accept", "application/json");
      return slug;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const demo = createAsyncThunk(
  "despatch_advices/demo",
  async ({ email, lang }, { rejectWithValue }) => {
    try {
      const response = await request
        .post(`/api/v1/despatch_advices/demo`)
        .field("email", email)
        .field("lang", lang);
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const confirmDesadv = createAsyncThunk(
  "despatch_advices/confirmDesadv",
  async ({ slug, confirm, remarks }, { dispatch, rejectWithValue }) => {
    try {
      const response = await request
        .post(`/api/v1/despatch_advices/${slug}`)
        .field("confirm", confirm ? "true" : "")
        .field("remarks", remarks);

      const desadv = response.body;
      dispatch(upsertDespatchAdviceItems(desadv.items));
      return { ...desadv, items: desadv.items.map((i) => i.id) };
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const changeDesadv = createAsyncThunk(
  "despatch_advices/changeDesadv",
  async (
    {
      slug,
      number,
      deliveryDateStart,
      shipDateStart,
      shipmentNumber,
      shipmentUrl,
      orderNumber,
      orderDate,
      reference,
      referenceDate,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await request
        .put(`/api/v1/despatch_advices/${slug}`)
        .field({ number: number })
        .field({ deliveryDateStart: deliveryDateStart })
        .field({ shipDateStart: shipDateStart })
        .field({ shipmentNumber: shipmentNumber })
        .field({ shipmentUrl: shipmentUrl })
        .field({ orderNumber: orderNumber })
        .field({ orderDate: orderDate })
        .field({ reference: reference })
        .field({ referenceDate: referenceDate });
      const desadv = response.body;
      dispatch(upsertDespatchAdviceItems(desadv.items));
      return { ...desadv, items: desadv.items.map((i) => i.id) };
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const updateItems = createAsyncThunk(
  "despatch_advices/updateItems",
  async ({ slug, items }, { dispatch, rejectWithValue }) => {
    try {
      const response = await request
        .put(`/api/v1/despatch_advices/${slug}/items`)
        .send(items)
        .set("Accept", "application/json");

      const desadv = response.body;
      dispatch(upsertDespatchAdviceItems(desadv.items));
      return { ...desadv, items: desadv.items.map((i) => i.id) };
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  }
);

export const slice = createSlice({
  name: "despatch_advices",
  initialState: initialState,
  reducers: {
    upsertDespatchAdvice: adapter.upsertOne,
  },
  extraReducers: {
    [fetchDesadvBySlug.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [updateItems.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [confirmDesadv.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [createDespatchAdvices.fulfilled]: (state, action) => {
      adapter.upsertMany(state, action.payload);
    },
    [deleteDesadv.fulfilled]: (state, action) => {
      adapter.removeOne(state, action.payload);
    },
    [changeDesadv.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [demo.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
  },
});

export const selectors = adapter.getSelectors(
  (state) => state.despatch_advices
);

export const selectDespatchAdviceItems = ({ slug }) =>
  createSelector(
    (state) => state,
    ({ despatch_advices, despatch_advice_items }) => {
      const o = despatch_advices.entities[slug];
      if (o.items === undefined) {
        return [];
      }
      return o.items
        .map((id) => despatch_advice_items.entities[id])
        .filter((i) => !!i && i);
    }
  );

export const { upsertDespatchAdvice } = slice.actions;
const reducer = slice.reducer;
export default reducer;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

import { useTranslation } from "react-i18next";

import Modal, { ModalHeader } from "../../layout/Modal";
import { Tabs, Tab } from "react-bootstrap";
import DespatchAdviceItemEditTab from "./DespatchAdviceItemEditTab";

export const TABS = {
  ITEM: "item",
};

export default function DespatchAdviceItemEdit({
  desadv,
  item,
  onClose,
  tab = TABS.ITEM,
}) {
  const { t } = useTranslation();

  return (
    <Modal>
      <ModalHeader style={{ borderBottom: "5px", padding: "15px" }}>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div>
          {t("despatch_advices.despatch_advice")} {t("despatch_advices.item")}
          <h4 style={{ display: "inline" }}>
            <br />
            {item.number}
          </h4>
        </div>
      </ModalHeader>
      <Tabs defaultActiveKey={tab} transition={false} id="desadv">
        <Tab eventKey={TABS.ITEM} title={t("despatch_advices.item")}>
          {/*<OrderEditTab order={order} onClose={onClose}/>*/}
          <DespatchAdviceItemEditTab
            desadv={desadv}
            item={item}
            onClose={onClose}
          />
        </Tab>
      </Tabs>
    </Modal>
  );
}

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import i18n from "./i18n";

export const formatCurrency = (number, currency) => {
  return new Intl.NumberFormat(i18n.language, {
    style: "currency",
    currency: currency,
  }).format(number);
};

export const formatNumber = (
  number,
  minFractions = 2,
  maxFraction = 20,
  grouping = true
) => {
  return new Intl.NumberFormat(i18n.language, {
    style: "decimal",
    minimumFractionDigits: minFractions,
    maximumFractionDigits: maxFraction,
    useGrouping: grouping,
  }).format(number);
};

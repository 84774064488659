/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React, { useEffect } from "react";
import {
  useParams,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import TabLink from "../../components/TabLink";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  createDespatchAdvices,
  deleteDesadv,
  fetchDesadvBySlug,
} from "./ducks/slice";
import retarusLogo from "../../logo.png";
import DespatchAdvice from "./DespatchAdvice";
import Spinner from "../../components/Spinner";

export default function DespatchAdviceView({
  onSetLogo,
  onNotFound,
  onSetPrint,
}) {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  //const order = useSelector((state) => state.orders.entities[slug]);
  const desadv = useSelector((state) => state.despatch_advices.entities[slug]);

  useEffect(() => {
    dispatch(fetchDesadvBySlug({ slug }))
      .then((res) => {
        // after first fetch set language by order language
        if (!!res.payload.language) {
          i18n.changeLanguage(res.payload.language);
        }
      })
      .catch((res) => {
        onNotFound();
      });
  }, [slug]);
  //
  //  useEffect(() => {
  //    if (!!order) {
  //      //i18n.changeLanguage(order.language);
  //      onSetLogo(!!order.logo.img ? order.logo : { img: retarusLogo });
  //      onSetPrint(true);
  //    }
  //  }, [order]);

  useEffect(() => {
    if (!!desadv) {
      //i18n.changeLanguage(order.language);
      onSetLogo(!!desadv.logo?.img ? desadv.logo : { img: retarusLogo });
      onSetPrint(true);
    }
  }, [desadv]);

  const handleOnNewDesadv = async () => {
    const resultAction = await dispatch(
      createDespatchAdvices({
        order_slug: desadv.order.slug,
        create_empty: true,
      }),
    );
    if (createDespatchAdvices.fulfilled.match(resultAction)) {
      history.push(`/despatch_advices/${resultAction.payload[0].slug}`);
    }
  };

  const handleOnDeleteDesadv = async () => {
    const resultAction = await dispatch(deleteDesadv({ slug: desadv.slug }));
    if (deleteDesadv.fulfilled.match(resultAction)) {
      if (desadv.related.length > 1) {
        history.push(
          `/despatch_advices/${desadv.related[desadv.related.length - 1].slug}`,
        );
      } else {
        history.push(`/orders/${desadv.order.slug}/despatch_advices`);
      }
    }
  };

  if (!desadv) {
    return <Spinner />;
  }

  return (
    <div className="container" style={{ maxWidth: "1370px" }}>
      {!!desadv && (
        <div className="row m-t-n">
          <div className="col-sm p-0">
            {/* Tabs Top */}
            <div className="tabs-container">
              <ul className="nav nav-tabs text-nowrap">
                {!!desadv.order && (
                  <TabLink to={`/orders/${desadv.order.slug}/details`}>
                    {t("orders.order")}
                  </TabLink>
                )}
                <TabLink to={`/despatch_advices/${slug}/details`}>
                  {t("despatch_advices.despatch_advices")}{" "}
                </TabLink>
                {/*<TabLink to={`/orders/${slug}/activity`}>Activity</TabLink>*/}
                <TabLink to={`/orders/${desadv.order.slug}/activity`}>
                  {t("activities.activity")}
                  <span className="badge badge-warning badge-sm m-l-xs">
                    Beta
                  </span>
                </TabLink>
                {!!desadv.order && (
                  <TabLink to={`/orders/${desadv.order.slug}/notifications`}>
                    {t("orders.notifications.title")}
                  </TabLink>
                )}
              </ul>
              <div className="tab-content">
                {/* Tabs Right */}
                <div className="tabs-container">
                  <div className={!!desadv.order && `tabs-right`}>
                    {!!desadv.order && (
                      <ul
                        className="nav nav-tabs text-nowrap"
                        style={{ marginLeft: "unset", width: "17%" }}
                      >
                        {desadv.related.map((d, i) => (
                          <TabLink
                            key={d.slug}
                            to={`/despatch_advices/${d.slug}/details`}
                            style={{ borderTopColor: "transparent" }}
                            data-toggle="tooltip"
                            title={
                              !!d.number
                                ? d.number
                                : !!d.date
                                ? d.date.date
                                : !!desadv.order_date
                                ? desadv.order_date.date
                                : "Draft"
                            }
                          >
                            {d.state === "PENDING" && (
                              <span className="label label-warning m-r-sm">
                                {t("common.draft")}
                              </span>
                            )}
                            {d.state === "CONFIRMED" && (
                              <span className="label label-primary m-r-sm text-center">
                                {t("common.completed")}
                              </span>
                            )}
                            {!!d.number
                              ? d.number
                              : !!d.date
                              ? d.date.date
                              : !!desadv.order_date
                              ? desadv.order_date.date
                              : "Draft"}
                            {false && slug === d.slug && (
                              <button
                                className="btn btn-xs"
                                type={"button"}
                                onClick={handleOnDeleteDesadv}
                              >
                                <i className="fa fa-remove" />
                              </button>
                            )}
                          </TabLink>
                        ))}
                        {desadv._gui.permission.edit && (
                          <li>
                            <button
                              className="btn btn-block text-left text-danger"
                              style={{ padding: "15px 20px" }}
                              onClick={handleOnNewDesadv}
                            >
                              <i className="fa fa-plus" />{" "}
                              {t("despatch_advices.create_new")}{" "}
                              {t("despatch_advices.despatch_advice")}
                            </button>
                          </li>
                        )}
                      </ul>
                    )}
                    <div className="tab-content">
                      <div className="tab-pane active">
                        <div className="tab-pane active">
                          <div
                            className="panel-body"
                            style={{
                              borderTop: "unset",
                              maxWidth: "1140px",
                              width: "100%",
                            }}
                          >
                            <Switch>
                              <Route
                                exact
                                path="/despatch_advices/:slug/details"
                              >
                                <DespatchAdvice />
                              </Route>

                              {/* default redirect */}
                              <Redirect
                                to={`/despatch_advices/:slug/details`}
                              />
                            </Switch>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

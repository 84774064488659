/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

import { Formik, Form, FieldArray, Field, getIn, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";

import { DEV_MODE } from "../../constants";
import { ModalFooter } from "../../layout/Modal";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { formatNumber } from "../../translations/intl";
import { allowanceAndChargesOnItem } from "./ducks/slice";

const createInitialValues = (allowances_and_charges) => ({
  allowancesAndCharges: allowances_and_charges.map((aoc) => ({
    sequence: parseInt(formatNumber(aoc.sequence, 0, 0, false)),
    value: formatNumber(aoc.value, 0, 20, false).replace(",", "."),
    isPercent: aoc.is_percent,
    isAllowance: aoc.is_allowance,
    perQuantity: aoc.per_quantity,
    description: aoc.description,
  })),
  error: null,
});

const createValidationSchema = (order, item, t) =>
  Yup.object().shape({
    allowancesAndCharges: Yup.array().of(
      Yup.object().shape({
        sequence: Yup.number()
          .required(t("errors.required", { attr: t("common.sequence") }))
          .min(1)
          .typeError(t("errors.valid", { type: t("common.number") })),

        description: Yup.string()
          .required(t("errors.required", { attr: t("common.description") }))
          .max(
            2048,
            t("errors.lte", { attr: t("common.description"), value: 2048 }),
          ),
        value: Yup.number()
          .required(t("errors.required", { attr: t("common.value") }))
          .when(
            ["isPercent", "perQuantity"],
            (isPercent, perQuantity, schema) =>
              schema.test(
                "decimal-places",
                t("errors.lte", {
                  attr: t("common.number_of_decimal_places"),
                  value: perQuantity
                    ? order.price_max_fraction
                    : order.amount_max_fraction,
                }),
                (value) =>
                  isPercent
                    ? true
                    : (value + "").match(
                        new RegExp(
                          `^\\d*\\.?\\d{0,${
                            perQuantity
                              ? order.price_max_fraction
                              : order.amount_max_fraction
                          }}$`,
                        ),
                      ),
              ),
          )
          .min(0.0)
          .max(
            Math.pow(10, 9) - 1,
            t("errors.lte", {
              attr: t("common.note"),
              value: Math.pow(10, 9) - 1,
            }),
          )
          .typeError(t("errors.valid", { type: t("common.number") })),
        isPercent: Yup.boolean(),
        isAllowance: Yup.boolean(),
        perQuantity: Yup.boolean(),
      }),
    ),
  });

function OrderItemAllowancesAndChargesTab({ order, item, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //let initialValue = createInitialValues(item.allowances_and_charges.current)
  //const handleResetInitialValue = async( {resetForm}) =>{
  //    initialValue = createInitialValues(item.allowances_and_charges.original)
  //}

  const handleSubmit = async (values, { setErrors }) => {
    const resultAction = await dispatch(
      allowanceAndChargesOnItem({
        slug: order.slug,
        item: item,
        allowancesAndCharges: values.allowancesAndCharges,
      }),
    );
    if (allowanceAndChargesOnItem.fulfilled.match(resultAction)) {
      onClose();
    } else {
      if (resultAction.payload) {
        setErrors({
          error: !!resultAction.payload.t
            ? t(resultAction.payload.t.id, {
                attr: t(resultAction.payload.t.args.attr),
                value: resultAction.payload.t.args.value,
              })
            : resultAction.payload.message,
        });
      }
    }
  };

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={true}
      enableReinitialize={true}
      initialValues={createInitialValues(item.allowances_and_charges.current)}
      onSubmit={handleSubmit}
      validationSchema={createValidationSchema(order, item, t)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        resetForm,
        setFieldValue,
        setValues,
        isSubmitting,
      }) => (
        <Form>
          <ModalBody>
            <FieldArray
              name="allowancesAndCharges"
              render={(arrayHelpers) => (
                <div className="container">
                  <div className="row">
                    <div className="p-1 col-md-1 text-right">
                      <button
                        className="btn"
                        style={{ paddingTop: "0", paddingBottom: "0" }}
                        type={"button"}
                        data-toggle="tooltip"
                        title="Reload Original"
                        onClick={() =>
                          setValues(
                            createInitialValues(
                              item.allowances_and_charges.original,
                            ),
                            false,
                          )
                        }
                      >
                        <i className="fa fa-refresh text-warning" />
                      </button>
                    </div>
                    <div className="p-1 col-md-1 text-left">
                      {t("common.sequence")}
                    </div>
                    <div className="p-1 col-md-2 text-left">
                      {t("common.type")}
                    </div>
                    <div className="p-1 col-md-2 text-left">
                      {t("common.apply")} {t("common.per")}
                    </div>
                    <div className="p-1 col-md text-left">
                      {t("common.description")}
                    </div>
                    <div className="p-1 col-md-2 text-left">
                      {t("common.value")}
                    </div>
                  </div>
                  {values.allowancesAndCharges.map(
                    (allowance_or_charge, index) => (
                      <div className="row" key={index}>
                        <div className="p-1 col-md-1">
                          <button
                            className="btn"
                            type={"button"}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <i className="fa fa-remove text-danger" />
                          </button>
                        </div>
                        <div className="p-1 col-md-1">
                          <Field
                            //type="number"
                            as="input"
                            name={`allowancesAndCharges.${index}.sequence`}
                            onChange={(e) =>
                              setFieldValue(
                                `allowancesAndCharges.${index}.sequence`,
                                parseInt(
                                  e.target.value
                                    .replace(",", ".")
                                    .replace(/[^\d.]/g, ""),
                                ),
                              )
                            }
                            className={`form-control ${
                              getIn(
                                errors,
                                `allowancesAndCharges.${index}.sequence`,
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name={`allowancesAndCharges.${index}.sequence`}
                            render={(msg) => (
                              <div className="order-last invalid-feedback text-left">
                                {msg}
                              </div>
                            )}
                          />
                        </div>
                        <div className="p-1 col-md-2">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              setFieldValue(
                                `allowancesAndCharges.${index}.isAllowance`,
                                e.target.value === "allowance" ? true : false,
                              )
                            }
                            value={
                              allowance_or_charge.isAllowance
                                ? "allowance"
                                : "charge"
                            }
                          >
                            <option value={"allowance"}>
                              {t("common.allowance")}
                            </option>
                            <option value={"charge"}>
                              {t("common.charge")}
                            </option>
                          </select>
                        </div>
                        <div className="p-1 col-md-2">
                          <select
                            className="form-control"
                            onChange={(e) =>
                              setFieldValue(
                                `allowancesAndCharges.${index}.perQuantity`,
                                e.target.value === "quantity" ? true : false,
                              )
                            }
                            value={
                              allowance_or_charge.perQuantity
                                ? "quantity"
                                : "amount"
                            }
                          >
                            <option value={"quantity"}>
                              {t("orders.price")} / {t("orders.unit")}
                            </option>
                            <option value={"amount"}>
                              {t("orders.amount")}
                            </option>
                          </select>
                          {/*<Field
                                  type="checkbox"
                                  htmlFor={`allowancesAndCharges.${index}.perQuantity`}
                                  name={`allowancesAndCharges.${index}.perQuantity`}
                                  //disabled={!course.has_travel_expenses}
                                  //hidden={!course.has_travel_expenses}
                                  //className={"align-middle"}
                              />*/}
                        </div>
                        <div className="p-1 col-md">
                          <Field
                            name={`allowancesAndCharges.${index}.description`}
                            placeholder={`${t("common.description")}...`}
                            value={
                              order.is_demo
                                ? t(
                                    values.allowancesAndCharges[index]
                                      .description,
                                  )
                                : values.allowancesAndCharges[index].description
                            }
                            className={`form-control ${
                              getIn(
                                errors,
                                `allowancesAndCharges.${index}.description`,
                              )
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            name={`allowancesAndCharges.${index}.description`}
                            render={(msg) => (
                              <div className="order-last invalid-feedback text-left">
                                {msg}
                              </div>
                            )}
                          />
                        </div>
                        <div className="p-1 col-md-2">
                          <div
                            className={
                              "input-group " //todo+ `errors.allowancesAndCharges.${index}.value` && "has-error"
                            }
                          >
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <span
                                  type={"button"}
                                  className="p-0 border-none"
                                  data-toggle="tooltip"
                                  title={`Click to switch to ${
                                    allowance_or_charge.isPercent
                                      ? order.currency.symbol
                                      : "%"
                                  }`}
                                  onClick={(e) =>
                                    setFieldValue(
                                      `allowancesAndCharges.${index}.isPercent`,
                                      !allowance_or_charge.isPercent,
                                    )
                                  }
                                >
                                  {allowance_or_charge.isPercent
                                    ? "%"
                                    : order.currency.symbol}
                                </span>
                              </div>
                            </div>
                            <Field
                              //type="number"
                              as="input"
                              name={`allowancesAndCharges.${index}.value`}
                              onChange={(e) =>
                                setFieldValue(
                                  `allowancesAndCharges.${index}.value`,
                                  e.target.value
                                    .replace(",", ".")
                                    .replace(/[^\d.]/g, ""),
                                )
                              }
                              className={`form-control ${
                                getIn(
                                  errors,
                                  `allowancesAndCharges.${index}.value`,
                                )
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              name={`allowancesAndCharges.${index}.value`}
                              render={(msg) => (
                                <div className="order-last invalid-feedback text-left text-wrap">
                                  {msg}
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                  <div className="row">
                    <div className="p-1 col-md-1">
                      <button
                        className="btn"
                        type={"button"}
                        onClick={() =>
                          arrayHelpers.push({
                            sequence:
                              values.allowancesAndCharges.length > 0
                                ? values.allowancesAndCharges[
                                    values.allowancesAndCharges.length - 1
                                  ].sequence + 1
                                : 1,
                            description: "",
                            isAllowance: true,
                            perQuantity: false,
                            isPercent: true,
                            value: 0,
                          })
                        }
                      >
                        <i className="fa fa-plus text-navy" />
                      </button>
                    </div>
                    <div
                      className="p-1 col-md-1"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <Field
                        value={
                          values.allowancesAndCharges.length > 0
                            ? values.allowancesAndCharges[
                                values.allowancesAndCharges.length - 1
                              ].sequence + 1
                            : 1
                        }
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                    <div className="p-1 col-md-2">
                      <Field
                        value=""
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                    <div className="p-1 col-md-2">
                      <Field
                        value=""
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                    <div className="p-1 col-md">
                      <Field
                        value=""
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                    <div className="p-1 col-md-2">
                      <Field
                        value=""
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              )}
            />
            {DEV_MODE && (
              <pre
                className="m-t-md"
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}
            {!!errors.error && (
              <div className="alert alert-danger m-t-sm">{errors.error}</div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="btn-group">
              <button className="btn btn-primary" type={"submit"}>
                <i className="fa fa-save" /> {t("form.save")}
              </button>
              <button className="btn btn-danger" onClick={() => onClose()}>
                <i className="fa fa-close" /> {t("form.cancel")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

export default OrderItemAllowancesAndChargesTab;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";

import Datepicker from "../../components/Datepicker";
import { DEV_MODE } from "../../constants";

import { ModalFooter } from "../../layout/Modal";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changeItem } from "./ducks/slice";

import { formatNumber } from "../../translations/intl";
import * as Yup from "yup";

//const createInitialValues = (partial) => ({
//    deliveryAt: parseISO(partial.deliveryAt),
//    quantitiy: partial.quantitiy
//});
const createInitialValues = (item, min_fraction, max_fraction) => ({
  price: formatNumber(
    item.price.current,
    min_fraction,
    max_fraction,
    false,
  ).replace(",", "."),
  quantity: formatNumber(item.quantity.current, 0, 20, false).replace(",", "."),
  tax: formatNumber(item.tax.current, 0, 20, false).replace(",", "."),
  remarks: !!item.remarks_new ? item.remarks_new : "",

  deliveryDateStart: !!item.delivery_date.current.start
    ? parseISO(item.delivery_date.current.start.iso)
    : "",
  deliveryDateEnd: !!item.delivery_date.current.end
    ? parseISO(item.delivery_date.current.end.iso)
    : "",

  supplierOrderNumber: !!item.supplier_order_number
    ? item.supplier_order_number
    : "",

  error: null,
});

const createValidationSchema = (order, item, t) =>
  Yup.object().shape({
    price: Yup.number()
      .required(t("errors.required", { attr: t("orders.price") }))
      .test(
        "decimal-places",
        t("errors.lte", {
          attr: t("common.number_of_decimal_places"),
          value: order.price_max_fraction,
        }),
        (value) =>
          (value + "").match(
            new RegExp(`^\\d*\\.?\\d{0,${order.price_max_fraction}}$`),
          ),
      )
      .min(0.0)
      .max(
        999999999.999999,
        t("errors.lte", {
          attr: t("orders.price"),
          value: 999999999.999999,
        }),
      )
      .typeError(t("errors.valid", { type: t("common.number") })),
    quantity: Yup.number()
      .required(t("errors.required", { attr: t("orders.quantity") }))
      .min(
        item.quantity.delivery.assigned,
        item.quantity.delivery.assigned !== item.quantity.current
          ? t("errors.gte", {
              attr: t("orders.quantity"),
              value: formatNumber(item.quantity.delivery.assigned, 0),
            })
          : t("errors.eq", {
              attr: t("orders.quantity"),
              value: formatNumber(item.quantity.delivery.assigned, 0),
            }),
      )
      //.max(
      //  item.quantity.original,
      //  item.quantity.delivery.assigned !== item.quantity.current
      //    ? t("errors.lte", {
      //        attr: t("orders.quantity"),
      //        value: formatNumber(item.quantity.original, 0),
      //      })
      //    : t("errors.eq", {
      //        attr: t("orders.quantity"),
      //        value: formatNumber(item.quantity.delivery.assigned, 0),
      //      }),
      //)
      .typeError(t("errors.valid", { type: t("common.number") })),
    tax: Yup.number()
      .required(t("errors.required", { attr: t("orders.vat") }))
      .min(0.0)
      .max(
        100.0,
        t("errors.lte", {
          attr: t("orders.vat"),
          value: 100.0,
        }),
      )
      .typeError(t("errors.valid", { type: t("common.number") })),
    remarks: Yup.string()
      .ensure()
      .trim()
      .max(
        512,
        t("errors.lte", {
          attr: t("common.note"),
          value: 512,
        }),
      )
      .nullable()
      .typeError(t("errors.valid", { type: "text" })),

    supplierOrderNumber: Yup.string()
      .trim()
      .max(
        64,
        t("errors.lte", { attr: t("orders.supplier_order_number"), value: 64 }),
      ),

    //deliveryDateStart: Yup.date()
    //.required(t("errors.required", { attr: t("common.date") }))
    //.min(new Date())
    //.typeError(t("errors.valid", { type: t("common.date") })),
  });

function OrderItemEditTab({ order, item, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (values, { setErrors }) => {
    const resultAction = await dispatch(
      changeItem({
        slug: order.slug,
        item: item,
        price: values.price,
        quantity: values.quantity,
        tax: values.tax,
        remarks: values.remarks,
        deliveryDateStart: values.deliveryDateStart,
        supplierOrderNumber: values.supplierOrderNumber,
      }),
    );
    if (changeItem.fulfilled.match(resultAction)) {
      onClose();
    } else {
      if (resultAction.payload) {
        setErrors({ error: resultAction.payload.message });
      }
    }
  };

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={createInitialValues(
        item,
        order.price_min_fraction,
        order.price_max_fraction,
      )}
      onSubmit={handleSubmit}
      validationSchema={createValidationSchema(order, item, t)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form>
          <ModalBody>
            <div className="container">
              <div className="row mb-2">
                <div className="offset-8 offset-sm-9 col text-left mt-auto">
                  {item.is_changed && (
                    <i>
                      {t("common.original")} {t("common.value")}
                    </i>
                  )}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("orders.quantity")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={
                      "input-group " + (errors.quantity && "has-error")
                    }
                  >
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text"
                        style={{
                          width: `${t(item.unit).length * 1}rem`,
                          minWidth: "1rem",
                          border: "none",
                        }}
                      >
                        {t(item.unit)}
                      </div>
                    </div>
                    <Field
                      name={`quantity`}
                      onChange={(e) =>
                        setFieldValue(
                          "quantity",
                          e.target.value
                            .replace(",", ".")
                            .replace(/[^\d.]/g, ""),
                        )
                      }
                      className={`form-control ${
                        errors.quantity ? "is-invalid" : ""
                      }`}
                      disabled={
                        !item.quantity_change_allowed ||
                        item.partials.current.filter((p) => !p.deleted).length >
                          0
                      }
                    />
                    <ErrorMessage
                      name="quantity"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col my-auto text-left">
                  {formatNumber(item.quantity.original) !==
                    formatNumber(item.quantity.current) && (
                    <i>
                      {formatNumber(item.quantity.original, 0, 0)}{" "}
                      {t(item.unit)}
                    </i>
                  )}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("orders.price")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={"input-group " + (errors.price && "has-error")}
                  >
                    <div className="input-group-prepend">
                      <div
                        className="input-group-text"
                        style={{
                          width: `${t(item.unit).length * 1}rem`,
                          border: "none",
                        }}
                      >
                        {order.currency.symbol}
                      </div>
                    </div>
                    <Field
                      name={`price`}
                      onChange={(e) =>
                        setFieldValue(
                          "price",
                          e.target.value
                            .replace(",", ".")
                            .replace(/[^\d.]/g, ""),
                        )
                      }
                      className={`form-control ${
                        errors.price ? "is-invalid" : ""
                      }`}
                      disabled={!item.price_change_allowed}
                    />
                    <ErrorMessage
                      name="price"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col my-auto text-left">
                  {formatNumber(
                    item.price.original,
                    order.price_min_fraction,
                    order.price_max_fraction,
                  ) !==
                    formatNumber(
                      item.price.current,
                      order.price_min_fraction,
                      order.price_max_fraction,
                    ) && (
                    <i>
                      {formatNumber(
                        item.price.original,
                        order.price_min_fraction,
                        order.price_max_fraction,
                      )}{" "}
                      {order.currency.symbol}
                    </i>
                  )}
                </div>
              </div>
              {order.has_tax_details && (
                <div className="row mb-2">
                  <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                    {t("orders.vat")}
                  </div>
                  <div className="col-6 col-lg-4 text-left">
                    <div
                      className={"input-group " + (errors.tax && "has-error")}
                    >
                      <div className="input-group-prepend">
                        <div
                          className="input-group-text"
                          style={{
                            width: `${t(item.unit).length * 1}rem`,
                            border: "none",
                          }}
                        >
                          %
                        </div>
                      </div>
                      <Field
                        name={`tax`}
                        //pattern={"[0-9\.]*"}
                        onChange={(e) =>
                          setFieldValue(
                            "tax",
                            e.target.value
                              .replace(",", ".")
                              .replace(/[^\d.]/g, ""),
                          )
                        }
                        className={`form-control ${
                          errors.tax ? "is-invalid" : ""
                        }`}
                        disabled={!item.tax_change_allowed}
                      />
                      <ErrorMessage
                        name="tax"
                        render={(msg) => (
                          <div className="order-last invalid-feedback">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col my-auto text-left">
                    {formatNumber(item.tax.original) !==
                      formatNumber(item.tax.current) && (
                      <i>{formatNumber(item.tax.original)} %</i>
                    )}
                  </div>
                </div>
              )}
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("orders.delivery_date")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={"input-group " + (errors.remarks && "has-error")}
                  >
                    <Datepicker
                      htmlFor="deliveryDateStart"
                      selected={values.deliveryDateStart}
                      disabled={
                        !item.delivery_date_change_allowed ||
                        item.partials.current.filter((p) => !p.deleted).length >
                          0
                      }
                      //showWeekNumbers
                      dateFormat="yyyy-MM-dd"
                      minDate={new Date()}
                      onChange={(date) =>
                        setFieldValue("deliveryDateStart", date)
                      }
                      className={`form-control ${
                        !!errors.deliveryDateStart ? "is-invalid" : ""
                      }`}
                    />
                  </div>
                </div>
                <div className="col my-auto text-left">
                  {(!!item.delivery_date.current.start
                    ? item.delivery_date.current.start.date
                    : null) !==
                    (!!item.delivery_date.original.start
                      ? item.delivery_date.original.start.date
                      : null) && (
                    <i>
                      {!!item.delivery_date.original.start ? (
                        item.delivery_date.original.start.date
                      ) : (
                        <i>({t("common.empty")})</i>
                      )}
                    </i>
                  )}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("orders.supplier_order_number")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={
                      "input-group " +
                      (errors.supplierOrderNumber && "has-error")
                    }
                  >
                    <Field
                      name="supplierOrderNumber"
                      className={`form-control ${
                        errors.supplierOrderNumber ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="supplierOrderNumber"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col my-auto text-left">
                  {!!item.supplier_order_number && (
                    <i>
                      <i>({t("common.empty")})</i>
                    </i>
                  )}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("common.note")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={"input-group " + (errors.remarks && "has-error")}
                  >
                    <Field
                      name="remarks"
                      placeholder={t("form.message_placeholder")}
                      className={`form-control ${
                        errors.remarks ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="remarks"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col my-auto text-left">
                  {!!item.remarks_new && (
                    <i>
                      {!!item.remarks_new ? <i>({t("common.empty")})</i> : ""}
                    </i>
                  )}
                </div>
              </div>
            </div>

            {DEV_MODE && (
              <pre
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}
            {!!errors.error && (
              <div className="alert alert-danger m-t-sm">{errors.error}</div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="btn-group">
              <button className="btn btn-primary" type={"submit"}>
                <i className="fa fa-save" /> {t("form.save")}
              </button>
              <button className="btn btn-danger" onClick={() => onClose()}>
                <i className="fa fa-close" /> {t("form.cancel")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

export default OrderItemEditTab;

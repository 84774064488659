/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchOrderNotificationsBySlug,
  selectOrderNotifications,
} from "./ducks/slice";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";

function OrderNotification() {
  const { slug } = useParams();

  const notifications = useSelector(selectOrderNotifications({ slug: slug }));

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchOrderNotificationsBySlug({ slug }));
    const id = setInterval(
      () => dispatch(fetchOrderNotificationsBySlug({ slug })),
      5000,
    );
    return () => clearInterval(id);
  }, []);

  const rows = notifications.map((n, i) => (
    <tr key={i}>
      <td className="text-center" style={{ width: 1 }}>
        {n.status === "PENDING" && <Spinner timeout={0} />}
        {n.status !== "PENDING" && n.type === "MAIL" && (
          <i className="fa fa-envelope" data-toggle="tooltip" title="E-Mail" />
        )}
      </td>
      <td className="text-nowrap">{n.recipient}</td>
      <td>{n.subject}</td>
      <td style={{ width: 1 }}>
        <span
          className={`small label label-${
            (n.status === "PENDING" && "warning") ||
            (n.status === "DELAY" && "warning") ||
            (n.status === "FAILED" && "danger") ||
            (n.status === "DELIVERED" && "primary") ||
            (n.status === "SUPPRESSED" && "danger")
          }`}
        >
          {n.status}
        </span>
      </td>
      <td className="text-nowrap" style={{ width: 1 }}>
        {!!n.sent_at && n.sent_at.fmt}
      </td>
      <td className="text-nowrap" style={{ width: 1 }}>
        {!!n.delivered_at && n.delivered_at.fmt}
      </td>
      <td>{n.description}</td>
    </tr>
  ));

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th className="text-left" style={{ width: 1 }}>
              {t(`orders.notifications.type`)}
            </th>
            <th>{t(`orders.notifications.address`)}</th>
            <th>{t(`orders.notifications.subject`)}</th>
            <th>{t(`orders.notifications.status`)}</th>
            <th>{t(`orders.notifications.sent`)}</th>
            <th>{t(`orders.notifications.delivered`)}</th>
            <th>{t(`orders.notifications.description`)}</th>
          </tr>
        </thead>
        <tbody>{!!rows && rows}</tbody>
      </table>
    </div>
  );
}

export default OrderNotification;

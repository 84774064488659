/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

// https://date-fns.org/
// alternative to momentjs!
import { de } from "date-fns/locale";
registerLocale("de", de);

export default function Datepicker(props) {
  // eslint-disable-next-line react/prop-types
  const { htmlFor, label } = props;

  return (
    <>
      {label ? <label htmlFor={htmlFor}>{label}</label> : null}
      <DatePicker
        //locale={de}
        //selected={selected}
        //onChange={onChange}
        //className="form-control"
        name={htmlFor}
        //disabled={disabled}
        // https://date-fns.org/v2.12.0/docs/format
        //dateFormat="P"
        //showPreviousMonths
        //monthsShown={2}
        {...props}
        //dateFormat="Pp"
      />
    </>
  );
}

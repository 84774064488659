/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";
import { useTranslation } from "react-i18next";

export function Contact({ name }) {
  return (
    <span>
      <br />
      <br />
      <i className="fa fa-user" /> {name}
    </span>
  );
}

export default function Address({
  ids = [],
  name,
  name2,
  name3,
  street,
  zip,
  city,
  country,
  state,
  country_code,
  vat_id,
  phone,
  email,
  url,
  fax,
  contacts,
  role_info = null,
}) {
  const { t } = useTranslation();
  return (
    <>
      {!!role_info && (
        <span className="pull-right">
          <small>{role_info}</small>
        </span>
      )}
      <address className={!!role_info ? "m-t-md" : undefined}>
        <strong>{name}</strong>
        {!!name2 && (
          <span>
            <br />
            {name2}
          </span>
        )}
        {!!name3 && (
          <span>
            <br />
            {name3}
          </span>
        )}
        {!!street && (
          <span>
            <br />
            {street}
          </span>
        )}
        {!!zip && (
          <span>
            <br />
            {zip}{" "}
          </span>
        )}
        {!!city && <span>{city} </span>}{" "}
        {!!state && (
          <span>
            <br />
            {state}
          </span>
        )}
        {!!country && (
          <span>
            {!!state ? " " : <br />}
            {country}
          </span>
        )}
        {!!country_code && <span>{country_code}</span>}
        {!!vat_id && (
          <span>
            <br />
            {t("orders.vat_id")}: {vat_id}
          </span>
        )}
        {(!!phone || !!email || !!fax) && <br />}
        {!!phone && (
          <span>
            <br />
            <i className="fa fa-phone" />
            <a href={`tel:${phone.number}`}> {phone.number}</a>
          </span>
        )}
        {!!fax && (
          <span>
            <br />
            <i className="fa fa-fax" />
            <a href={`fax:${fax}`}> {fax}</a>
          </span>
        )}
        {!!email && (
          <span>
            <br />
            <i className="fa fa-envelope" />
            <a href={`mailto:${email}`}> {email}</a>
          </span>
        )}
        {!!url && (
          <span>
            <br />
            <i className="fa fa-globe" />
            <a href={url}> {url}</a>
          </span>
        )}
        {contacts.length > 0 &&
          contacts.map((c, i) => <Contact key={i} {...c} />)}
        {ids.length > 0 && (
          <ul className="m-t-sm list-unstyled">
            {ids
              .filter((pid) => !!pid.id)
              .map((pid) => (
                <li key={pid.id}>
                  {pid.type.toUpperCase()}: {pid.id}
                </li>
              ))}
          </ul>
        )}
      </address>
    </>
  );
}

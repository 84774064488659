const translation = {
  attributes: {
    attribute: "Attribut",
    attributes: "Attribute",
    label: "Bezeichnung",
    value: "Wert",
    type: "Type",
    types: {
      text: "Text",
      number: "Zahl",
      date: "Datum",
      time: "Uhrzeit",
      select: "Auswahl",
      errors: {
        number: "Wert muss einer gültigen Zahl entsprechen",
        date: "Wert muss einem gültigen Datum entsprechen",
        time: "Wert muss einer gültigen Zeit entsprechen",
      },
    },
  },
  activities: {
    activity: "Aktivitäten",
    order: {
      link: {
        opened: "Link für Bestell-Nr. {{number}} wurde geöffnet",
      },
      accepted: "Bestell-Nr. {{number}} wurde angenommen",
      rejected: "Bestell-Nr. {{number}} wurde abgelehnt",
      changed: "Bestell-Nr. {{number}} wurde geändert",
      field: {
        changed:
          "$t({{field_t_key}}) wurde von $t({{before}}, '') $t({{suffix}}, '') auf $t({{after}}, '') $t({{suffix}}, '') geändert",
      },
      replaced:
        'Bestell-Nr. {{number}} wurde durch Auftraggeber ersetzt. Nachfolgende Version unter folgendem <a class="btn btn-xs btn-light" alt="Link" href="{{host.url}}ui/orders/{{descendant_slug}}"><i class="fa fa-link"></i> Link</a> abrufbar',
      replaces:
        'Bestell-Nr. {{number}} wurde durch Auftraggeber ersetzt. Vorhergehende Version unter folgendem <a class="btn btn-xs btn-light" alt="Link" href="{{host.url}}ui/orders/{{ancestor_slug}}"><i class="fa fa-link"></i> Link</a> abrufbar',
      canceled: "Bestellung Nr. {{number}} wurde durch Auftraggeber storniert",
      upload: "{{filename}} wurde hochgeladen",
      upload_delete: "{{filename}} wurde gelöscht",
      allowances_and_charges: {
        added:
          "{{value}} {{suffix}} $t({{aoc_t_key}}) mit $t({{seq_t_key}}) Nr. {{seq}} und $t(common.note) $t({{note}}, 'common.empty')",
      },
      item: {
        changed: "Artikel-Nr. {{number}} auf Position {{line}} wurde geändert",
        field: {
          changed:
            "$t({{field_t_key}}) wurde von $t({{before}}, '') $t({{suffix}}, '') auf $t({{after}}, '') $t({{suffix}}, '') geändert",
        },

        partial: {
          added:
            "Teillieferung geändert auf Position $t({{pos}}, '') $t(common.note) $t({{note}}, 'common.empty') $t({{quantity}}, '') $t({{suffix}}, '') am $t({{delivery_at}}, '')",
        },

        allowances_and_charges: {
          added:
            "{{value}} {{suffix}} $t({{aoc_t_key}}) mit $t({{seq_t_key}}) Nr. {{seq}} wurde angewendet auf $t({{apply_t_key}}) mit $t(common.note) $t({{note}}, 'common.empty')",
        },
        attributes: {
          added: "Attribut {{label}} mit Wert {{value}} wurde hinzugefügt",
          changed: "Attribut {{label}} mit Wert {{value}} wurde geändert",
          deleted: "Attribut {{label}} mit Wert {{value}} wurde gelöscht",
        },
      },
      notification: {
        resend:
          "Bestellbenachrichtigung wurde erneut versendet an {{recipient}}",
      },
    },
  },
  despatch_advices: {
    shipmentNumber: "Trackingnummer",
    shipmentUrl: "Tracking URL",
    delivery_address: "Lieferadresse",
    order_items: "Bestellartikel",
    addresses: "Adressen",
    delete_despatch_advice: "Lieferavis löschen?",
    despatch_advices: "Lieferavise",
    despatch_advice: "Lieferavis",
    number: "Lieferscheinnummer",
    delivery_date: "Lieferdatum",
    ship_date: "Versanddatum",
    supplier: "Lieferant",
    buyer: "Auftraggeber",
    description: "Beschreibung",
    item_number: "Artikel Nr.",
    unit: "Einheit",
    quantity: "Menge",
    create_by_items: "Erstellen mit allen Artikeln",
    create_by_delivery_date: "Erstellen nach Lieferdatum",
    create_empty: "Leer erstellen",
    item: "Artikel",
    items: "Artikel",
    create_new: "Neuer",
    reference: "Referenz",
    reference_date: "Referenzdatum",
    help: {
      1: "Zur Lieferung ausstehende Artikel",
      2: "Zur Lieferung bestätigte Artikel",
      3: "Liefermenge für diese Lieferavis",
    },
    permissions: {
      edit: {
        false: "Die Lieferavis ist für die Bearbeitung gesperrt.",
      },
    },
  },
  orders: {
    orders: "Bestellungen",
    orders_overview: "Übersicht Bestellungen",
    overview: {
      responses: "Bestätigungen",
      tab: {
        info: "Das Suchfeld ermöglicht die Suche nach Bestell- oder Auftragsnummer. Über die Aktionen können Sie die Bestellung einsehen oder weiterleiten bzw. erneut versenden",
        info_auth:
          "Das Suchfeld ermöglicht die Suche nach Bestell-, Auftragsnummer oder Lieferant. Über die Aktionen können Sie die Bestellung einsehen oder weiterleiten bzw. erneut versenden",
      },
      menu: {
        overview: "Übersicht Bestellungen",
        all: "Alle",
        due: "Überfällig",
        pending: "Offen",
        state: "Status",
        rejected: "Abgelehnt",
        accepted: "Bestätigt",
        replaced: "Ersetzt",
        canceled: "Storniert",
        open_order: "Bestellung ansehen",
        resent_notification: "Bestellbenachrichtigung erneut versenden",
        supplier_recipients: "Empfänger",
      },
      resend_notification: "Bestellbenachrichtigung",
    },
    permissions: {
      edit: {
        false: "Die Bestellung ist für die Bearbeitung gesperrt.",
      },
    },
    changes: {
      replaced: {
        current:
          "Diese Bestellung wurde vom Auftraggeber nachträglich angepasst. <br/>Über das Menü „Aktivitäten“ steht Ihnen die originale Bestellung weiterhin im Lesezugriff zur Verfügung.",
        ancestor:
          "Diese Bestellung wurde vom Auftraggeber durch eine neue Version ersetzt.",
      },
      canceled: "Diese Bestellung wurde vom Besteller storniert.",
    },
    items: "Bestellartikel",
    order: "Bestellung",
    number: "Bestellnummer",
    date: "Bestelldatum",
    supplier: "Lieferant",
    supplier_order_number: "Auftragsnummer",
    buyer: "Besteller",
    invoice_address: "Rechnungsadresse",
    delivery_address: "Lieferadresse",
    description: "Beschreibung",
    item_number: "Artikel Nr.",
    unit: "Einheit",
    quantity: "Menge",
    sum: "Summe",
    total: "Summe",
    net: "Netto",
    gross: "Brutto",
    price: "Preis",
    price_unit: "Preis Einheit",
    total_quantity: "Gesamtmenge",

    partial_deliveries: "Teillieferungen",
    partial_delivery_at: "Teillieferung am",
    partial_delivery_title:
      "Teillieferung für Artikel in Zeile {{line}} mit Nummer:",
    item_edit_modal_header_title: "Artikel in Zeile {{line}} mit Nummer",
    delivery_date: "Lieferdatum",

    confirm_order:
      "Sind Sie sicher, dass Sie die Bestellung bestätigen möchten?",
    reject_order: "Sind Sie sicher, dass Sie die Bestellung ablehnen möchten?",
    response: "Bestätigung",

    change_delivery_date: "Original Lieferdatum {{original_start_date}} ändern",
    change_delivery_date_hover: "Lieferdatum ändern",

    please_enter_supplier_order_number: "Bitte Auftragsnummer eingeben",

    order_response_created:
      "Ihre Auftragsbestätigung wurde erfolgreich erstellt",

    notifications: {
      title: "Benachrichtigungen",
      type: "Art",
      address: "Adresse",
      subject: "Betreff",
      status: "Status",
      sent: "Gesendet",
      delivered: "Geliefert",
      description: "Beschreibung",
      info: "Empfänger der Bestellbenachrichtigung bestätigen oder ändern.",
      resend:
        "Sind Sie sicher, dass Sie die Bestellbenachrichtigung erneut versenden möchten?",
      resend_alert:
        "Eine neue Bestellbenachrichtigung für Bestellung {{number}} wurde erfolgreich übermittelt",
    },

    demo: {
      title:
        "Neugierig auf eine Ad hoc-Lösung für Ihre digitalen Bestellprozesse?",
      description:
        "Das Portal zeigt anhand einer Beispiel-Bestellung, wie übersichtlich und einfach diese E-Procurement-" +
        "Lösung zu bedienen ist: Ihre Lieferanten erfassen komfortabel die von Ihnen bestellten Produkte, können " +
        "eine Nachricht hinterlassen und den jeweiligen Auftrag bestätigen, verwerfen oder einzelne Positionen " +
        "ändern. Überzeugen Sie sich selbst von der mühelosen Anwendung des WebConnect-Portals, das ganz " +
        "ohne Login, schnell, automatisiert und spielend einfach funktioniert.",
      form: {
        footer: "Username: demo, Password: demo",
        title:
          "Geben Sie hier Ihre E-Mail-Adresse* ein, um zur Live-Demo von Retarus WebConnect for Suppliers zu gelangen:",
        email: "Ihre E-Mail-Adresse",
      },

      ol: {
        title:
          "An Ihre E-Mail-Adresse werden zur Demonstration bis zu drei E-Mails gesendet:",
        1: "Eine Beispiel-Bestellung zur Veranschaulichung, wie Lieferanten einen Auftrag von Ihnen via E-Mail erhalten.",
        2: "Eine Beispiel-Bestätigung, wie ein Lieferant sie erhält, nachdem er Ihre Bestellung im WebConnect-Portal bearbeitet oder bestätigt hat.",
        3: "Eine Beispiel-Nachricht, die das Monitoring offener Bestellungen innerhalb eines definierten Zeitrahmens zeigt. Im realen Anwendungsfall würden Ihre Mitarbeiter im Einkauf eine solche E-Mail erhalten.",
        footer: "Wir wünschen Ihnen viel Erfolg mit WebConnect for Suppliers!",
      },

      footer: {
        title:
          "Bei Fragen zu WebConnect for Suppliers stehen wir Ihnen gerne zur Verfügung.",
      },

      quote: {
        1: "* Selbstverständlich verwenden wir Ihre E-Mail-Adresse ausschließlich zur Demonstration von Retarus WebConnect for Suppliers; sie wird nicht für Werbezwecke genutzt oder gespeichert.",
      },

      items: {
        1: {
          unit: "Stück",
          description:
            "Hochleistungskeilr. SUPER XE-POWER PRO XPA 1180 Lw (1135 Li / 1198 La) flankenoffen, formgezahnt",
        },
        2: {
          unit: "Stück",
          description: "Gelenkverbinder Endliche Keilriemen Z/10",
        },
        3: {
          unit: "Stück",
          description:
            "Klassische Keilriemen DIN2215 / ISO4184 A55 13 x 1400 Li ( 1430 Lw / 1450 La) ummantelt; Satzkonstant (S=C PLUS)",
        },
        4: {
          unit: "Stück",
          description: "Endliche Keilriemen DIN2216, gelocht A/13",
        },
      },
    },

    uploads: {
      click_me: "Bitte klicken um Dateien hoch zu laden",
      delete_btn: "Upload löschen",
    },

    vat: "MwSt",
    vat_id: "USt-IdNr",

    item: "Artikel",

    allowances_and_charges: "Zu- und Abschläge",
    amount: "Betrag",
  },
  form: {
    message_placeholder: "Hinterlassen Sie Ihre Nachricht...",
    confirm: "Bestätigen",
    confirmed: "Bestätigt",
    reject: "Ablehnen",
    rejected: "Abgelehnt",
    save: "Speichern",
    cancel: "Abbrechen",
    send: "Senden",

    placeholder: {
      email: "Ihre E-Mail Adresse",
    },
  },

  common: {
    demo: "Demo",
    from: "von",
    to: "bis",
    overview: "Übersicht",
    actions: "Aktionen",
    modified: "Verändert",
    reload: "Erneut laden",
    restore: "Wiederherstellen",
    advised: "Avisiert",
    current: "Aktuell",
    ordered: "Bestellt",
    confirmed: "Bestätigt",
    delivered: "Geliefert",
    not_available: "N/A",
    add: "Hinzufügen",
    search: "Suche",
    committed: "Bestätigt",
    remaining: "Verbleibend",
    delete: "Löschen",
    completed: "Abgeschlossen",
    change_lang: "Sprache wechseln",
    date: "Datum",
    note: "Hinweis",
    attachments: "Downloads",
    uploads: "Uploads",
    at: "am",
    print: "Drucken",
    imprint: "Impressum",
    privacy_policy: "Datenschutz",
    number: "Zahl",
    additional: "Weiteres",
    original: "Original",
    value: "Wert",
    empty: "Leer",
    allowance: "Abschlag",
    charge: "Zuschlag",
    subtotal: "Zwischensumme",
    sequence: "Sequenz",
    description: "Beschreibung",
    apply: "Anwenden",
    type: "Typ",
    per: "auf",
    discount: "Rabatt",
    freight_charge: "Frachtkosten",
    edit: "Editieren",
    please_enter: "Bitte eingeben",
    ean: "EAN",
    draft: "Entwurf",
    number_of_decimal_places: "Anzahl an Nachkommastellen",
    submit: "Submit",
  },

  errors: {
    not_found: "Ressource nicht gefunden",
    token_expired:
      "Gültigkeit des Links abgelaufen. Rufen Sie die Bestellübersicht erneut über eine Einzelbestellung auf",
    rate_limit_reached: "Rate Limit erreicht",
    email: "{{attr}} muss gültiger E-Mail Adresse entsprechen",
    url: "{{attr}} muss gültiger URL entsprechen",
    uuid: "{{attr}} muss gültiger UUID entsprechen",
    lowercase: "{{attr}} muss kleingeschrieben sein",
    uppercase: "{{attr}} muss großgeschrieben sein",
    integer: "{{attr}} muss ganzzahlig sein",
    positive: "{{attr}} muss positiv sein",
    negative: "{{attr}} muss negativ sein",
    matches: "{{attr}} muss dem Schema {{value}} entsprechen",
    length: "{{attr}} muss {{value}} Zeichen lang sein",
    eq: "{{attr}} muss gleich {{value}} sein",
    gt: "{{attr}} muss größer {{value}} sein",
    gte: "{{attr}} muss größer oder gleich {{value}} sein",
    lte: "{{attr}} muss kleiner oder gleich {{value}} sein",
    valid: "{{type}} notwendig",
    required: "{{attr}} ist notwendig",
    irregular: {
      "too many files": "Zu viele Dateien",
    },
  },
};

export default translation;

const translation = {
  attributes: {
    attribute: "แอตทริบิวต์",
    attributes: "แอตทริบิวต์",
    label: "ป้ายกำกับ",
    value: "ค่า",
    type: "ประเภท",
    types: {
      text: "ข้อความ",
      number: "หมายเลข",
      date: "วันที่",
      time: "เวลา",
      select: "เลือก",
      errors: {
        number: "ค่าต้องเป็นเลขที่ถูกต้อง",
        date: "ค่าต้องเป็นวันที่ถูกต้อง",
        time: "ค่าต้องเป็นเวลาที่ถูกต้อง",
      },
    },
  },
  activities: {
    activity: "กิจกรรม",
    order: {
      link: {
        opened: "มีการเปิดลิงก์สำหรับคำสั่งซื้อหมายเลข {{number}} แล้ว",
      },
      accepted: "คำสั่งซื้อหมายเลข {{number}} ได้รับการยอมรับแล้ว",
      rejected: "คำสั่งซื้อหมายเลข {{number}} ถูกปฏิเสธ",
      changed: "มีการเปลี่ยนแปลงคำสั่งซื้อหมายเลข {{number}} แล้ว",
      field: {
        changed:
          "มีการเปลี่ยนแปลง $t({{field_t_key}}) จาก $t({{before}}, '') $t({{suffix}}, '') เป็น $t({{after}}, '') $t({{suffix}}, '') แล้ว",
      },
      replaced:
        'หมายเลขใบสั่งซื้อ {{number}} ถูกแทนที่โดยผู้ซื้อ เวอร์ชั่นใหม่ได้ที่ลิงค์ต่อไปนี้<a class="btn btn-xs btn-light" alt="link" href="{{host.url}}ui/orders/{{descendant_slug}}"><i class="fa fa-link"></i> Link</a>',
      replaces:
        'หมายเลขใบสั่งซื้อ {{number}} ถูกแทนที่โดยผู้ซื้อ รุ่นก่อนหน้าได้ที่ลิงค์ต่อไปนี้<a class="btn btn-xs btn-light" alt="link" href="{{host.url}}ui/orders/{{ancestor_slug}}"><i class="fa fa-link"></i> Link</a>',
      canceled: "ผู้ซื้อได้ยกเลิกคำสั่งซื้อหมายเลข {{number}} แล้ว",
      upload: "{{filename}} อัปโหลดเสร็จแล้ว",
      upload_delete: "{{filename}} ถูกลบออกแล้ว",
      allowances_and_charges: {
        added:
          "{{value}} {{suffix}} $t({{aoc_t_key}}) ที่มี $t({{seq_t_key}}) หมายเลข {{seq}} และ $t(common.note) $t({{note}}, 'common.empty')",
      },
      item: {
        changed:
          "มีการเปลี่ยนแปลงสินค้าหมายเลข {{number}} บนตำแหน่ง {{line}} แล้ว",
        field: {
          changed:
            "มีการเปลี่ยนแปลง $t({{field_t_key}}) จาก $t({{before}}, '') $t({{suffix}}, '') เป็น $t({{after}}, '') $t({{suffix}}, '') แล้ว",
        },

        partial: {
          added:
            "การจัดส่งบางส่วนที่มีการเปลี่ยนแปลงบนตำแหน่ง $t({{pos}}, '') $t(common.note) $t({{note}}, 'common.empty') $t({{quantity}}, '') $t({{suffix}}, '') บน $t({{delivery_at}}, '')",
        },
        allowances_and_charges: {
          added:
            "{{value}} {{suffix}} $t({{aoc_t_key}}) ที่มี $t({{seq_t_key}}) หมายเลข {{seq}} ใช้กับ $t({{apply_t_key}}) ที่มี $t(common.note) $t({{note}}, 'common.empty')",
        },
        attributes: {
          added: "เพิ่มแอตทริบิวต์ {{label}} ที่มีค่า {{value}} แล้ว",
          changed: "แก้ไขแอตทริบิวต์ {{label}} ที่มีค่า {{value}} แล้ว",
          deleted: "ลบแอตทริบิวต์ {{label}} ที่มีค่า {{value}} แล้ว",
        },
      },
      notification: {
        resend: "ส่งการแจ้งเตือนคำสั่งไปให้ผู้รับ {{recipient}} อีกครั้งแล้ว ",
      },
    },
  },
  despatch_advices: {
    shipmentNumber: "หมายเลขพัสดุ",
    shipmentUrl: "URL สำหรับติดตาม",
    delivery_address: "การจัดส่ง",
    order_items: "รายการสั่งซื้อ",
    addresses: "ที่อยู่",
    delete_despatch_advice: "ลบคำแนะนำในการส่งหรือไม่",
    despatch_advices: "คำแนะนำการส่ง",
    despatch_advice: "คำแนะนำการส่ง",
    number: "หมายเลข ASN",
    delivery_date: "วันที่จัดส่ง",
    ship_date: "วันที่จัดส่ง",
    supplier: "ซัพพลายเออร์",
    buyer: "ผู้ซื้อ",
    description: "คำอธิบาย",
    item_number: "หมายเลขสินค้า",
    unit: "รายการ",
    quantity: "จำนวน",
    create_by_items: "สร้างตามรายการ",
    create_by_delivery_date: "สร้างตามวันที่จัดส่ง",
    create_empty: "สร้างค่าว่าง",
    item: "รายการ",
    items: "รายการ",
    create_new: "ใหม่",
    reference: "อ้างอิง",
    reference_date: "วันที่อ้างอิง",
    help: {
      1: "รายการสำหรับจัดส่งที่คงเหลือ",
      2: "รายการสำหรับจัดส่งที่ยืนยัน",
      3: "ปริมาณสำหรับคำแนะนำการส่งนี้",
    },
    permissions: {
      edit: {
        false: "มีการล็อกคำแนะนำการส่งไว้สำหรับแก้ไข",
      },
    },
  },
  orders: {
    orders: "คำสั่งซื้อ",
    orders_overview: "ภาพรวมของคำสั่ง",
    overview: {
      responses: "การยืนยัน",
      tab: {
        info: "ช่องค้นหาช่วยให้คุณสามารถค้นหารายการตามหมายเลขคำสั่งหรือหมายเลขคำสั่งจากซัพพลายเออร์ได้ การดำเนินการต่างๆ ทำให้คุณสามารถดู ส่งต่อ หรือส่งคำสั่งอีกครั้งก็ได้",
        info_auth:
          "ช่องค้นหาช่วยให้คุณค้นหาตามหมายเลขคำสั่งซื้อ หมายเลขคำสั่งซื้อของซัพพลายเออร์ หรือชื่อซัพพลายเออร์ การดำเนินการช่วยให้คุณสามารถดู ส่งต่อ หรือส่งคำสั่งซื้ออีกครั้งได้",
      },
      menu: {
        overview: "ภาพรวมของคำสั่ง",
        all: "ทั้งหมด",
        due: "หมดอายุแล้ว",
        pending: "รอดำเนินการ",
        state: "สถานะ",
        rejected: "ถูกปฏิเสธ",
        accepted: "ยอมรับแล้ว",
        replaced: "ถูกแทนที่",
        canceled: "ถูกยกเลิก",
        open_order: "ดูคำสั่ง",
        resent_notification: "ส่งการแจ้งเตือนคำสั่งอีกครั้ง",
        supplier_recipients: "ผู้รับ",
      },
      resend_notification: "การแจ้งเตือนคำสั่ง",
    },
    permissions: {
      edit: {
        false: "มีการล็อกคำสั่งซื้อไว้สำหรับแก้ไข",
      },
    },
    changes: {
      replaced: {
        current:
          "ผู้ซื้อได้เปลี่ยนแปลงคำสั่งซื้อนี้แล้ว <br/>ยังคงสามารถเข้าถึงคำสั่งซื้อเดิมแบบอ่านอย่างเดียวได้ผ่านเมนูกิจกรรม",
        ancestor: "ผู้ซื้อได้เปิดคำสั่งซื้อใหม่แทนที่คำสั่งซื้อนี้แล้ว",
      },
      canceled: "ผู้ซื้อได้ยกเลิกคำสั่งซื้อนี้แล้ว",
    },
    items: "รายการสั่งซื้อ",
    order: "คำสั่งซื้อ",
    number: "หมายเลขคำสั่งซื้อ",
    date: "วันที่ของคำสั่งซื้อ",
    supplier: "ซัพพลายเออร์",
    supplier_order_number: "หมายเลขคำสั่งซื้อของซัพพลายเออร์",
    buyer: "ผู้ซื้อ",
    invoice_address: "ใบแจ้งหนี้",
    delivery_address: "การจัดส่ง",
    description: "คำอธิบาย",
    item_number: "หมายเลขสินค้า",
    unit: "รายการ",
    quantity: "จำนวน",
    sum: "ผลรวม",
    total: "รวม",
    net: "สุทธิ",
    gross: "สุทธิ",
    price: "ราคา",
    price_unit: "หน่วยราคา",
    total_quantity: "จำนวนรวม",

    partial_deliveries: "การจัดส่งบางส่วน",
    partial_delivery_at: "การจัดส่งบางส่วน",
    partial_delivery_title:
      "การจัดส่งบางส่วนสำหรับสินค้าในบรรทัดที่ {{line}} ที่มีหมายเลข",
    item_edit_modal_header_title: "สินค้าในบรรทัดที่ {{line}} ที่มีหมายเลข",
    delivery_date: "วันที่จัดส่ง",

    confirm_order: "คุณแน่ใจหรือไม่ว่าต้องการยืนยันคำสั่งซื้อ",
    reject_order: "คุณแน่ใจหรือไม่ว่าต้องการปฏิเสธคำสั่งซื้อ",
    response: "การตอบกลับ",

    change_delivery_date: "เปลี่ยนวันที่จัดส่งเดิม {{original_start_date}}",
    change_delivery_date_hover: "เปลี่ยนวันที่จัดส่ง",

    please_enter_supplier_order_number:
      "โปรดกรอกหมายเลขคำสั่งซื้อของซัพพลายเออร์",

    order_response_created: "สร้างการยืนยันคำสั่งซื้อของคุณเรียบร้อยแล้ว",

    notifications: {
      title: "การแจ้งเตือน",
      type: "ประเภท",
      address: "ที่อยู่",
      subject: "หัวเรื่อง",
      status: "สถานะ",
      sent: "ส่งแล้ว",
      delivered: "ได้รับแล้ว",
      description: "คำอธิบาย",
      info: "ยืนยันหรือเปลี่ยนแปลงผู้รับแจ้งคำสั่งซื้อ",
      resend: "คุณแน่ใจหรือไม่ว่าต้องการส่งการแจ้งเตือนคำสั่งอีกครั้ง",
      resend_alert:
        "ส่งการแจ้งเตือนคำสั่งใหม่สำหรับคำสั่ง {{number}} สำเร็จแล้ว",
    },

    demo: {
      title: "สนใจนำโซลูชันครบวงจรไปใช้ในกระบวนการสั่งซื้อใช่ไหม",
      description:
        "พอร์ทัล WebConnect จะใช้คำสั่งซื้อเป็นตัวอย่างเพื่อแสดงให้เห็นว่าการใช้บริการจัดซื้ออิเล็กทรอนิกส์และจัดการคำสั่งซื้อนั้นทำได้ง่ายเพียงใดในอินเทอร์เฟซที่เรียบง่ายนี้ ซัพพลายเออร์ของคุณสามารถดูสินค้าที่มีการสั่งซื้อได้อย่างสะดวก ฝากข้อความ และเลือกยืนยัน/ปฏิเสธคำสั่งซื้อแต่ละรายการ หรือแม้กระทั่งเปลี่ยนแปลงตำแหน่งของแต่ละรายการได้\n" +
        "ลองมาดูสักครู่ว่าพอร์ทัล WebConnect นั้นใช้งานได้สะดวกและง่ายดายเพียงใด แล้วคุณจะได้พบว่าบริการนี้ทั้งรวดเร็ว เป็นอัตโนมัติ ใช้งานง่าย และไม่จำเป็นต้องใช้บัญชีเพื่อเข้าสู่ระบบ",

      form: {
        footer: "Username: demo, Password: demo",
        title:
          "ป้อนอีเมล*เพื่อเข้าชมการสาธิตการใช้งาน Retarus WebConnect for Suppliers แบบถ่ายทอดสด:",
        email: "อีเมลของคุณ",
      },

      ol: {
        title:
          "การสาธิตนี้จะมีการส่งข้อความ 3 รายการดังนี้ไปยังอีเมลที่คุณลงทะเบียน ",
        1: "ตัวอย่างอีเมลคำสั่งซื้อ ซึ่งแสดงให้เห็นว่าซัพพลายเออร์ของคุณได้รับคำสั่งซื้อที่ส่งไปอย่างไร",
        2: "ข้อความยืนยัน ตามที่ซัพพลายเออร์ของคุณได้รับเมื่อพวกเขาได้แก้ไขหรือยืนยันคำสั่งซื้อในพอร์ทัล WebConnect",
        3: "ตัวอย่างข้อความ ซึ่งแสดงการตรวจสอบคำสั่งซื้อที่เปิดอยู่ภายในระยะเวลาที่กำหนด เมื่อนำบริการไปใช้งานจริง พนักงานในแผนกจัดซื้อจะได้รับอีเมลเหล่านี้",
        footer: "ขอให้ประสบความสำเร็จกับการใช้งาน WebConnect for Suppliers!",
      },

      footer: {
        title:
          "หากมีคำถามเพิ่มเติมเกี่ยวกับ WebConnect for Suppliers คุณสามารถ<0>ติดต่อเรา</0>ได้ทุกเมื่อ",
      },

      quote: {
        1: "*วางใจได้ว่าเราจะใช้อีเมลของคุณเพื่อสาธิตการใช้งาน WebConnect for Suppliers ของ Retarus เท่านั้น และจะไม่บันทึกหรือนำไปใช้เพื่อวัตถุประสงค์ทางการตลาด",
      },

      items: {
        1: {
          unit: "ชิ้น",
          description:
            "สายพานวีประสิทธิภาพสูง SUPER XE-POWER PRO XPA 1180 Lw (1135 Li / 1198 La) ร่องลึกแบบมีฟัน",
        },
        2: {
          unit: "ชิ้น",
          description: "สายพานวีปลายเปิดแบบขั้วต่อยืดหยุ่น Z/10",
        },
        3: {
          unit: "ชิ้น",
          description:
            "สายพานวีคลาสสิก DIN2215 / ISO4184 A55 13 x 1400 Li (1430 Lw / 1450 La) แบบเรียบ, ค่าคงที่ (S=C PLUS)",
        },
        4: {
          unit: "ชิ้น",
          description: "สายพานวีปลายเปิด DIN2216 เจาะรู A/13",
        },
      },
    },

    uploads: {
      click_me: "คลิกฉันเพื่ออัปโหลดไฟล์สิ",
      delete_btn: "ลบไฟล์ที่อัปโหลด",
    },

    vat: "VAT",
    vat_id: "VAT-ID",

    item: "รายการ",

    allowances_and_charges: "งบประมาณและค่าใช้จ่าย",
    amount: "จำนวน",
  },
  form: {
    message_placeholder: "ฝากข้อความของคุณไว้...",
    confirm: "ยืนยัน",
    confirmed: "ยืนยันแล้ว",
    reject: "ปฏิเสธ",
    rejected: "ถูกปฏิเสธ",
    save: "บันทึก",
    cancel: "ยกเลิก",
    send: "ส่ง",

    placeholder: {
      email: "อีเมลของคุณ",
    },
  },

  common: {
    demo: "Demo",
    from: "จาก",
    to: "จนกระทั่ง",

    overview: "ภาพรวม",
    actions: "การดำเนินการ",
    modified: "ถูกแก้ไข",
    reload: "รีโหลด",
    restore: "กู้คืน",
    advised: "แนะนำ",
    current: "ปัจจุบัน",
    ordered: "สั่งซื้อแล้ว",
    confirmed: "ยืนยันแล้ว",
    delivered: "ได้รับแล้ว",
    not_available: "ไม่มี",
    add: "เพิ่ม",
    search: "ค้นหา",
    committed: "ดำเนินการแล้ว",
    remaining: "คงเหลือ",
    delete: "ลบ",
    completed: "เสร็จสิ้นแล้ว",
    change_lang: "เปลี่ยนภาษา",
    date: "วันที่",
    note: "หมายเหตุ",
    attachments: "ไฟล์ที่ดาวน์โหลด",
    uploads: "ไฟล์ที่อัปโหลด",
    at: "เมื่อ",
    print: "พิมพ์",
    imprint: "ประทับตา",
    privacy_policy: "นโยบายความเป็นส่วนตัว",
    number: "หมายเลข",
    additional: "เพิ่มเติม",
    original: "ค่าเดิม",
    value: "ค่า",
    empty: "ว่างเปล่า",
    allowance: "งบประมาณ",
    charge: "ค่าใช้จ่าย",
    subtotal: "ยอดรวม",

    sequence: "ลำดับ",
    description: "คำอธิบาย",
    apply: "ใช้",
    type: "ประเภท",
    per: "ต่อ",
    discount: "ส่วนลด",
    freight_charge: "ค่าขนส่งสินค้า",
    edit: "แก้ไข",
    please_enter: "โปรดกรอก",
    ean: "EAN",
    draft: "ฉบับร่าง",
    number_of_decimal_places: "จำนวนตำแหน่งทศนิยม",
    submit: "ส่ง",
  },

  errors: {
    not_found: "ไม่พบแหล่งข้อมูล",
    token_expired:
      "ลิงก์นี้ใช้ไม่ได้แล้ว เข้าถึงภาพรวมของคำสั่งอีกครั้งผ่านคำสั่งแต่ละรายการ",
    rate_limit_reached: "ถึงขีดจำกัดอัตราแล้ว",
    email: "{{attr}} ต้องตรงกับที่อยู่อีเมลที่ถูกต้อง",
    url: "{{attr}} ต้องตรงกับ URL ที่ถูกต้อง",
    uuid: "{{attr}} ต้องตรงกับ UUID ที่ถูกต้อง",
    lowercase: "{{attr}} ต้องเป็นตัวพิมพ์เล็ก",
    uppercase: "{{attr}} ต้องเป็นตัวพิมพ์ใหญ่",
    integer: "{{attr}} ต้องเป็นจำนวนเต็ม",
    positive: "{{attr}} ต้องเป็นจำนวนเต็มบวก",
    negative: "{{attr}} ต้องเป็นจำนวนเต็มลบ",
    matches: "{{attr}} ต้องตรงกับสคีม่า {{value}}",
    length: "{{attr}} ต้องมี {{value}} อักขระ",
    eq: "{{attr}} ต้องเท่ากับ {{value}}",
    gt: "{{attr}} ต้องมากกว่า {{value}}",
    gte: "{{attr}} ต้องมากกว่าหรือเท่ากับ {{value}}",
    lte: "{{attr}} ต้องน้อยกว่าหรือเท่ากับ {{value}}",
    valid: "จำเป็นต้องกรอก {{type}} ที่ถูกต้อง",
    required: "จำเป็นต้องกรอก {{attr}}",
    irregular: {
      "too many files": "จำนวนไฟล์มากเกินไป",
    },
  },
};

export default translation;

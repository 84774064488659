/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React, { useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";

const TabLink = (props) => {
  const [active, setActive] = useState(false);

  const handleActive = (match) => {
    if (match) {
      setActive(true);
    } else {
      setActive(false);
    }
    return match;
  };

  return (
    <li className={active ? "active" : ""}>
      <NavLink isActive={handleActive} {...props} />
    </li>
  );
};

export default TabLink;

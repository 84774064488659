/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";

import Modal, { ModalFooter, ModalHeader } from "../../layout/Modal";
import { Tabs, Tab } from "react-bootstrap";
import DespatchAdviceEditTab from "./DespatchAdviceEditTab";
import DespatchAdviceItemsTab from "./DespatchAdviceItemsTab";

export const TABS = {
  HEADER: "header",
  ITEMS: "items",
};

export default function DespatchAdviceEdit({
  desadv,
  onClose,
  tab = TABS.HEADER,
}) {
  const { t } = useTranslation();

  return (
    <Modal>
      <ModalHeader style={{ borderBottom: "5px", padding: "15px" }}>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div>
          {t("despatch_advices.despatch_advice")}:{" "}
          <h4 style={{ display: "inline" }}>
            {!!desadv.number ? desadv.number : t("common.not_available")}
          </h4>
        </div>
      </ModalHeader>
      <Tabs defaultActiveKey={tab} transition={false} id="desadv">
        <Tab
          eventKey={TABS.HEADER}
          title={t("despatch_advices.despatch_advice")}
        >
          {/*<OrderEditTab order={order} onClose={onClose}/>*/}
          <DespatchAdviceEditTab desadv={desadv} onClose={onClose} />
        </Tab>
        {/*<Tab eventKey="addresses" title={t("despatch_advices.addresses")}>
          TODO
        </Tab>*/}
        <Tab
          eventKey={TABS.ITEMS}
          title={`${t("despatch_advices.order_items")}`}
        >
          {/*<OrderEditTab order={order} onClose={onClose}/>*/}
          <DespatchAdviceItemsTab desadv={desadv} onClose={onClose} />
        </Tab>
      </Tabs>
    </Modal>
  );
}

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React, { useState } from "react";
import "./theme/style.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useBodyClass, usePageClass } from "./theme/helper";

import NavTopUnauth from "./layout/NavTopUnauth";
import Footer from "./layout/Footer";
import Content from "./layout/Content";
import PageUnauth from "./layout/PageUnauth";
import OrderDemo from "./features/orders/OrderDemo";
import { DEV_MODE } from "./constants";
import OrderView from "./features/orders/OrderView";
import DespatchAdviceView from "./features/despatch_advices/DespatchAdviceView";
import DespatchAdviceDemo from "./features/despatch_advices/DespatchAdviceDemo";
import Orders from "./features/orders/Orders";
import LoginForm from "./features/auth/LoginForm";
import AppAuth from "./AppAuth";

export default function AppUnauth(props) {
  const isMobile = window.innerWidth <= 768;
  useBodyClass([
    "mailedi",
    "top-navigation",
    isMobile ? "body-small" : "__none__",
  ]);

  const [logo, setLogo] = useState({ href: null, img: null });
  const [print, setPrint] = useState(false);
  const [supplierCorrelationLink, setSupplierCorrelationLink] = useState(null);
  const { t } = useTranslation();

  return (
    <PageUnauth>
      <NavTopUnauth
        logo={logo}
        print={print}
        supplierCorrelationLink={supplierCorrelationLink}
      />
      <Content>
        <Switch>
          <Route exact path="/orders">
            <Orders
              onSetLogo={setLogo}
              onSetPrint={setPrint}
              onSetSupplierCorrelationLink={setSupplierCorrelationLink}
            />
          </Route>
          <Route exact path="/orders/demo">
            <OrderDemo onSetLogo={setLogo} />
          </Route>
          <Route exact path="/despatch_advices/demo">
            <DespatchAdviceDemo onSetLogo={setLogo} />
          </Route>
          <Route path="/orders/:slug">
            <OrderView
              onSetLogo={setLogo}
              onSetPrint={setPrint}
              onSetSupplierCorrelationLink={setSupplierCorrelationLink}
              onNotFound={() => {
                if (!DEV_MODE) {
                  window.location =
                    "https://www.retarus.com/services/webconnect-for-suppliers/";
                }
                return null;
              }}
            />
          </Route>
          <Route path="/despatch_advices/:slug">
            <DespatchAdviceView
              onSetLogo={setLogo}
              onSetPrint={setPrint}
              onNotFound={() => {
                if (!DEV_MODE) {
                  window.location =
                    "https://www.retarus.com/services/webconnect-for-suppliers/";
                }
                return null;
              }}
            />
          </Route>
          <Route path="/auth/login">
            <LoginForm />
          </Route>
          <Route
            component={() => {
              if (!DEV_MODE) {
                window.location =
                  "https://www.retarus.com/services/webconnect-for-suppliers/";
              }
              return null;
            }}
          />
        </Switch>
      </Content>
      <Footer>
        <div className="d-flex">
          <div>
            <a
              href="https://www.retarus.com/services/webconnect-for-suppliers/"
              className="text-dark m-r-sm"
            >
              retarus GmbH &copy; 1992-{new Date().getFullYear()}
            </a>
            <span className="text-danger">|</span>
            <a
              href="https://www.retarus.com/services/webconnect-for-suppliers/"
              className="text-dark m-l-sm m-r-sm"
            >
              WebConnect for Suppliers
            </a>
            <span className="text-danger">|</span>
            <a
              href="https://www.retarus.com/de/impressum"
              className="text-dark m-l-sm m-r-sm"
            >
              {t("common.imprint")}
            </a>
            <span className="text-danger">|</span>
            <a
              href="https://www.retarus.com/data-privacy-policy/"
              className="text-dark m-l-sm m-r-sm"
            >
              {t("common.privacy_policy")}
            </a>
          </div>
          <div className="ml-auto">
            Version {process.env.REACT_APP_GIT_VERSION}
          </div>
        </div>
      </Footer>
    </PageUnauth>
  );
}

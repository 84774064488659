/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import React, { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  confirmDesadv,
  deleteDesadv,
  fetchDesadvBySlug,
  selectDespatchAdviceItems,
} from "./ducks/slice";
import { useTranslation } from "react-i18next";
import Address from "../../components/Address";
import { DEV_MODE } from "../../constants";
import Image from "../../components/Image";
import { formatNumber } from "../../translations/intl";
import { Form, Formik } from "formik";
import Field from "../../components/Field";
import { t_special } from "../../helper";
import DespatchAdviceEdit, { TABS } from "./DespatchAdviceEdit";
import Spinner from "../../components/Spinner";
import * as Yup from "yup";
import DespatchAdviceItemEdit from "./DespatchAdviceItemEdit";
import { deleteDesadvItem } from "./ducks/itemSlices";

const initialValues = {
  remarks: "",
  isDeleteButton: false,
};

const createValidationSchema = (t) =>
  Yup.object().shape({
    remarks: Yup.string()
      .ensure()
      .trim()
      .max(2048, t("errors.lte", { attr: t("common.note"), value: 2048 }))
      .nullable(),
  });

export default function DespatchAdvice() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [showDesadvEditModal, setShowDesadvEditModal] = useState({
    show: false,
    tab: TABS.HEADER,
  });

  const desadv = useSelector((state) => state.despatch_advices.entities[slug]);
  const desadvItems = useSelector(selectDespatchAdviceItems({ slug: slug }));
  const [selectedItem, setSelectedItem] = useState(null);

  //useEffect(() => {
  //  dispatch(fetchBySlug({ slug }));
  //}, []);

  const handleDeleteItem = async (index, item) => {
    await dispatch(deleteDesadvItem({ slug: desadv.slug, item: item }));
    await dispatch(fetchDesadvBySlug({ slug: desadv.slug }));
  };

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    setSubmitting(true);
    if (values.isDeleteButton) {
      const resultAction = await dispatch(deleteDesadv({ slug: desadv.slug }));
      setSubmitting(false);
      if (deleteDesadv.fulfilled.match(resultAction)) {
        const currentPos = desadv.related.findIndex(
          (v) => v.slug === desadv.slug,
        );
        //const nextPos = currentPos === 0 ? desadv.related.length === 1 ? -1 : currentPos :
        //console.log("CURRENT", currentPos)
        if (desadv.related.length === 1) {
          history.push(`/orders/${desadv.order.slug}`);
        } else if (currentPos + 1 < desadv.related.length) {
          history.push(
            `/despatch_advices/${desadv.related[currentPos + 1].slug}`,
          );
        } else {
          history.push(
            `/despatch_advices/${desadv.related[currentPos - 1].slug}`,
          );
        }

        // 0,1,2
        // A
        // A,B
        // A,B,C

        //history.push(`/orders/${desadv.order.slug}`);
      } else {
        if (resultAction.payload.field_errors) {
          setErrors(resultAction.payload.field_errors);
        }
      }
    } else {
      if (!desadv.number && !values.number) {
        alert(
          t("errors.required", {
            attr: `${t("despatch_advices.number")}`,
          }),
        );
        return;
      }

      const resultAction = await dispatch(
        confirmDesadv({
          slug: desadv.slug,
          confirm: true,
          remarks: values.remarks,
        }),
      );
      setSubmitting(false);
      if (confirmDesadv.fulfilled.match(resultAction)) {
      } else {
        if (resultAction.payload.field_errors) {
          setErrors(resultAction.payload.field_errors);
        }
      }
    }
  };

  if (!desadv) {
    return <Spinner />;
  }

  return (
    <Fragment>
      {showDesadvEditModal.show && (
        <DespatchAdviceEdit
          desadv={desadv}
          tab={showDesadvEditModal.tab}
          onClose={() =>
            setShowDesadvEditModal({ show: false, tab: TABS.HEADER })
          }
        />
      )}
      <div className="container">
        <div className="row">
          {!desadv._gui.permission.edit && (
            <div className="col-md-12 m-t-sm text-center">
              <div className="alert alert-danger">
                {t("despatch_advices.permissions.edit.false")}
              </div>
            </div>
          )}

          <div className="col-lg-4 p-0">
            <div className="ibox ">
              <div className="ibox-title border-0">
                <h5>{t("despatch_advices.buyer")}</h5>
              </div>
              <div className="ibox-content border-0">
                <div className="row">
                  <div className="col-sm-12">
                    <Address {...desadv.parties.buyer.address} />
                  </div>
                  {!!desadv.parties.delivery && (
                    <div className="col-sm-12">
                      <hr className="m-n" />
                      <Address
                        role_info={t("despatch_advices.delivery_address")}
                        {...desadv.parties.delivery.address}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 border-left border-right">
            {!!desadv.parties.supplier && (
              <div className="ibox">
                <div className="ibox-title border-0">
                  <h5>{t("despatch_advices.supplier")}</h5>
                </div>
                <div className="ibox-content border-0">
                  <Address {...desadv.parties.supplier.address} />
                </div>
              </div>
            )}
          </div>

          <div className="col-lg-4 p-0">
            <div className="ibox">
              <div className="ibox-title border-0">
                <h5>{t("despatch_advices.despatch_advice")}</h5>
                {desadv._gui.permission.edit && desadv.is_pending && (
                  <button
                    className="btn btn-xs btn-light  m-l-xs pull-right"
                    data-toggle="tooltip"
                    title={t("common.edit")}
                    onClick={() =>
                      setShowDesadvEditModal({ show: true, tab: TABS.HEADER })
                    }
                  >
                    <i className="fa fa-pencil-square-o" />
                  </button>
                )}
              </div>
              <div className="ibox-content border-0">
                <div className="row">
                  <div className="col-sm-12">
                    <table className="table table-borderless table-hover table-order-heading">
                      <tbody>
                        {!!desadv.order_number && (
                          <tr>
                            <td></td>
                            <td>{t("orders.number")}:</td>
                            <td>{desadv.order_number}</td>
                          </tr>
                        )}
                        {!!desadv.order_date && (
                          <tr>
                            <td></td>
                            <td>{t("orders.date")}:</td>
                            <td>{desadv.order_date.date}</td>
                          </tr>
                        )}
                        <tr>
                          <td></td>
                          <td>{t("despatch_advices.delivery_date")}:</td>
                          <td>
                            {!!desadv.delivery_date_start &&
                              desadv.delivery_date_start.date}
                          </td>
                        </tr>
                        {!!desadv.shipment.ship_date_start && (
                          <tr>
                            <td></td>
                            <td>{t("despatch_advices.ship_date")}:</td>
                            <td>{desadv.shipment.ship_date_start.date}</td>
                          </tr>
                        )}
                        {!!desadv.reference && (
                          <tr>
                            <td></td>
                            <td>{t("despatch_advices.reference")}:</td>
                            <td>{desadv.reference}</td>
                          </tr>
                        )}
                        {!!desadv.reference_date && (
                          <tr>
                            <td></td>
                            <td>{t("despatch_advices.reference_date")}:</td>
                            <td>{desadv.reference_date.date}</td>
                          </tr>
                        )}
                        {!!desadv.shipment.number && (
                          <tr>
                            <td></td>
                            <td>{t("despatch_advices.shipmentNumber")}:</td>
                            <td>{desadv.shipment.number}</td>
                          </tr>
                        )}
                        <tr
                          data-toggle="tooltip"
                          title={t("errors.required", {
                            attr: `${t("despatch_advices.number")}`,
                          })}
                        >
                          <td>
                            <span className="text-danger">*</span>
                          </td>
                          <td>{t("despatch_advices.number")}:</td>
                          <td>
                            <p style={{ overflowWrap: "anywhere" }}>
                              {!!desadv.number && (
                                <span className="m-r-sm">{desadv.number}</span>
                              )}
                              {desadv._gui.permission.edit &&
                                desadv.is_pending && (
                                  <button
                                    type={"button"}
                                    onClick={() =>
                                      setShowDesadvEditModal({
                                        show: true,
                                        tab: TABS.HEADER,
                                      })
                                    }
                                    className={`btn btn-xs ${
                                      !!desadv.number
                                        ? "btn-default"
                                        : "btn-danger"
                                    }`}
                                  >
                                    {!!desadv.number ? (
                                      <i className="fa fa-edit"></i>
                                    ) : (
                                      t("common.please_enter")
                                    )}
                                  </button>
                                )}
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div className="">
              <div className="">
                {desadv.header.length > 0 && (
                  <div className="row m-b-sm m-t-sm">
                    <div className="col-md-12">
                      {desadv.header.map((l, i) =>
                        !!l ? <div key={i}>{l}</div> : <br key={i} />,
                      )}
                    </div>
                  </div>
                )}
                <div className="table-responsive">
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th width={1} className="px-0">
                          #
                        </th>
                        <th className="text-nowrap">
                          {t("despatch_advices.item_number")}
                          <br />
                          {!!desadv._gui.item_number && (
                            <small>
                              ({t_special(t, desadv._gui.item_number.source)})
                            </small>
                          )}
                        </th>
                        <th>{t("despatch_advices.description")}</th>
                        <th>{t("despatch_advices.unit")}</th>
                        <th className="text-right">
                          {t("despatch_advices.quantity")}
                        </th>
                        <th className="px-0" width={1}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {desadvItems.map((item, index) => (
                        <Fragment key={item.id}>
                          <tr>
                            <td className="px-0">{index + 1}</td>
                            <td>{item.number}</td>
                            <td>
                              <div>
                                {item.description_short.length > 0 &&
                                  item.description_short.map((l, i) =>
                                    !!l ? <div key={i}>{t(l)}</div> : <br />,
                                  )}
                                {item.description_long.length > 0 &&
                                  item.description_long.map((l, i) =>
                                    !!l ? <div key={i}>{t(l)}</div> : <br />,
                                  )}
                              </div>
                              {!!item.barcode && (
                                <div className="m-t-sm text-center clearfix">
                                  <div className="float-left m-l-sm">
                                    {item.barcode.show_image &&
                                      item.barcode.show_image && (
                                        <Image
                                          height={80}
                                          source={`/api/v1/barcodes?code=${item.barcode.code}&type=${item.barcode.type}`}
                                        />
                                      )}
                                    <br />
                                    <b>
                                      {item.barcode.type.toUpperCase()}
                                    </b>: {item.barcode.code}
                                  </div>
                                </div>
                              )}
                            </td>
                            <td>{t(item.unit)}</td>
                            <td className="text-right text-nowrap">
                              <span>
                                {formatNumber(item.quantity.current, 0)}
                              </span>
                              {selectedItem === index && (
                                <DespatchAdviceItemEdit
                                  desadv={desadv}
                                  item={item}
                                  onClose={() => setSelectedItem(null)}
                                />
                              )}
                            </td>
                            <td className="px-0 w-0">
                              {desadv._gui.permission.edit &&
                                desadv.is_pending && (
                                  <Fragment>
                                    <div className="btn-group m-r-xs">
                                      <button
                                        className="btn btn-xs btn-light"
                                        data-toggle="tooltip"
                                        title={t("common.edit")}
                                        onClick={() => setSelectedItem(index)}
                                      >
                                        <i className="fa fa-pencil" />
                                      </button>
                                      <button
                                        className="btn btn-xs btn-light"
                                        data-toggle="tooltip"
                                        title={t("common.delete")}
                                        onClick={() =>
                                          handleDeleteItem(index, item)
                                        }
                                      >
                                        <i className="fa fa-remove" />
                                      </button>
                                    </div>
                                  </Fragment>
                                )}
                            </td>
                          </tr>
                        </Fragment>
                      ))}
                      <tr>
                        <td colSpan={5}></td>
                        <td className="px-0 w-0">
                          {desadv._gui.permission.edit && desadv.is_pending && (
                            <div className="pull-right m-r-xs">
                              <button
                                onClick={() =>
                                  setShowDesadvEditModal({
                                    show: true,
                                    tab: TABS.ITEMS,
                                  })
                                }
                                className="btn btn-xs btn-light"
                                data-toggle="tooltip"
                                title={t("common.add")}
                              >
                                <i className="fa fa-plus" />
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {desadv.footer.length > 0 && (
                <div className="row m-b-sm m-t-sm">
                  <div className="col-md-12">
                    {desadv.footer.map((l, i) =>
                      !!l ? <div key={i}>{l}</div> : <br />,
                    )}
                  </div>
                </div>
              )}

              {desadv._gui.permission.edit && desadv.is_pending && (
                <Formik
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={initialValues}
                  onSubmit={handleSubmit}
                  validationSchema={createValidationSchema(t)}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    submitForm,
                    setFieldValue,
                    setSubmitting,
                    isSubmitting,
                    isValid,
                    /* and other goodies */
                  }) => (
                    <Form>
                      <div className="m-t-sm">
                        <div className="row">
                          <div className="col-md-12">
                            <Field
                              error={errors.remarks}
                              htmlFor="remarks"
                              as="textarea"
                              placeholder={t("form.message_placeholder")}
                              className={`m-t-md ${
                                errors.remarks ? "is-invalid" : ""
                              }`}
                            />
                          </div>
                          <div className="offset-xl-9 col-xl-3">
                            <div className="btn-group btn-block">
                              <button
                                className="btn btn-primary"
                                type="button"
                                disabled={isSubmitting}
                                onClick={async () => {
                                  await setFieldValue("isDeleteButton", false);
                                  submitForm();
                                }}
                              >
                                <i className="fa fa-check" />{" "}
                                {t("form.confirm")}
                              </button>
                              <button
                                className="btn btn-danger"
                                type="button"
                                disabled={isSubmitting}
                                onClick={async () => {
                                  await setFieldValue("isDeleteButton", true);
                                  submitForm();
                                }}
                              >
                                <i className="fa fa-close" />{" "}
                                {t("common.delete")}
                              </button>
                            </div>

                            {DEV_MODE && (
                              <pre
                                style={{
                                  fontSize: "1rem",
                                  padding: ".25rem .5rem",
                                  overflowX: "scroll",
                                }}
                              >
                                VALUES: {JSON.stringify(values, null, 2)}
                                <br />
                                ERRORS: {JSON.stringify(errors, null, 2)}
                              </pre>
                            )}
                          </div>
                        </div>
                      </div>
                      {!!errors.error && (
                        <div className="alert alert-danger m-t-sm">
                          {t(errors.error.map((o) => t(o.t)))}
                        </div>
                      )}
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

import { Formik, Form, FieldArray, Field, getIn, ErrorMessage } from "formik";
import { formatISO, parseISO, max } from "date-fns";
import { useTranslation } from "react-i18next";

import Datepicker from "../../components/Datepicker";
import { DEV_MODE } from "../../constants";
import { ModalFooter } from "../../layout/Modal";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { partialDelivery } from "./ducks/slice";

import { formatNumber } from "../../translations/intl";

//const createInitialValues = (partial) => ({
//    deliveryAt: parseISO(partial.deliveryAt),
//    quantitiy: partial.quantitiy
//});
const createInitialValues = (partials) => ({
  partials: partials.map((p) => ({
    deliveryAt: parseISO(p.delivery_at.iso),
    deleted: !!p.deleted_at,
    quantity: formatNumber(p.quantity, 0, 20, false).replace(",", "."),
    remarks: p.remarks,
  })),
  //partials: [
  //    {deliveryAt: parseISO("2020-07-15T00:00:00"), remarks: "", quantity: 1},
  //    {deliveryAt: parseISO("2020-07-15T00:00:00"), remarks: "", quantity: 2},
  //    {deliveryAt: parseISO("2020-07-15T00:00:00"), remarks: "", quantity: 3},
  //    {deliveryAt: parseISO("2020-07-15T00:00:00"), remarks: "", quantity: 4}
  //],
  error: null,
});

const createValidationSchema = (item, t) =>
  Yup.object().shape({
    partials: Yup.array().of(
      Yup.object().shape({
        deliveryAt: Yup.date()
          .required(t("errors.required", { attr: t("common.date") }))
          //.min(new Date())
          .typeError(t("errors.valid", { type: t("common.date") })),

        remarks: Yup.string().max(
          512,
          t("errors.lte", { attr: t("common.note"), value: 512 }),
        ),
        quantity: Yup.number()
          .required(t("errors.required", { attr: t("orders.quantity") }))
          .min(0.0)
          .max(
            item.quantity.original,
            t("errors.lte", {
              attr: t("orders.quantity"),
              value: formatNumber(item.quantity.original, 0),
            }),
          )
          .typeError(t("errors.valid", { type: t("common.number") })),
      }),
    ),
  });

const partialSum = (partials) => {
  return partials
    .filter((p) => !p.deleted)
    .map((p) => parseFloat(p.quantity))
    .reduce((a, b) => a + b, 0);
};

function OrderItemPartialsTab({ order, item, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (values, { setErrors }) => {
    const resultAction = await dispatch(
      partialDelivery({
        slug: order.slug,
        item: item,
        partials: values.partials.map((p) => ({
          ...p,
          deliveryAt: formatISO(p.deliveryAt),
        })),
      }),
    );
    if (partialDelivery.fulfilled.match(resultAction)) {
      onClose();
    } else {
      if (resultAction.payload) {
        setErrors({
          error: !!resultAction.payload.t
            ? t(resultAction.payload.t.id, {
                attr: t(resultAction.payload.t.args.attr),
                value: isNaN(resultAction.payload.t.args.value)
                  ? resultAction.payload.t.args.value
                  : formatNumber(resultAction.payload.t.args.value, 0),
              })
            : resultAction.payload.message,
        });
      }
    }
  };

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={createInitialValues(item.partials.current)}
      onSubmit={handleSubmit}
      validationSchema={createValidationSchema(item, t)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        setFieldValue,
        setValues,
        isSubmitting,
      }) => (
        <Form>
          <ModalBody>
            <FieldArray
              name="partials"
              render={(arrayHelpers) => (
                <div className="container">
                  <div className="row">
                    <div className="p-1 col-md-1 text-right">
                      <button
                        className="btn"
                        style={{ paddingTop: "0", paddingBottom: "0" }}
                        type={"button"}
                        data-toggle="tooltip"
                        title={t("common.reload")}
                        onClick={() =>
                          setValues(
                            createInitialValues(item.partials.original),
                            false,
                          )
                        }
                      >
                        <i className="fa fa-refresh text-warning" />
                      </button>
                    </div>
                    <div className="p-1 col-md-1 text-right">Pos</div>
                    <div className="p-1 col-md-2 text-left">
                      {t("common.date")}
                    </div>
                    <div className="p-1 col-md text-left">
                      {t("common.note")}
                    </div>
                    <div className="p-1 col-md-3 text-left">
                      {t("orders.quantity")}
                    </div>
                  </div>
                  {values.partials.map((partial, index) => (
                    <div className="row" key={index}>
                      <div className="p-1 col-md-1">
                        <button
                          title={
                            values.partials[index].deleted
                              ? t("common.restore")
                              : t("common.delete")
                          }
                          data-toggle="tooltip"
                          className="btn"
                          type={"button"}
                          //onClick={() => arrayHelpers.remove(index)}
                          onClick={() =>
                            index < item.partials.original.length
                              ? setFieldValue(
                                  `partials.${index}.deleted`,
                                  !values.partials[index].deleted,
                                )
                              : arrayHelpers.remove(index)
                          }
                        >
                          {values.partials[index].deleted ? (
                            <i className="fa fa-plus text-navy" />
                          ) : index < item.partials.original.length ? (
                            <i className="fa fa-remove text-danger" />
                          ) : (
                            <i className="fa fa-trash text-danger" />
                          )}
                        </button>
                      </div>
                      <div
                        className="p-1 col-md-1"
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                      >
                        {item.line}.{index + 1}
                      </div>
                      <div className="p-1 col-md-2">
                        <Datepicker
                          htmlFor={`partials.${index}.deliveryAt`}
                          selected={values.partials[index].deliveryAt}
                          disabled={values.partials[index].deleted}
                          minDate={new Date()}
                          //showWeekNumbers
                          dateFormat="yyyy-MM-dd"
                          //locale="de-DE"
                          onChange={(date) =>
                            setFieldValue(`partials.${index}.deliveryAt`, date)
                          }
                          className={`form-control ${
                            getIn(errors, `partials.${index}.deliveryAt`)
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        {/*<ErrorMessage
                          name={`partials.${index}.deliveryAt`}
                          render={(msg) => (
                            <div className="order-last invalid-feedback">
                              {msg}
                            </div>
                          )}
                        />*/}
                      </div>
                      <div className="p-1 col-md">
                        <Field
                          name={`partials.${index}.remarks`}
                          placeholder={`${t("common.note")}...`}
                          disabled={values.partials[index].deleted}
                          className={`form-control ${
                            getIn(errors, `partials.${index}.remarks`)
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`partials.${index}.remarks`}
                          render={(msg) => (
                            <div className="order-last invalid-feedback text-left">
                              {msg}
                            </div>
                          )}
                        />
                      </div>
                      <div className="p-1 col-md-3">
                        <Field
                          //type="number"
                          as="input"
                          name={`partials.${index}.quantity`}
                          disabled={values.partials[index].deleted}
                          onChange={(e) =>
                            setFieldValue(
                              `partials.${index}.quantity`,
                              e.target.value
                                .replace(",", ".")
                                .replace(/[^\d.]/g, ""),
                            )
                          }
                          className={`form-control ${
                            getIn(errors, `partials.${index}.quantity`)
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`partials.${index}.quantity`}
                          render={(msg) => (
                            <div className="order-last invalid-feedback text-left">
                              {msg}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  ))}
                  <div className="row">
                    <div className="p-1 col-md-1">
                      <button
                        title={t("common.add")}
                        data-toggle="tooltip"
                        className="btn"
                        type={"button"}
                        onClick={() =>
                          arrayHelpers.push({
                            deliveryAt:
                              values.partials.filter((p) => !p.deleted).length >
                              0
                                ? max(
                                    values.partials
                                      .filter((p) => !p.deleted)
                                      .map((p) => p.deliveryAt),
                                  )
                                : !!item.delivery_date.current.start
                                ? parseISO(item.delivery_date.current.start.iso)
                                : !!order.delivery_date.start
                                ? parseISO(order.delivery_date.start.iso)
                                : new Date(),
                            remarks: "",
                            quantity: 0,
                            deleted: false,
                          })
                        }
                      >
                        <i className="fa fa-plus text-navy" />
                      </button>
                    </div>
                    <div
                      className="p-1 col-md-1"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      {item.line}.{values.partials.length + 1}
                    </div>
                    <div className="p-1 col-md-2">
                      <Datepicker
                        value=""
                        disabled={true}
                        className={"form-control"}
                      />
                    </div>
                    <div className="p-1 col-md">
                      <Field
                        value=""
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                    <div className="p-1 col-md-3">
                      <Field
                        value=""
                        disabled={true}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row m-t-sm">
                    <div
                      className="offset-4 col-md-4 text-right"
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      {t("orders.total_quantity")} ({t(item.unit)})
                    </div>
                    <div className="col-md">
                      <div className="bg-muted p-xs">
                        {values.partials.length > 0 && (
                          <span>
                            <span
                              className={
                                partialSum(values.partials) >
                                parseFloat(item.quantity.original)
                                  ? "text-danger"
                                  : undefined
                              }
                            >
                              {formatNumber(partialSum(values.partials), 0)}
                            </span>{" "}
                            of{" "}
                          </span>
                        )}
                        {formatNumber(item.quantity.original, 0)}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
            {DEV_MODE && (
              <pre
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}
            {!!errors.error && (
              <div className="alert alert-danger m-t-sm">{errors.error}</div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="btn-group">
              <button className="btn btn-primary" type={"submit"}>
                <i className="fa fa-save" /> {t("form.save")}
              </button>
              <button className="btn btn-danger" onClick={() => onClose()}>
                <i className="fa fa-close" /> {t("form.cancel")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

export default OrderItemPartialsTab;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import rootReducer from "./reducers";
import { DEV_MODE } from "./constants";

export default function createStore() {
  let middleware = [...getDefaultMiddleware()];

  if (DEV_MODE) {
    middleware = [logger, ...middleware];
  }

  const store = configureStore({
    reducer: rootReducer,
    middleware: middleware,
  });

  if (DEV_MODE && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }
  return { store };
}

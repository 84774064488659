/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import { combineReducers } from "redux";
import { default as navReducer } from "./features/navigation/ducks/slice";
import { default as authReducer } from "./features/auth/ducks/slice";
import { default as orderReducer } from "./features/orders/ducks/slice";
import { default as orderAuthReducer } from "./features/orders/ducks/auth";
import { default as despatchAdviceReducer } from "./features/despatch_advices/ducks/slice";
import { default as despatchAdviceItemReducer } from "./features/despatch_advices/ducks/itemSlices";
import { default as notificationReducer } from "./features/notifications/ducks/slice";
import { default as activitiesReducer } from "./features/activities/ducks/slice";

export default combineReducers({
  nav: navReducer,
  auth: authReducer,
  orders: orderReducer,
  orders_auth: orderAuthReducer,
  despatch_advices: despatchAdviceReducer,
  despatch_advice_items: despatchAdviceItemReducer,
  notifications: notificationReducer,
  activities: activitiesReducer,
});

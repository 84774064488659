/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

export function t_special(t, text) {
  switch (text) {
    case "SUPPLIER_PID":
      return t("orders.supplier");
    case "BUYER_PID":
      return t("orders.buyer");
    case "EAN":
      return t("common.ean");
    default:
      return "Unknown";
  }
}

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from 'react';

const Content = (props) => {
  return (
      <div className="wrapper wrapper-content">
          {props.children}
      </div>
  );
};

export default Content;


import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { ToastContainer } from "react-toastify";

//import "react-toastify/dist/ReactToastify.css";
import "./theme/style.scss";

import { Switch, Route, Redirect } from "react-router-dom";
import { useBodyClass } from "./theme/helper";

import NavLeft from "./layout/NavLeft";
import Footer from "./layout/Footer";
import Content from "./layout/Content";
import Link from "./layout/Link";
import { useTranslation } from "react-i18next";
import Page from "./layout/PageAuth";
import NavTopAuth from "./layout/NavTopAuth";
import logo_normal from "./dashboard_logo.png";
import logo_small from "./dashboard_logo_small.png";
import OrdersAuth from "./features/orders/OrdersAuth";
import Logout from "./features/auth/Logout";

export default function AppAuth() {
  const isMobile = window.innerWidth <= 768;

  const minimized = useSelector((state) => state.nav.menu.left.minimized);

  const logo = minimized ? logo_small : logo_normal;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  //const isMobile = window.innerWidth < 480;
  // const canManageUser = useSelector(hasRole("admin"));

  //const isAdmin = useSelector(hasRole("admin"));
  const isAdmin = true;

  return (
    <>
      <NavLeft logo={logo}>
        <Link
          to="/auth/orders"
          data-toggle="tooltip"
          title={t("orders.menu.orders")}
        >
          <i className="fa fa-home"></i>
          <span className="nav-label"> {t("orders.order")}</span>
        </Link>
        {/*<Link
          to="/auth/settings"
          data-toggle="tooltip"
          title={t("settings.menu.orders")}
        >
          <i className="fa fa-cog"></i>
          <span className="nav-label"> {t("settings.settings")}</span>
        </Link>*/}
      </NavLeft>
      <Page>
        <NavTopAuth />
        <Content>
          <Switch>
            <Route exact path="/auth/orders">
              <OrdersAuth />
            </Route>
            <Route exact path="/auth/logout">
              <Logout />
            </Route>
            <Route exact path="/auth">
              <Redirect to="/auth/orders" />
            </Route>
          </Switch>
        </Content>
        <Footer>
          <div className="d-flex">
            <div>
              <a
                href="https://www.retarus.com/services/webconnect-for-suppliers/"
                className="text-dark m-r-sm"
              >
                retarus GmbH &copy; 1992-{new Date().getFullYear()}
              </a>
              <span className="text-danger">|</span>
              <a
                href="https://www.retarus.com/services/webconnect-for-suppliers/"
                className="text-dark m-l-sm m-r-sm"
              >
                WebConnect for Suppliers
              </a>
              <span className="text-danger">|</span>
              <a
                href="https://www.retarus.com/de/impressum"
                className="text-dark m-l-sm m-r-sm"
              >
                {t("common.imprint")}
              </a>
              <span className="text-danger">|</span>
              <a
                href="https://www.retarus.com/data-privacy-policy/"
                className="text-dark m-l-sm m-r-sm"
              >
                {t("common.privacy_policy")}
              </a>
            </div>
            <div className="ml-auto">
              Version {process.env.REACT_APP_GIT_VERSION}
            </div>
          </div>
        </Footer>
      </Page>
    </>
  );
}

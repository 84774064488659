/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

const Footer = (props) => {
    return (
        <div className="footer">
            {props.children}
        </div>
    )
}

export default Footer;
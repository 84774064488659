const translation = {
  attributes: {
    attribute: "Attribut",
    attributes: "Attributs",
    label: "Libellé",
    value: "Valeur",
    type: "Type",
    types: {
      text: "Texte",
      number: "Numéro",
      date: "Date",
      time: "Heure",
      select: "Sélectionner",
      errors: {
        number: "La valeur doit être un nombre valide",
        date: "La valeur doit être une date valide",
        time: "La valeur doit être une heure valide",
      },
    },
  },
  activities: {
    activity: "Activités",
    order: {
      link: {
        opened: "Lien pour la commande n° {{number}} ouvert",
      },
      accepted: "La commande n° {{number}} a été acceptée",
      rejected: "La commande n° {{number}} a été rejetée",
      changed: "La commande n° {{number}} a été modifiée",
      field: {
        changed:
          "$t({{field_t_key}}) est passé de $t({{before}}, '') $t({{suffix}}, '') à $t({{after}}, '') $t({{suffix}}, '')",
      },
      replaced:
        "La commande numéro {{number}} a été remplacée par l'acheteur. Nouvelle version disponible sous le lien suivant<a class='btn btn-xs btn-light' alt='link' href='{{host.url}}ui/orders/{{descendant_slug}}'><i class='fa fa-link'></i> Link</a>",
      replaces:
        "La commande n° {{number}} a été remplacée par l'acheteur. Version précédente disponible sous le lien suivant<a class='btn btn-xs btn-light' alt='link' href='{{host.url}}ui/orders/{{ancestor_slug}}'><i class='fa fa-link'></i> Link</a>",
      canceled: "La commande n° {{number}} a été annulée par l'acheteur",
      upload: "{{filename}} a été envoyé",
      upload_delete: "{{filename}} a été supprimé",
      allowances_and_charges: {
        added:
          "{{value}} {{suffix}} $t({{aoc_t_key}}) avec $t({{seq_t_key}}) n° {{seq}} et $t(common.note) $t({{note}}, 'common.empty')",
      },
      item: {
        changed:
          "L'article n° {{number}} dans la position {{line}} a été modifié",
        field: {
          changed:
            "$t({{field_t_key}}) est passé de $t({{before}}, '') $t({{suffix}}, '') à $t({{after}}, '') $t({{suffix}}, '')",
        },

        partial: {
          added:
            "Livraison partielle modifiée à $t({{delivery_at}}, '') dans la position $t({{pos}}, '') $t(common.note) $t({{note}}, 'common.empty') $t({{quantity}}, '') $t({{suffix}}, '')",
        },
        allowances_and_charges: {
          added:
            "{{value}} {{suffix}} $t({{aoc_t_key}}) avec $t({{seq_t_key}}) n° {{seq}} appliqué à $t({{apply_t_key}}) avec $t(common.note) $t({{note}}, 'common.empty')",
        },
        attributes: {
          added: "L'attribut {{label}} ayant la valeur {{value}} a été ajouté",
          changed:
            "L'attribut {{label}} ayant la valeur {{value}} a été modifié",
          deleted:
            "L'attribut {{label}} ayant la valeur {{value}} a été supprimé",
        },
      },
      notification: {
        resend:
          "La notification de commande a été renvoyée au destinataire {{recipient}} ",
      },
    },
  },
  despatch_advices: {
    shipmentNumber: "ID d'expédition",
    shipmentUrl: "URL de suivi",
    delivery_address: "Livraison",
    order_items: "Commander des articles",
    addresses: "Adresses",
    delete_despatch_advice: "Supprimer les bordereaux d'expédition ?",
    despatch_advices: "Bordereaux d'expédition",
    despatch_advice: "Bordereau d'expédition",
    number: "Numéro ASN",
    delivery_date: "Date de livraison",
    ship_date: "Date d'expédition",
    supplier: "Fournisseur",
    buyer: "Acheteur",
    description: "Description",
    item_number: "N° d'article",
    unit: "Unité",
    quantity: "Quantité",
    create_by_items: "Créer par Articles",
    create_by_delivery_date: "Créer par Date de livraison",
    create_empty: "Créer Vide",
    item: "Article",
    items: "Articles",
    create_new: "Nouveau",
    reference: "Référence",
    reference_date: "Date de référence",
    help: {
      1: "Articles restants à livrer",
      2: "Articles confirmés pour la livraison",
      3: "Quantité pour ce bordereau d'expédition",
    },
    permissions: {
      edit: {
        false: "La modification de ce bordereau d'expédition est bloquée",
      },
    },
  },
  orders: {
    orders: "Commandes",
    orders_overview: "Vue d'ensemble des commandes",
    overview: {
      responses: "Confirmations",
      tab: {
        info: "Le champ de recherche vous permet de rechercher en fonction du numéro de commande ou du numéro de commande du fournisseur. Les actions vous permettent d'afficher, de transférer ou de renvoyer la commande.",
        info_auth:
          "Le champ de recherche vous permet d'effectuer une recherche en fonction du numéro de commande, du numéro de commande du fournisseur ou du nom du fournisseur. Les actions vous permettent d'afficher, de transmettre ou de renvoyer la commande.",
      },
      menu: {
        overview: "Vue d'ensemble des commandes",
        all: "Toutes",
        due: "Expirée",
        pending: "En attente",
        state: "Statut",
        rejected: "Refusée",
        accepted: "Acceptée",
        replaced: "Remplacée",
        canceled: "Annulée",
        open_order: "Afficher la commande",
        resent_notification: "Renvoyer la notification de commande",
        supplier_recipients: "Destinataire",
      },
      resend_notification: "Notification de commande",
    },
    permissions: {
      edit: {
        false: "La modification de cette commande est bloquée",
      },
    },
    changes: {
      replaced: {
        current:
          "Cette commande a été modifiée par l'acheteur. <br/>La commande d'origine est toujours disponible en lecture seule depuis le menu Activités.",
        ancestor:
          "Cette commande a été remplacée par une nouvelle commande par l'acheteur.",
      },
      canceled: "Cette commande a été annulée par l'acheteur.",
    },
    items: "Commander des articles",
    order: "Commande",
    number: "N° de commande",
    date: "Date de la commande",
    supplier: "Fournisseur",
    supplier_order_number: "N° de commande du fournisseur",
    buyer: "Acheteur",
    invoice_address: "Facture",
    delivery_address: "Livraison",
    description: "Description",
    item_number: "N° d'article",
    unit: "Unité",
    quantity: "Quantité",
    sum: "Somme",
    total: "Total",
    net: "Net",
    gross: "Brut",
    price: "Prix",
    price_unit: "Prix unité",
    total_quantity: "Quantité totale",

    partial_deliveries: "Livraison partielle",
    partial_delivery_at: "Livraison partielle",
    partial_delivery_title:
      "Livraison partielle pour l'article à la ligne {{line}}, présentant le numéro",
    item_edit_modal_header_title:
      "Article à la ligne {{line}} présentant le numéro",
    delivery_date: "Date de livraison",

    confirm_order: "Êtes-vous sûr de vouloir confirmer la livraison ?",
    reject_order: "Êtes-vous sûr de vouloir refuser la livraison ?",
    response: "Réponse",

    change_delivery_date:
      "Changer la date de livraison d'origine {{original_start_date}}",
    change_delivery_date_hover: "Changer la date de livraison",

    please_enter_supplier_order_number:
      "Veuillez saisir le numéro de commande du fournisseur",

    order_response_created:
      "Votre confirmation de commande a été créée avec succès",

    notifications: {
      title: "Notifications",
      type: "Type",
      address: "Adresse",
      subject: "Objet",
      status: "Statut",
      sent: "Envoyé",
      delivered: "Livré",
      description: "Description",
      info: "Confirmer ou modifier le destinataire de la notification de commande.",
      resend: "Souhaitez-vous vraiment renvoyer la notification de commande ?",
      resend_alert:
        "Une nouvelle notification de commande {{number}} pour la commande a été envoyée avec succès.",
    },

    demo: {
      title:
        "Vous êtes intéressé par une solution compacte pour vos processus de commande ?",
      description:
        "À l'aide d'un exemple de commande, le portail WebConnect montre à quel point il est simple d'utiliser le service d'approvisionnement électronique et de gérer vos commandes au sein de son interface cohérente. Vos fournisseurs ont la possibilité d'afficher les produits commandés en toute simplicité, de laisser des messages, de confirmer/refuser chaque commande, et même d'apporter des modifications à des positions individuelles.\n" +
        "Prenez un moment pour découvrir par vous-même combien le portail WebConnect est facile et pratique : le service est rapide, automatisé, extrêmement simple d'utilisation et ne requiert aucune connexion.",

      form: {
        footer: "Username: demo, Password: demo",
        title:
          "Saisissez votre adresse e-mail* pour accéder à la démonstration live de Retarus WebConnect for Suppliers :",
        email: "Votre adresse e-mail",
      },

      ol: {
        title:
          "Dans le cadre de la démonstration, trois e-mails seront envoyés à votre adresse e-mail : ",
        1: "Un exemple d'e-mail de commande, illustrant comment vos fournisseurs reçoivent les commandes passées.",
        2: "Un message de confirmation, tel que reçu par vos fournisseurs une fois qu'ils ont modifié ou confirmé la commande dans le portail WebConnect.",
        3: "Un exemple de message illustrant le suivi de commandes ouvertes au sein d'une période définie. Dans un contexte réel d'utilisation du service, vos employés du service d'achat reçoivent ces e-mails.",
        footer:
          "Nous vous souhaitons beaucoup de succès avec WebConnect for Suppliers !",
      },

      footer: {
        title:
          "En cas de questions supplémentaires autour de WebConnect for Suppliers, n'hésitez pas à <0>nous contacter</0> à tout moment.",
      },

      quote: {
        1: "*Nous vous assurons que nous n'utilisons votre adresse e-mail qu'à des fins de démonstration du service WebConnect for Suppliers de Retarus et qu'elle ne sera en aucun cas enregistrée ou utilisée à des fins de marketing.",
      },

      items: {
        1: {
          unit: "Pièce",
          description:
            "Courroie haute performance SUPER XE-POWER PRO XPA 1180 Lw (1135 Li / 1198 La) à flancs bruts, crantée",
        },
        2: {
          unit: "Pièce",
          description: "Connecteur à charnière courroies finales Z/10",
        },
        3: {
          unit: "Pièce",
          description:
            "Courroies classiques DIN2215 / ISO4184 A55 13 x 1400 Li (1430 Lw / 1450 La) manchonnées ; rapport de transmission constant (S=C PLUS)",
        },
        4: {
          unit: "Pièce",
          description: "Courroies finales DIN2216, perforée A/13",
        },
      },
    },

    uploads: {
      click_me: "Cliquez ici pour envoyer les fichiers",
      delete_btn: "Supprimer les fichiers envoyés",
    },

    vat: "TVA",
    vat_id: "Numéro de TVA",

    item: "Article",

    allowances_and_charges: "Allocations et charges",
    amount: "Montant",
  },
  form: {
    message_placeholder: "Laissez un message...",
    confirm: "Confirmer",
    confirmed: "Confirmé",
    reject: "Refuser",
    rejected: "Refusé",
    save: "Enregistrer",
    cancel: "Annuler",
    send: "Envoyer",

    placeholder: {
      email: "Votre adresse e-mail",
    },
  },

  common: {
    demo: "Demo",
    from: "De",
    to: "à",

    overview: "Vue d'ensemble",
    actions: "Actions",
    modified: "Modifié",
    reload: "Recharger",
    restore: "Restaurer",
    advised: "Averti",
    current: "Actuel",
    ordered: "Commandé",
    confirmed: "Confirmé",
    delivered: "Livré",
    not_available: "n/a",
    add: "Ajouter",
    search: "Rechercher",
    committed: "Engagé",
    remaining: "Restant",
    delete: "Supprimer",
    completed: "Terminé",
    change_lang: "Changer de langue",
    date: "Date",
    note: "Remarque",
    attachments: "Téléchargements",
    uploads: "Envois",
    at: "à",
    print: "Imprimer",
    imprint: "Imprimé",
    privacy_policy: "Politique de confidentialité",
    number: "Numéro",
    additional: "Supplémentaire",
    original: "Original",
    value: "Valeur",
    empty: "Vide",
    allowance: "Allocation",
    charge: "Charge",
    subtotal: "Sous-total",

    sequence: "Séquence",
    description: "Description",
    apply: "Appliquer",
    type: "Type",
    per: "par",
    discount: "Remise",
    freight_charge: "Frais de transport",
    edit: "Modifier",
    please_enter: "Merci de saisir",
    ean: "EAN",
    draft: "Brouillon",
    number_of_decimal_places: "Nombre de décimales",
    submit: "Soumettre",
  },

  errors: {
    not_found: "Ressource introuvable",
    token_expired:
      "Le lien n'est plus valide. Accéder de nouveau à la vue d'ensemble des commandes via une commande individuelle.",
    rate_limit_reached: "Limite de taux dépassée",
    email: "{{attr}} doit correspondre à une adresse e-mail valide",
    url: "{{attr}} doit correspondre à une URL valide",
    uuid: "{{attr}} doit correspondre à un UUID valide",
    lowercase: "{{attr}} doit être écrit en minuscules",
    uppercase: "{{attr}} doit être écrit en majuscules",
    integer: "{{attr}} doit être un nombre entier",
    positive: "{{attr}} doit être positif",
    negative: "{{attr}} doit être négatif",
    matches: "{{attr}} doit correspondre au schéma {{value}}",
    length: "{{attr}} doit contenir {{value}} caractères",
    eq: "{{attr}} doit être égal à {{value}}",
    gt: "{{attr}} doit être supérieur à {{value}}",
    gte: "{{attr}} doit être supérieur ou égal à {{value}}",
    lte: "{{attr}} doit être inférieur ou égal à {{value}}",
    valid: "{{type}} valide requis",
    required: "{{attr}} est requis",
    irregular: {
      "too many files": "Trop de fichiers",
    },
  },
};

export default translation;

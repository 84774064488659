import React from "react";
import { useSelector } from "react-redux";

//import logo from "../logo.png";
import { DEV_MODE } from "../constants";

const NavLeft = ({ logo, children }) => {
  return (
    <nav className="navbar-default navbar-static-side" role="navigation">
      <div className="sidebar-collapse">
        <ul className="nav metismenu" id="side-menu">
          <li
            className="nav-header"
            style={{ backgroundColor: DEV_MODE ? "#4a4c50" : "#4a4c50" }}
          >
            <div className="dropdown profile-element">
              <a
                data-toggle="dropdown"
                className="dropdown-toggle"
                href="javascript:void(0)"
              >
                {/*<span className="block m-t-xs font-bold">{me.full_name}</span>*/}
                <img width={150} src={logo} l />

                {false && (
                  <span className="text-muted text-xs block">
                    menu <b className="caret"></b>
                  </span>
                )}
              </a>
              {false && (
                <ul className="dropdown-menu animated fadeInRight m-t-xs">
                  <li>
                    <a className="dropdown-item" href="login.html">
                      Logout
                    </a>
                  </li>
                </ul>
              )}
            </div>
            <div className="logo-element">
              <img width={24} height={24} src={logo} />
            </div>
          </li>
          {children}
        </ul>
      </div>
    </nav>
  );
};

export default NavLeft;

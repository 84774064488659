import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

const Spinner = (props) => {
  const { timeout } = props;
  const [show, setShow] = useState(timeout > 0 ? false : true);

  useEffect(() => {
    const ticker = setTimeout(() => setShow(true), timeout);
    return () => clearTimeout(ticker);
  }, []);

  const circle = (
    <div className="sk-spinner sk-spinner-circle">
      <div className="sk-circle1 sk-circle"></div>
      <div className="sk-circle2 sk-circle"></div>
      <div className="sk-circle3 sk-circle"></div>
      <div className="sk-circle4 sk-circle"></div>
      <div className="sk-circle5 sk-circle"></div>
      <div className="sk-circle6 sk-circle"></div>
      <div className="sk-circle7 sk-circle"></div>
      <div className="sk-circle8 sk-circle"></div>
      <div className="sk-circle9 sk-circle"></div>
      <div className="sk-circle10 sk-circle"></div>
      <div className="sk-circle11 sk-circle"></div>
      <div className="sk-circle12 sk-circle"></div>
    </div>
  );

  return <>{show ? circle : <span />}</>;
};

Spinner.defaultProps = {
  timeout: 0,
};

Spinner.propTypes = {
  timeout: PropTypes.number,
};

export default Spinner;

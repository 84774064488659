/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";
import PropTypes from "prop-types"
import {Field as _Field, ErrorMessage} from "formik"

function Field(props) {

    const {htmlFor, error, label, placeholder, type, disabled=false, as="input", className=""} = props;

    return (
        <div className={"form-group" + (error ? " has-error " : "")}>
            {label ? <label htmlFor={htmlFor}>{label}</label> : null}
            <_Field
                as={as}
                name={htmlFor}
                className={`form-control ${className}`}
                type={type}
                placeholder={placeholder}
                disabled={disabled}
            />

            <ErrorMessage
                name={htmlFor}
                //render={e => {e instanceof Array ? e.map(msg => <label className="error">{msg}</label>) : <label className="error">{e}</label>}}
                //render={e => e instanceof Array ? <ul>{e.map(m => <li><label key={m} className="error">{m}</label></li>)}</ul> : <label className="error">{e}</label>}
                render={msg => <label className="error">{msg}</label>}
            />
        </div>
    )

}

Field.defaultProps={
    type: "text",
    placeholder: "",
    label: "",
};

Field.props = {
    htmlFor: PropTypes.string.isRequired,
    error: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    type: PropTypes.string.isRequired,
}

export default Field
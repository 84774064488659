import React from "react";

import { Formik, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { Link, Redirect, useLocation } from "react-router-dom";

import * as Yup from "yup";

import Field from "../../components/Field";

import { useBodyClass } from "../../theme/helper";

const initialValues = {
  email: "",
  password: "",
  otp: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
  password: Yup.string().required(),
  otp: Yup.string().optional(),
});

export default function LoginForm(props) {
  useBodyClass("gray-bg");

  const dispatch = useDispatch();
  //const me = useSelector((state) => state.auth.me);
  //const meIsLoading = useSelector((state) => state.auth.isLoading);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const next = searchParams.get("next");

  const handleSubmit = async (values, { setErrors }) => {
  //  const resultAction = await dispatch(login(values));
  //  if (login.fulfilled.match(resultAction)) {
  //    const user = unwrapResult(resultAction);
  //    //if (user.is_expired) {
  //    //  //history.push(`/users/${user.id}/change_password`);
  //    //  toast.success(
  //    //    <span>
  //    //      Your password is expired. Please consider to{" "}
  //    //      <Link to={`/me/change_password`}>change it.</Link>
  //    //    </span>,
  //    //  );
  //    //}
  //    //history.push(`/dashboard`);
  //  } else {
  //    if (resultAction.payload) {
  //      setErrors(resultAction.payload.field_errors);
  //    }
  //  }
  };

  //if (!!me) {
  //  return <Redirect to={!!next ? next : "/"} />;
  //}

  return (
    <div className="d-flex align-items-center w-100 min-vh-100">
      <div className="m-auto">
        <div className="row bg-white mx-0" style={{ maxWidth: "800px" }}>
          <div className="col-md-6 p-sm d-none d-sm-block">
            <h2 className="font-bold">
              WebConnect
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
            </p>

            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
            </p>
          </div>
          <div className="col-xs-12 col-md-6 p-sm">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              validateOnMount={false}
              onSubmit={handleSubmit}
              render={({ errors }) => (
                <Form>
                  {/*We use error.otp because we want to not showe the reason for error */}
                  <Field
                    error={errors.otp}
                    htmlFor="email"
                    placeholder="Email"
                  />
                  <Field
                      error={errors.otp}
                      htmlFor="password"
                      placeholder="Password"
                      type="password"
                  />
                  {/*<Field error={errors.otp} htmlFor="otp" placeholder="Token"/>*/}
                  <button
                    className="btn btn-primary block full-width m-b"
                    type="submit"
                  >
                    <i className="fa fa-sign-in" /> Login
                  </button>
                  <p className="text-muted text-center">
                    <small>
                      Did you forget your password or do you like an account?
                    </small>
                  </p>
                  <div className="btn-group btn-block">
                    <a
                      href="/ui/auth/forgot_password"
                      className="btn btn-sm btn-white"
                    >
                      Forgot password
                    </a>
                    <a
                      href="https://webconnect.retarus.com"
                      className="btn btn-sm btn-white"
                    >
                      Get an account
                    </a>
                  </div>
                </Form>
              )}
            />
          </div>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <div>
            <small className="text-danger">retarus GmbH</small>
          </div>
          <div>
            <small>
              Lorem Ipsum
            </small>
          </div>
          <div>
            <small>&copy; 1992 - {new Date().getFullYear()}</small>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useBodyClass } from "../theme/helper";

export default function Modal({ children, size = "xl" }) {
  useEffect(() => {
    const div = document.createElement("div");
    div.setAttribute("class", "modal-backdrop show");
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, []);

  useBodyClass(["modal-open"]);

  return (
    <div className="modal inmodal m-t-lg" style={{ display: "block" }}>
      <div className={`modal-dialog modal-${size}`}>
        <div className="modal-content">{children}</div>
      </div>
    </div>
  );
}

export function ModalHeader(props) {
  return (
    <div className="modal-header" {...props}>
      {props.children}
    </div>
  );
}

export function ModalBody(props) {
  return (
    <div className="modal-body" {...props}>
      {props.children}
    </div>
  );
}
export function ModalFooter(props) {
  return (
    <div className="modal-footer" {...props}>
      {props.children}
    </div>
  );
}

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { formatISO, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import Datepicker from "../../components/Datepicker";
import { DEV_MODE } from "../../constants";
import { ModalFooter } from "../../layout/Modal";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changeOrder, resendNotification } from "./ducks/slice";

const createInitialValues = (order) => ({
  email: order.supplier_mail_to_recipients[0],
  error: null,
});

const createValidateSchema = (order, t) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t("errors.email", { attr: t("attributes.value") }))
      .required(
        t("errors.required", {
          attr: t("orders.supplier_order_number"),
        }),
      ),
  });

function OrderNotificationResendTab({ order, onClose }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const handleSubmit = async (values, { setErrors }) => {
    const resultAction = await dispatch(
      resendNotification({
        slug: order.slug,
        email: values.email,
      }),
    );
    if (resendNotification.fulfilled.match(resultAction)) {
      onClose();
    } else {
      if (resultAction.payload) {
        setErrors(resultAction.payload.field_errors);
      }
    }
  };

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={createInitialValues(order)}
      onSubmit={handleSubmit}
      validationSchema={createValidateSchema(order, t)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form>
          <ModalBody>
            <div className="container">
              {i18n.exists("orders.notifications.info") &&
                !t("orders.notifications.info").startsWith("TODO") && (
                  <div className="row">
                    <div className="col mb-1">
                      <div className="alert alert-warning">
                        {t("orders.notifications.info")}
                      </div>
                    </div>
                  </div>
                )}
              <div className="row">
                <div className="col mb-3">
                  <div
                    className={"input-group " + (errors.email && "has-error")}
                  >
                    <Field
                      name="email"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="email"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">
                          {t(msg)}
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            {DEV_MODE && (
              <pre
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}

            {!!errors.error && (
              <div className="alert alert-danger m-t-sm">{errors.error}</div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="btn-group">
              <button className="btn btn-primary" type={"submit"}>
                <i className="fa fa-envelope" /> {t("form.send")}
              </button>
              <button className="btn btn-danger" onClick={() => onClose()}>
                <i className="fa fa-close" /> {t("form.cancel")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

export default OrderNotificationResendTab;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import React from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { formatISO, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import Datepicker from "../../components/Datepicker";
import { DEV_MODE } from "../../constants";
import { ModalFooter } from "../../layout/Modal";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { changeOrder } from "./ducks/slice";

const createInitialValues = (order) => ({
  deliveryDateStart: !!order.delivery_date.start
    ? parseISO(order.delivery_date.start.iso)
    : "",
  deliveryDateEnd: !!order.delivery_date.end
    ? parseISO(order.delivery_date.end.iso)
    : "",
  supplierOrderNumber: !!order.supplier_order_number
    ? order.supplier_order_number
    : "",
  error: null,
});

const createValidateSchema = (order, t) =>
  Yup.object().shape({
    supplierOrderNumber: order.supplier_order_number_is_mandatory
      ? Yup.string()
          .trim()
          .max(
            64,
            t("errors.lte", {
              attr: t("orders.supplier_order_number"),
              value: 64,
            }),
          )
          .required(
            t("errors.required", {
              attr: t("orders.supplier_order_number"),
            }),
          )
      : Yup.string()
          .trim()
          .max(
            64,
            t("errors.lte", {
              attr: t("orders.supplier_order_number"),
              value: 64,
            }),
          ),
    //deliveryDateStart: Yup.date()
    //.required(t("errors.required", { attr: t("common.date") }))
    //.min(new Date())
    //.typeError(t("errors.valid", { type: t("common.date") })),
  });

function OrderEditTab({ order, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (values, { setErrors }) => {
    const resultAction = await dispatch(
      changeOrder({
        slug: order.slug,
        deliveryStartDate: !!values.deliveryDateStart
          ? formatISO(values.deliveryDateStart, {
              representation: "date",
            })
          : "",
        supplierOrderNumber: values.supplierOrderNumber,
      }),
    );
    if (changeOrder.fulfilled.match(resultAction)) {
      onClose();
    } else {
      if (resultAction.payload) {
        setErrors({ error: resultAction.payload.error });
      }
    }
  };

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={createInitialValues(order)}
      onSubmit={handleSubmit}
      validationSchema={createValidateSchema(order, t)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form>
          <ModalBody>
            <div className="container">
              <div className="row mb-2">
                <div className="offset-8 offset-sm-9 col text-left mt-auto">
                  {!!order.delivery_date.original.start &&
                    !!order.delivery_date.start.date &&
                    order.delivery_date.original.start.date !==
                      order.delivery_date.start.date && (
                      <i>
                        {t("common.original")} {t("common.value")}
                      </i>
                    )}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("orders.delivery_date")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <Datepicker
                    htmlFor="deliveryDateStart"
                    selected={values.deliveryDateStart}
                    disabled={!order.delivery_date.change_allowed}
                    //showWeekNumbers
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    onChange={(date) =>
                      setFieldValue("deliveryDateStart", date)
                    }
                    className={`form-control ${
                      !!errors.deliveryDateStart ? "is-invalid" : ""
                    }`}
                  />
                </div>
                <div className="col my-auto text-left">
                  {(!!order.delivery_date.original.start
                    ? order.delivery_date.original.start.date
                    : null) !==
                    (!!order.delivery_date.start
                      ? order.delivery_date.start.date
                      : null) && (
                    <i>
                      {!!order.delivery_date.original.start ? (
                        order.delivery_date.original.start.date
                      ) : (
                        <i>({t("common.empty")})</i>
                      )}
                    </i>
                  )}
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {order.supplier_order_number_is_mandatory && (
                    <span className="text-danger">*</span>
                  )}{" "}
                  {t("orders.supplier_order_number")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={
                      "input-group " +
                      (errors.supplierOrderNumber && "has-error")
                    }
                  >
                    <Field
                      name="supplierOrderNumber"
                      className={`form-control ${
                        errors.supplierOrderNumber ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="supplierOrderNumber"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
                <div className="col my-auto text-left">
                  {!!order.supplierOrderNumber && (
                    <i>
                      {!!order.supplierOrderNumber ? (
                        <i>({t("common.empty")})</i>
                      ) : (
                        ""
                      )}
                    </i>
                  )}
                </div>
              </div>

              {false && (
                <div className="row justify-content-center">
                  <div className="col-sm-6">
                    <Datepicker
                      htmlFor="deliveryDateStart"
                      selected={values.deliveryDateStart}
                      //showWeekNumbers
                      dateFormat="yyyy-MM-dd"
                      minDate={new Date()}
                      onChange={(date) =>
                        setFieldValue("deliveryDateStart", date)
                      }
                      //className={"form-control " + !!errors.deliveryDateStart ? "is-invalid" : ""}
                      className={`form-control ${
                        !!errors.deliveryDateStart ? "is-invalid" : ""
                      }`}
                    />
                    {/*<ErrorMessage
                          name={`deliveryDateStart`}
                          render={(msg) => (
                            <div className="order-last invalid-feedback">
                              {msg}
                            </div>
                          )}
                        />*/}
                  </div>
                  {false && (
                    <div className="col-sm-6">
                      <Datepicker
                        htmlFor="deliveryDateEnd"
                        selected={values.deliveryDateEnd}
                        onChange={(date) =>
                          setFieldValue("deliveryDateEnd", date)
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {DEV_MODE && (
              <pre
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}

            {!!errors.error && (
              <div className="alert alert-danger m-t-sm">{errors.error}</div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="btn-group">
              <button className="btn btn-primary" type={"submit"}>
                <i className="fa fa-save" /> {t("form.save")}
              </button>
              <button className="btn btn-danger" onClick={() => onClose()}>
                <i className="fa fa-close" /> {t("form.cancel")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

export default OrderEditTab;

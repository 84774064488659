/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React, { useState, Fragment } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import * as Yup from "yup";
import { Formik, Form } from "formik";

import { useTranslation } from "react-i18next";

import { confirm, deleteUpload } from "./ducks/slice";
import { DEV_MODE, FEATURES } from "../../constants";
import Field from "../../components/Field";

import { formatNumber } from "../../translations/intl";
import Upload from "./OrderUpload";
import Image from "../../components/Image";
import OrderItemEdit from "./OrderItemEdit";
import OrderEdit from "./OrderEdit";
import Address from "../../components/Address";
import { t_special } from "../../helper";

function Partial({ item, partial, index }) {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-md-4">
        {item.line}.{index}
      </div>
      <div className="col-md-6">
        {t("orders.partial_delivery_at")}: {partial.delivery_at.date}
        {!!partial.remarks && (
          <div className=" m-b-sm">
            <small>
              <i>{t("common.note")}:</i> {partial.remarks}
            </small>
          </div>
        )}
      </div>
      <div className="col-md-2 text-right text-nowrap">
        <b>
          {formatNumber(partial.quantity, 0)} {t(item.unit)}
        </b>
      </div>
    </div>
  );
}

const initialValues = {
  accept: null,
  remarks: "",
};

const createValidationSchema = (t) =>
  Yup.object().shape({
    accept: Yup.boolean().required(),
    remarks: Yup.string()
      .ensure()
      .trim()
      .max(2048, t("errors.lte", { attr: t("common.note"), value: 2048 }))
      .nullable(),
  });

export default function Order() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(null);
  const [showOrderEditModal, setShowOrderEditModal] = useState(false);
  const [notification, setNotification] = useState(null);

  const order = useSelector((state) => state.orders.entities[slug]);

  const someItemHasPriceQuantityGtOne =
    !!order && order.items.some((o) => o.price_quantity > 1);

  //useEffect(() => {
  //  dispatch(fetchBySlug({ slug }));
  //}, []);

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    const resultAction = await dispatch(
      confirm({ slug: order.slug, ...values }),
    );
    if (confirm.fulfilled.match(resultAction)) {
      setNotification("orders.order_response_created");
      resetForm();
    } else {
      if (resultAction.payload) {
        setErrors(resultAction.payload.field_errors);
      }
    }
  };

  return (
    <Fragment>
      {!!order && (
        <Fragment>
          {!!order._gui.permission.edit && showOrderEditModal && (
            <OrderEdit
              order={order}
              onClose={() => setShowOrderEditModal(false)}
            />
          )}
          <div className="container">
            <div className="row">
              {order.state === "BUYER_REPLACED" && (
                <div className="col-md-12 m-t-sm text-center">
                  <div className="alert alert-danger">
                    {t("orders.changes.replaced.ancestor")}
                  </div>
                </div>
              )}
              {order.state === "BUYER_CANCELED" && (
                <div className="col-md-12 m-t-sm text-center">
                  <div className="alert alert-danger">
                    {t("orders.changes.canceled")}
                  </div>
                </div>
              )}
              {order.has_ancestor && (
                <div className="col-md-12 m-t-sm text-center">
                  <div className="alert alert-warning">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("orders.changes.replaced.current"),
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="col-lg-4 p-0">
                <div className="ibox ">
                  <div className="ibox-title border-0">
                    <h5>{t("orders.buyer")}</h5>
                  </div>
                  <div className="ibox-content border-0">
                    <div className="row">
                      <div className="col-sm-12">
                        <Address {...order.parties.buyer} />
                      </div>
                      {!!order.parties.delivery && (
                        <div className="col-sm-12">
                          <hr className="m-n" />
                          <Address
                            role_info={t("orders.delivery_address")}
                            {...order.parties.delivery}
                          />
                        </div>
                      )}
                      {!!order.parties.invoice_recipient && (
                        <div className="col-sm-12">
                          <hr className="m-n" />
                          <Address
                            role_info={t("orders.invoice_address")}
                            {...order.parties.invoice_recipient}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 border-left border-right">
                {!!order.parties.supplier && (
                  <div className="ibox">
                    <div className="ibox-title border-0">
                      <h5>{t("orders.supplier")}</h5>
                    </div>
                    <div className="ibox-content border-0">
                      <Address {...order.parties.supplier} />
                    </div>
                  </div>
                )}
              </div>

              <div className="col-lg-4 p-0">
                <div className="ibox">
                  <div className="ibox-title border-0">
                    <h5>{t("orders.order")}</h5>
                    {!!order._gui.permission.edit &&
                      order.delivery_date.change_allowed && (
                        <button
                          className="btn btn-xs btn-light  m-l-xs pull-right"
                          data-toggle="tooltip"
                          title={t("common.edit")}
                          onClick={() => setShowOrderEditModal((p) => !p)}
                        >
                          <i className="fa fa-pencil-square-o" />
                        </button>
                      )}
                  </div>
                  <div className="ibox-content border-0">
                    <div className="row">
                      <div className="col-sm-12">
                        <table className="table table-borderless table-hover table-order-heading">
                          <tbody>
                            <tr>
                              <td></td>
                              <td>{t("orders.number")}:</td>
                              <td>{order.number}</td>
                            </tr>

                            <tr>
                              <td></td>
                              <td>{t("orders.date")}:</td>
                              <td>{order.date.date}</td>
                            </tr>
                            {!!order.delivery_date.start && (
                              <tr>
                                <td></td>
                                <td>{t("orders.delivery_date")}:</td>
                                <td>
                                  {order.delivery_date.start.date}
                                  {/*!!order.delivery_date.end &&
                                  `- ${order.delivery_date.end.date}`*/}
                                </td>
                              </tr>
                            )}
                            <tr
                              data-toggle="tooltip"
                              title={
                                order.supplier_order_number_is_mandatory &&
                                t("errors.required", {
                                  attr: `${t("orders.supplier")} ${t(
                                    "orders.number",
                                  )}`,
                                })
                              }
                            >
                              <td>
                                {order.supplier_order_number_is_mandatory && (
                                  <span className="text-danger">*</span>
                                )}
                              </td>
                              <td>{t("orders.supplier_order_number")}</td>
                              <td>
                                <p style={{ overflowWrap: "anywhere" }}>
                                  {!!order.supplier_order_number && (
                                    <span className="m-r-sm">
                                      {order.supplier_order_number}
                                    </span>
                                  )}
                                  {!!order._gui.permission.edit && (
                                    <button
                                      type={"button"}
                                      onClick={() =>
                                        setShowOrderEditModal(true)
                                      }
                                      className={`btn btn-xs ${
                                        order.supplier_order_number_is_mandatory
                                          ? !!order.supplier_order_number
                                            ? "btn-default"
                                            : "btn-danger"
                                          : "btn-default"
                                      }`}
                                    >
                                      {!!order.supplier_order_number ? (
                                        <i className="fa fa-edit"></i>
                                      ) : (
                                        t("common.please_enter")
                                      )}
                                    </button>
                                  )}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {!!order.attachments && order.attachments.length > 0 && (
                        <div className="col-sm-12">
                          <hr className="m-n" />
                          <span className="pull-right">
                            <small>{t("common.attachments")}</small>
                          </span>
                          <table className="table table-borderless table-hover table-order-heading">
                            <tbody>
                              {order.attachments.map((a, i) => (
                                <tr key={i}>
                                  <td>
                                    <i className="fa fa-download" />{" "}
                                    <a href={a.url}>{a.name}</a>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                      {order.uploads.allowed && (
                        <div className="col-sm-12">
                          <hr className="m-n" />
                          <span className="pull-right">
                            <small>{t("common.uploads")}</small>
                          </span>
                          {!!order.uploads.files &&
                            order.uploads.files.length > 0 && (
                              <table className="table table-borderless table-hover table-order-heading">
                                <tbody>
                                  {order.uploads.files.map((u, i) => (
                                    <tr key={i}>
                                      <td>
                                        <i
                                          className={`fa fa-${
                                            u.infected
                                              ? "exclamation-circle text-danger"
                                              : "upload"
                                          }`}
                                          data-toggle="tooltip"
                                          title={
                                            u.infected
                                              ? "This is virus infected and therefore suppressed"
                                              : u.name
                                          }
                                        />{" "}
                                        {!u.infected && (
                                          <a href={u.url}>{u.name}</a>
                                        )}
                                        {u.infected && u.name}
                                      </td>
                                      {!!order._gui.permission.edit && (
                                        <td>
                                          <button
                                            className="btn btn-xs btn-light pull-right"
                                            data-toggle="tooltip"
                                            title={t(
                                              "orders.uploads.delete_btn",
                                            )}
                                            onClick={() =>
                                              dispatch(
                                                deleteUpload({
                                                  slug: order.slug,
                                                  id: u.id,
                                                }),
                                              )
                                            }
                                          >
                                            <i className="fa fa-remove" />
                                          </button>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            )}
                          {order.uploads.allowed &&
                            order.upload_remaining > 0 &&
                            !!order._gui.permission.edit && (
                              <Upload
                                slug={order.slug}
                                accept={order.uploads.extensions.map(
                                  (e) => `.${e}`,
                                )}
                                maxFiles={order.upload_remaining}
                              />
                            )}
                        </div>
                      )}

                      {order.responses.length > 0 && (
                        <div className="col-sm-12">
                          <hr className="m-n" />
                          <span className="pull-right">
                            <small>{t("common.additional")}</small>
                          </span>
                          <table className="table table-borderless table-hover table-order-heading">
                            <tbody>
                              {order.responses.map((r, i) => (
                                <tr key={r.created_at.epoch}>
                                  <td>
                                    {r.accepted ? (
                                      <span
                                        className="label label-primary"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        {t("form.confirmed")}
                                      </span>
                                    ) : (
                                      <span
                                        className="label label-warning"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        {t("form.rejected")}
                                      </span>
                                    )}{" "}
                                    {t("common.at")} {r.created_at.fmt}
                                    {!!r.remarks && (
                                      <div className="m-t-sm">
                                        <h5>{t("common.note")}</h5>
                                        <p>{r.remarks}</p>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                              {/*<tr>
                                <td>
                                  <i className="fa fa-download" />{" "}
                                  <a
                                    href={`/api/v1/orders/${order.slug}/responses/1/content`}
                                  >
                                    export.xml
                                  </a>
                                </td>
                              </tr>*/}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12 p-0">
                <div className="">
                  <div className="">
                    {order.header.length > 0 && (
                      <div className="row m-b-sm m-t-sm">
                        <div className="col-md-12">
                          {order.header.map((l, i) =>
                            !!l ? <div key={i}>{l}</div> : <br key={i} />,
                          )}
                        </div>
                      </div>
                    )}
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th width={1} className="px-0">
                              #
                            </th>
                            <th className="text-nowrap">
                              {t("orders.item_number")}
                              <br />
                              <small>
                                ({t_special(t, order._gui.item_number.source)})
                              </small>
                            </th>
                            <th>{t("orders.description")}</th>
                            <th></th>
                            <th>{t("orders.unit")}</th>
                            <th className="text-right">
                              {t("orders.quantity")}
                            </th>
                            <th className="text-right">{t("orders.price")}</th>
                            {order.has_tax_details && (
                              <th className="text-center text-nowrap">
                                {t("orders.vat")}
                              </th>
                            )}
                            {someItemHasPriceQuantityGtOne && (
                              <th className="text-center text-nowrap">
                                {t("orders.price_unit")}
                              </th>
                            )}
                            <th className="text-right text-nowrap">
                              {t("orders.sum")}
                              {order.has_tax_details && (
                                <span> ({t("orders.net")})</span>
                              )}
                            </th>
                            <th className="px-0" width={1}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.items.map((item, index) => (
                            <>
                              <tr
                                key={item.row}
                                style={{
                                  backgroundColor: item.is_changed
                                    ? "lightyellow"
                                    : undefined,
                                }}
                              >
                                <td className="px-0">{item.line}</td>
                                <td>{item.number.current}</td>
                                <td>
                                  <div>
                                    {item.description_short.length > 0 &&
                                      item.description_short.map((l, i) =>
                                        !!l ? (
                                          <div key={i}>{t(l)}</div>
                                        ) : (
                                          <br key={i} />
                                        ),
                                      )}
                                    {item.description_long.length > 0 &&
                                      item.description_long.map((l, i) =>
                                        !!l ? (
                                          <div key={i}>{t(l)}</div>
                                        ) : (
                                          <br key={i} />
                                        ),
                                      )}
                                  </div>
                                  {!!item.barcode && (
                                    <div className="m-t-sm text-center clearfix">
                                      <div className="float-left m-l-sm">
                                        {FEATURES.ORDER.BARCODE_IMAGE &&
                                          item.barcode.show_image &&
                                          item.barcode.show_image && (
                                            <Image
                                              height={80}
                                              source={`/api/v1/barcodes?code=${item.barcode.code}&type=${item.barcode.type}`}
                                            />
                                          )}
                                        <br />
                                        <b>{item.barcode.type.toUpperCase()}</b>
                                        : {item.barcode.code}
                                      </div>
                                    </div>
                                  )}
                                  {/* Visualize highlited item attributes */}
                                  {item.attributes.current
                                    .filter(
                                      (attr) =>
                                        !attr.deleted && attr.data.highlight,
                                    )
                                    .map((attr, i) => (
                                      <div
                                        key={i}
                                        className={i === 0 && "m-t-sm"}
                                      >
                                        <b>
                                          {order.is_demo
                                            ? t(attr.label)
                                            : attr.label}
                                          :
                                        </b>{" "}
                                        {attr.data.values.join(", ")}
                                      </div>
                                    ))}

                                  {/* Visualize item delivery date*/}
                                  {(!!item.delivery_date.current.start ||
                                    !!order.delivery_date.start) && (
                                    <div
                                      className={
                                        item.attributes.current.filter(
                                          (attr) =>
                                            !attr.deleted &&
                                            attr.data.highlight,
                                        ).length === 0 && "m-t-sm"
                                      }
                                    >
                                      <b>{t("orders.delivery_date")}:</b>{" "}
                                      {item.delivery_date.current.start?.date ||
                                        order.delivery_date.start?.date}
                                    </div>
                                  )}

                                  {!!item.supplier_order_number && (
                                    <div className="">
                                      <b>
                                        {t("orders.supplier_order_number")}:
                                      </b>{" "}
                                      {item.supplier_order_number}
                                    </div>
                                  )}

                                  {item.remarks.length > 0 && (
                                    <div className="m-t-sm">
                                      <small>
                                        {item.remarks.map((r, i) =>
                                          !!r ? (
                                            <div key={i}>{r}</div>
                                          ) : (
                                            <br key={i} />
                                          ),
                                        )}
                                      </small>
                                    </div>
                                  )}
                                  {!!item.remarks_new && (
                                    <div className="m-t-sm">
                                      <small>{item.remarks_new}</small>
                                    </div>
                                  )}
                                  {item.partials.current.filter(
                                    (p) => !p.deleted,
                                  ).length > 0 && (
                                    <div>
                                      <hr className="hr-line-dashed" />
                                      {item.partials.current
                                        .filter((p) => !p.deleted)
                                        .map((p, i) => (
                                          <Partial
                                            key={i}
                                            index={i + 1}
                                            item={item}
                                            partial={p}
                                          />
                                        ))}
                                    </div>
                                  )}
                                </td>
                                <td className="text-nowrap">
                                  {/* allowance surcharge description */}
                                  <br />
                                  <br />
                                  <ul className="list-unstyled">
                                    {item.expenses
                                      .filter(
                                        (c) =>
                                          order.allowances_and_charges_show_zero_values ||
                                          Number(c.sum) !== Number(0),
                                      )
                                      .map((c, i) => (
                                        <>
                                          <li key={i}>
                                            {!!c.description ? (
                                              t(c.description)
                                            ) : (
                                              <br />
                                            )}
                                          </li>
                                          {c.last && (
                                            <li>{t("common.subtotal")}</li>
                                          )}
                                        </>
                                      ))}
                                  </ul>
                                </td>
                                <td>{t(item.unit)}</td>
                                <td className="text-right text-nowrap">
                                  <span>
                                    {formatNumber(item.quantity.current, 0)}
                                  </span>
                                  {selectedItem === index && (
                                    <OrderItemEdit
                                      order={order}
                                      item={item}
                                      onClose={() => setSelectedItem(null)}
                                    />
                                  )}

                                  <br />
                                  <br />
                                  <ul className="list-unstyled">
                                    {item.expenses
                                      .filter(
                                        (c) =>
                                          order.allowances_and_charges_show_zero_values ||
                                          Number(c.sum) !== Number(0),
                                      )
                                      .map((c, i) => (
                                        <>
                                          <li key={i}>
                                            {!!c.quantity ? (
                                              formatNumber(c.quantity, 0)
                                            ) : (
                                              <br />
                                            )}
                                          </li>
                                          {c.last && <br />}
                                        </>
                                      ))}
                                  </ul>
                                </td>
                                <td className="text-right text-nowrap">
                                  {formatNumber(
                                    item.price.current,
                                    order.price_min_fraction,
                                    order.price_max_fraction,
                                  )}{" "}
                                  {order.currency.symbol}
                                  <br />
                                  <br />
                                  <ul className="list-unstyled">
                                    {item.expenses
                                      .filter(
                                        (c) =>
                                          order.allowances_and_charges_show_zero_values ||
                                          Number(c.sum) !== Number(0),
                                      )
                                      .map((c, i) => (
                                        <>
                                          <li key={i} className="text-nowrap">
                                            {!!c.percent || !!c.amount ? (
                                              <span>
                                                {c.percent !== null
                                                  ? formatNumber(c.percent, 0)
                                                  : formatNumber(
                                                      c.amount,
                                                      order.price_min_fraction,
                                                      order.price_max_fraction,
                                                    )}{" "}
                                                {!!c.percent
                                                  ? "%"
                                                  : order.currency.symbol}
                                              </span>
                                            ) : (
                                              <br />
                                            )}
                                          </li>
                                          {c.last && <br />}
                                        </>
                                      ))}
                                  </ul>
                                </td>
                                {order.has_tax_details && (
                                  <td className="text-center">
                                    {formatNumber(item.tax.current, 0)} %
                                  </td>
                                )}
                                {someItemHasPriceQuantityGtOne && (
                                  <td className="text-center">
                                    {formatNumber(item.price_quantity, 0)}
                                  </td>
                                )}
                                <td className="text-right text-nowrap">
                                  {formatNumber(
                                    item.price_sum.current,
                                    order.amount_min_fraction,
                                    order.amount_max_fraction,
                                  )}{" "}
                                  {order.currency.symbol}
                                  {/* allowance surcharge on item.amount level */}
                                  <br />
                                  <br />
                                  <ul className="list-unstyled">
                                    {item.expenses
                                      .filter(
                                        (c) =>
                                          order.allowances_and_charges_show_zero_values ||
                                          Number(c.sum) !== Number(0),
                                      )
                                      .map((c, i) => (
                                        <>
                                          <li
                                            key={i}
                                            className={
                                              c.type === "sum"
                                                ? "border-bottom border-underline"
                                                : undefined
                                            }
                                          >
                                            {!!c.sum ? (
                                              <>
                                                {c.sign === -1 && "-"}
                                                {formatNumber(
                                                  c.sum,
                                                  order.amount_min_fraction,
                                                  order.amount_max_fraction,
                                                )}{" "}
                                                {order.currency.symbol}
                                              </>
                                            ) : (
                                              <br />
                                            )}
                                          </li>
                                          {c.last && (
                                            <li>
                                              {formatNumber(
                                                c.subtotal,
                                                order.amount_min_fraction,
                                                order.amount_max_fraction,
                                              )}{" "}
                                              {order.currency.symbol}
                                            </li>
                                          )}
                                        </>
                                      ))}
                                  </ul>
                                </td>
                                <td className="px-0 w-0">
                                  {!!order._gui.permission.edit &&
                                    (item.partial_shipment_allowed ||
                                      item.price_change_allowed ||
                                      item.tax_change_allowed ||
                                      item.allowances_and_charges_allowed ||
                                      item.quantity_change_allowed) && (
                                      <button
                                        className="btn btn-xs btn-light m-r-xs"
                                        data-toggle="tooltip"
                                        title={t("common.edit")}
                                        onClick={() => {
                                          setSelectedItem(index);
                                        }}
                                      >
                                        <i className="fa fa-pencil" />
                                      </button>
                                    )}
                                </td>
                              </tr>
                              {/*item.line === 2 && false && (
                                <tr>
                                  <td className="no-borders"></td>
                                  <td className="no-borders"></td>
                                  <td className="no-borders"></td>
                                  <td className="no-borders"></td>
                                  <td colSpan={5} className="no-borders">
                                    <table className="table table-borderless table-order-heading">
                                      <tbody>
                                        <tr>
                                          <td>Rabatt 1</td>
                                          <td></td>
                                          <td className="text-right text-nowrap">
                                            10€
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Rabatt 2</td>
                                          <td></td>
                                          <td className="text-right text-nowrap">
                                            10€
                                          </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td>Zuschlag 1</td>
                                          <td className="text-right text-nowrap">
                                            10€
                                          </td>
                                        </tr>
                                        <tr>
                                          <td></td>
                                          <td>Zuschlag 2</td>
                                          <td className="text-right text-nowrap">
                                            10€
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )*/}
                            </>
                          ))}
                          {order.expenses
                            .filter(
                              (e) =>
                                order.allowances_and_charges_show_zero_values ||
                                Number(e.sum) !== Number(0),
                            )
                            .map((e, i) => (
                              <Fragment key={i}>
                                {i === 0 && (
                                  <tr>
                                    <td className="px-0" />
                                    <td />
                                    <td>{t("common.subtotal")}</td>
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                    {order.has_tax_details && <td />}
                                    {someItemHasPriceQuantityGtOne && <td />}
                                    <td className="text-right text-nowrap">
                                      {Number(e.base) < Number(0) && "-"}
                                      {formatNumber(
                                        e.base,
                                        order.amount_min_fraction,
                                        order.amount_max_fraction,
                                      )}{" "}
                                      {order.currency.symbol}
                                    </td>
                                    {!!order._gui.permission.edit && <td></td>}
                                  </tr>
                                )}
                                <tr>
                                  <td className="px-0" />
                                  <td />
                                  <td
                                    style={{
                                      paddingLeft: e.bold ? undefined : "2rem",
                                    }}
                                  >
                                    {!!e.description && t(e.description)}
                                  </td>
                                  <td />
                                  <td />
                                  <td className="text-right text-nowrap">
                                    {!!e.percent ? "" : ""}
                                  </td>
                                  <td className="text-right text-nowrap">
                                    {!!e.percent && formatNumber(e.percent, 0)}
                                    {!!e.percent && " %"}
                                    {!!e.amount &&
                                      formatNumber(
                                        e.amount,
                                        order.price_min_fraction,
                                        order.price_max_fraction,
                                      )}{" "}
                                    {!!e.amount && order.currency.symbol}
                                  </td>
                                  {order.has_tax_details && (
                                    <td className="text-center text-nowrap">
                                      {!!e.tax && formatNumber(e.tax, 0)}
                                      {!!e.tax && " %"}
                                    </td>
                                  )}
                                  {someItemHasPriceQuantityGtOne && (
                                    <td className="text-center"></td>
                                  )}
                                  <td className="text-right text-nowrap">
                                    {e.sign === -1 && "-"}
                                    {formatNumber(
                                      e.sum,
                                      order.amount_min_fraction,
                                      order.amount_max_fraction,
                                    )}{" "}
                                    {order.currency.symbol}
                                  </td>
                                  {!!order._gui.permission.edit && <td></td>}
                                </tr>
                                {e.last && (
                                  <tr>
                                    <td className="px-0" />
                                    <td />
                                    <td>{t("common.subtotal")}</td>
                                    <td />
                                    <td />
                                    <td />
                                    <td />
                                    {order.has_tax_details && <td />}
                                    {someItemHasPriceQuantityGtOne && <td />}
                                    <td className="text-right text-nowrap">
                                      {formatNumber(
                                        e.subtotal,
                                        order.amount_min_fraction,
                                        order.amount_max_fraction,
                                      )}{" "}
                                      {order.currency.symbol}
                                    </td>
                                    {!!order._gui.permission.edit && <td></td>}
                                  </tr>
                                )}
                              </Fragment>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {!!order.summary && (
                      <div className="row m-t-md">
                        <div className="offset-md-8 col-md-4">
                          <table className="table table-borderless table-order-heading table-hover">
                            {/*<thead>
                            tr>
                              <td></td>
                              <td className="text-center">Base</td>
                              <td></td>
                            </tr>
                            </thead>\
                            */}
                            <tbody>
                              {order.has_tax_details &&
                                !!order.summary.total_net && (
                                  <tr>
                                    <td>{t("orders.net")}:</td>
                                    <td></td>
                                    <td
                                      className="text-right text-nowrap"
                                      style={{ paddingRight: 8 }}
                                    >
                                      {formatNumber(
                                        order.summary.total_net,
                                        order.amount_min_fraction,
                                        order.amount_max_fraction,
                                      )}{" "}
                                      {order.currency.symbol}
                                    </td>
                                  </tr>
                                )}
                              {order.has_tax_details &&
                                order.summary.taxes.map((s) => (
                                  <tr key={s.tax}>
                                    <td className="text-capitalize">
                                      {t("orders.vat")} {formatNumber(s.tax, 0)}
                                      %:
                                    </td>
                                    <td className="text-right text-nowrap">
                                      {formatNumber(
                                        s.base,
                                        order.amount_min_fraction,
                                        order.amount_max_fraction,
                                      )}{" "}
                                      {order.currency.symbol}
                                    </td>
                                    <td
                                      className="text-right text-nowrap"
                                      style={{ paddingRight: 8 }}
                                    >
                                      {formatNumber(
                                        s.amount,
                                        order.amount_min_fraction,
                                        order.amount_max_fraction,
                                      )}{" "}
                                      {order.currency.symbol}
                                    </td>
                                  </tr>
                                ))}
                              {!!order.summary.total && (
                                <tr
                                  className={
                                    order.has_tax_details
                                      ? `border-top`
                                      : undefined
                                  }
                                >
                                  <td>{t("orders.sum")}:</td>
                                  <td></td>
                                  <td
                                    className="text-right"
                                    style={{ paddingRight: 8 }}
                                  >
                                    {formatNumber(
                                      order.summary.total,
                                      order.amount_min_fraction,
                                      order.amount_max_fraction,
                                    )}{" "}
                                    {order.currency.symbol}
                                  </td>
                                  <td className="px-0 w-0" width={1}>
                                    {!!order._gui.permission.edit &&
                                      order.delivery_date.change_allowed && (
                                        <button
                                          className="btn btn-xs btn-light m-r-xs"
                                          data-toggle="tooltip"
                                          title={t("common.edit")}
                                          onClick={() =>
                                            setShowOrderEditModal((p) => !p)
                                          }
                                        >
                                          <i className="fa fa-pencil-square-o" />
                                        </button>
                                      )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>

                  {order.footer.length > 0 && (
                    <div className="row m-b-sm m-t-sm">
                      <div className="col-md-12">
                        {order.footer.map((l, i) =>
                          !!l ? <div key={i}>{l}</div> : <br key={i} />,
                        )}
                      </div>
                    </div>
                  )}

                  {!!order._gui.permission.edit && (
                    <Formik
                      validateOnChange={false}
                      validateOnBlur={false}
                      initialValues={initialValues}
                      onSubmit={handleSubmit}
                      validationSchema={createValidationSchema(t)}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        submitForm,
                        setFieldValue,
                        setSubmitting,
                        isSubmitting,
                        isValid,
                        /* and other goodies */
                      }) => (
                        <Form>
                          <div className="m-t-sm">
                            <div className="row">
                              <div className="col-md-12">
                                <Field
                                  error={errors.remarks}
                                  htmlFor="remarks"
                                  as="textarea"
                                  placeholder={t("form.message_placeholder")}
                                  className={`m-t-md ${
                                    errors.remarks ? "is-invalid" : ""
                                  }`}
                                />
                              </div>

                              <div className="offset-xl-9 col-xl-3">
                                <div className="btn-group btn-block">
                                  <button
                                    className="btn btn-primary"
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={(e) => {
                                      // validate
                                      if (
                                        order.supplier_order_number_is_mandatory &&
                                        !order.supplier_order_number
                                      ) {
                                        alert(
                                          t(
                                            "orders.please_enter_supplier_order_number",
                                          ),
                                        );
                                        return;
                                      }

                                      // confirm
                                      if (
                                        window.confirm(
                                          t("orders.confirm_order"),
                                        )
                                      ) {
                                        setFieldValue("accept", true).then(() =>
                                          submitForm().then(() =>
                                            setSubmitting(false),
                                          ),
                                        );
                                      }
                                    }}
                                  >
                                    <i className="fa fa-check" />{" "}
                                    {t("form.confirm")}
                                  </button>
                                  <button
                                    className="btn btn-danger"
                                    type="button"
                                    disabled={isSubmitting}
                                    onClick={(e) => {
                                      //e.preventDefault();  // this will cause no post?
                                      window.confirm(
                                        t("orders.reject_order"),
                                      ) &&
                                        setFieldValue("accept", false).then(
                                          () =>
                                            submitForm().then(() =>
                                              setSubmitting(false),
                                            ),
                                        );
                                    }}
                                  >
                                    <i className="fa fa-close" />{" "}
                                    {t("form.reject")}
                                  </button>
                                </div>

                                {DEV_MODE && (
                                  <pre
                                    style={{
                                      fontSize: "1rem",
                                      padding: ".25rem .5rem",
                                      overflowX: "scroll",
                                    }}
                                  >
                                    VALUES: {JSON.stringify(values, null, 2)}
                                    <br />
                                    ERRORS: {JSON.stringify(errors, null, 2)}
                                  </pre>
                                )}
                              </div>
                              {!!notification && (
                                <div className="col-md-12 m-t-sm">
                                  <div className="alert alert-success">
                                    {t(notification)}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          {!!errors.error && (
                            <div className="alert alert-danger m-t-sm">
                              {t(errors.error.map((o) => t(o.t)))}
                            </div>
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

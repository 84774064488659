/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

const PageAuth = (props) => {
  return (
    <div id="page-wrapper" style={{ backgroundColor: "#f1f4f5" }}>
      {props.children}
    </div>
  );
};

export default PageAuth;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import React, { useState, Fragment } from "react";
import { ErrorMessage, Field, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import { ModalBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { DEV_MODE } from "../../constants";
import { matchSorter } from "match-sorter";
import { ModalFooter } from "../../layout/Modal";
import { selectDespatchAdviceItems, updateItems } from "./ducks/slice";
import { formatNumber } from "../../translations/intl";
import { t_special } from "../../helper";

const createInitialValues = (
  order_items,
  desadv_items_by_order_item_ids,
  t
) => ({
  items: order_items.reduce((map, obj) => {
    map[obj.id] = {
      order_item_id: obj.id,
      number: obj.number,
      row: obj.row,
      line: obj.line,
      description_short: obj.description_short,
      description_long: obj.description_long,
      quantity: {
        // current on order_item is latest committed quantity
        // In case of multi order response this is the latest values
        // If we have a desadv item we use latest values from that state. otherwise we use values from desadv state :-/
        committed: !!desadv_items_by_order_item_ids[obj.id]
          ? formatNumber(
              desadv_items_by_order_item_ids[obj.id].quantity.order.committed,
              0
            )
          : formatNumber(obj.quantity.committed, 0),
        remaining: !!desadv_items_by_order_item_ids[obj.id]
          ? formatNumber(
              desadv_items_by_order_item_ids[obj.id].quantity.order.remaining,
              0
            )
          : formatNumber(obj.quantity.remaining, 0),
        current: !!desadv_items_by_order_item_ids[obj.id]
          ? formatNumber(
              desadv_items_by_order_item_ids[obj.id].quantity.current,
              0,
              20,
              false
            ).replace(",", ".")
          : "",
      },
      unit: t(obj.unit),
    };
    return map;
  }, {}),
});

const createValidateSchema = (order_items, desadv_items_by_order_item_ids, t) =>
  Yup.object().shape({
    items: Yup.object().shape(
      order_items.reduce((map, item) => {
        // if this is an existing desadv item relation we use sum of current
        // and remaining to calculate quantity maximum
        // otherwise overall item remaing is the maximum
        const quantity_maximum = !!desadv_items_by_order_item_ids[item.id]
          ? Number(desadv_items_by_order_item_ids[item.id].quantity.current) +
            Number(
              desadv_items_by_order_item_ids[item.id].quantity.order.remaining
            )
          : item.quantity.remaining;

        map[item.id] = Yup.object().shape({
          //row: Yup.date().required(),
          quantity: Yup.object().shape({
            current: Yup.number()
              //.required(t("errors.required", {attr: t("despatch_advices.quantity")}))
              .min(0.0)
              .max(
                // current + remaining is max
                quantity_maximum,
                t("errors.lte", {
                  attr: t("despatch_advices.quantity"),
                  value: formatNumber(quantity_maximum, 0),
                })
              )
              .typeError(t("errors.valid", { type: t("common.number") })),
          }),
        });
        return map;
      }, {})
    ),
  });

export default function DespatchAdviceItemsTab({ desadv, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const desadv_items = useSelector(
    selectDespatchAdviceItems({ slug: desadv.slug })
  );
  const desadv_items_by_order_item_ids = desadv_items.reduce((map, obj) => {
    map[obj.order_item.id] = obj;
    return map;
  }, {});

  const order_items = !!desadv.order
    ? desadv.order.items.map((item) => ({
        ...item,
        description_short_t: t(item.description_short.join(" ")),
        description_long_t: t(item.description_long.join(" ")),
      }))
    : [];
  const filtered_order_items_ids = !!search.trim()
    ? matchSorter(order_items, search, {
        keys: ["number", "description_short_t", "description_long_t"],
        threshold: matchSorter.rankings.CONTAINS,
      }).map((i) => i.id)
    : [];

  //const order_items = useSelector(
  //  selectOrderItems({ slug: !!desadv.order ? desadv.order.slug : null })
  //);

  //useEffect(() => {
  //  if (!!desadv.order) {
  //    dispatch(fetchOrderBySlug({ slug: desadv.order.slug }));
  //  }
  //}, []);

  const handleSubmit = async (values, { setErrors, resetForm }) => {
    const resultAction = await dispatch(
      updateItems({ slug: desadv.slug, items: Object.values(values.items) })
    );
    if (updateItems.fulfilled.match(resultAction)) {
      onClose();
    } else {
    }
  };

  return (
    <Fragment>
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        initialValues={createInitialValues(
          order_items,
          desadv_items_by_order_item_ids,
          t
        )}
        onSubmit={handleSubmit}
        validationSchema={createValidateSchema(
          order_items,
          desadv_items_by_order_item_ids,
          t
        )}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <ModalBody>
              <div className="container">
                <div className="row">
                  <div className="p-1 col-4 text-left">
                    <div className={search !== "" ? "input-group" : undefined}>
                      <input
                        className="form-control"
                        placeholder={t("common.search") + "..."}
                        type="text"
                        onChange={(e) => setSearch(e.target.value || "")}
                        value={search}
                      />
                      {search !== "" && (
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setSearch("")}
                          >
                            <i className="fa fa-remove"></i>
                          </button>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row border-bottom">
                  <div
                    className="p-1 col-1 text-left font-bold"
                    style={{ maxWidth: "3rem" }}
                  >
                    #
                  </div>
                  <div className="p-1 col-1 text-left text-nowrap font-bold">
                    {t("despatch_advices.item_number")}
                    <br />
                    <small>
                      ({t_special(t, desadv.order._gui.item_number.source)})
                    </small>
                  </div>
                  <div className="p-1 col text-left font-bold">
                    {t("despatch_advices.description")}
                  </div>
                  <div
                    className="p-1 col-1 text-right font-bold"
                    data-toggle="tooltip"
                    title={t("despatch_advices.help.3")}
                  >
                    {t("despatch_advices.quantity")}
                  </div>
                  <div
                    className="p-1 col-1 text-right font-bold my"
                    data-toggle="tooltip"
                    title={t("despatch_advices.help.1")}
                  >
                    {t("common.remaining")}
                  </div>
                  <div
                    className="p-1 col-1 text-right font-bold"
                    data-toggle="tooltip"
                    title={t("despatch_advices.help.2")}
                  >
                    {t("common.committed")}
                  </div>
                  <div className="p-1 col-1 text-right font-bold">
                    {t("despatch_advices.unit")}
                  </div>
                </div>
                {Object.entries(values.items).map(([key, item]) => (
                  <Fragment key={key}>
                    <div
                      className="row border-bottom"
                      style={{
                        display:
                          filtered_order_items_ids.length > 0
                            ? filtered_order_items_ids.includes(
                                item.order_item_id
                              )
                              ? undefined
                              : "none"
                            : undefined,
                      }}
                    >
                      <div
                        className="p-1 col-1 my-auto"
                        style={{ maxWidth: "3rem" }}
                      >
                        {values.items[key].line}
                      </div>
                      <div className="p-1 col-1 text-left my-auto">
                        {values.items[key].number}
                      </div>
                      <div className="p-1 col my-auto">
                        {item.description_short.length > 0 &&
                          item.description_short.map((l, i) =>
                            !!l ? <div key={i}>{t(l)}</div> : <br />
                          )}
                        {item.description_long.length > 0 &&
                          item.description_long.map((l, i) =>
                            !!l ? <div key={i}>{t(l)}</div> : <br />
                          )}
                      </div>
                      <div className="p-1 col-1">
                        <Field
                          //type="number"
                          as="input"
                          name={`items.${key}.quantity.current`}
                          onChange={(e) =>
                            setFieldValue(
                              `items.${key}.quantity.current`,
                              e.target.value
                                .replace(",", ".")
                                .replace(/[^\d.]/g, "")
                            )
                          }
                          //disabled={
                          //  values.items[key].quantity.remaining === "0" &&
                          //  !values.items[key].quantity.current
                          //}
                          className={`form-control text-right ${
                            getIn(errors, `items.${key}.quantity.current`)
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`items.${key}.quantity.current`}
                          render={(msg) => (
                            <div className="order-last invalid-feedback text-left">
                              {msg}
                            </div>
                          )}
                        />
                      </div>

                      <div className="p-1 col-1 text-right my-auto">
                        {values.items[key].quantity.remaining}
                      </div>
                      <div className="p-1 col-1 text-right my-auto">
                        {values.items[key].quantity.committed}
                      </div>
                      <div className="p-1 col-1 text-right my-auto">
                        {values.items[key].unit}
                      </div>
                    </div>
                    {/*<div className="row bg-white m-b-sm border-bottom">
                          <div className="col p-xs">
                            {item.description_short.length > 0 &&
                              item.description_short.map((l, i) =>
                                !!l ? <div key={i}>{t(l)}</div> : <br />
                              )}
                            {item.description_long.length > 0 &&
                              item.description_long.map((l, i) =>
                                !!l ? <div key={i}>{t(l)}</div> : <br />
                              )}
                          </div>
                        </div>*/}
                  </Fragment>
                ))}
              </div>

              {DEV_MODE && (
                <pre
                  style={{
                    fontSize: "1rem",
                    padding: ".25rem .5rem",
                    overflowX: "scroll",
                  }}
                >
                  FILTERED: {JSON.stringify(filtered_order_items_ids, null, 2)}
                  ITEMS: {JSON.stringify(order_items, null, 2)}
                  VALUES: {JSON.stringify(values, null, 2)}
                  <br />
                  ERRORS: {JSON.stringify(errors, null, 2)}
                </pre>
              )}

              {!!errors.error && (
                <div className="alert alert-danger m-t-sm">{errors.error}</div>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="btn-group">
                <button className="btn btn-primary" type={"submit"}>
                  <i className="fa fa-save" /> {t("form.save")}
                </button>
                <button className="btn btn-danger" onClick={() => onClose()}>
                  <i className="fa fa-close" /> {t("form.cancel")}
                </button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}

import React, { useState, Fragment } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ModalBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { DEV_MODE } from "../../constants";
import { matchSorter } from "match-sorter";
import { ModalFooter } from "../../layout/Modal";
import { formatNumber } from "../../translations/intl";
import { t_special } from "../../helper";

const createInitialValues = (order_items, t) => ({
  items: order_items.reduce((map, obj) => {
    map[obj.id] = {
      id: obj.id,
      number: obj.number,
      row: obj.row,
      line: obj.line,
      description_short: obj.description_short,
      description_long: obj.description_long,
      quantity: obj.quantity,
      unit: t(obj.unit),
    };
    return map;
  }, {}),
});

const createValidateSchema = (order_items, t) =>
  Yup.object().shape({
    items: Yup.object().shape(
      order_items.reduce((map, item) => {
        // refactor for order items
        const quantity_maximum = item.quantity.current;

        map[item.id] = Yup.object().shape({
          //row: Yup.date().required(),
          quantity: Yup.object().shape({
            current: Yup.number()
              //.required(t("errors.required", {attr: t("despatch_advices.quantity")}))
              .min(0.0)
              .max(
                // current + remaining is max
                quantity_maximum,
                t("errors.lte", {
                  attr: t("orders.quantity"),
                  value: formatNumber(quantity_maximum, 0),
                }),
              )
              .typeError(t("errors.valid", { type: t("common.number") })),
          }),
        });
        return map;
      }, {}),
    ),
  });

export default function OrderItemsTab({ order, onClose }) {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  const order_items = order.items.map((item) => ({
    ...item,
    description_short_t: t(item.description_short.join(" ")),
    description_long_t: t(item.description_long.join(" ")),
  }));

  const filtered_order_items_ids = !!search.trim()
    ? matchSorter(order_items, search, {
        keys: ["number.current", "description_short_t", "description_long_t"],
        threshold: matchSorter.rankings.CONTAINS,
      }).map((i) => i.id)
    : [];

  const handleSubmit = async (values, { setErrors, resetForm }) => {};

  return (
    <Fragment>
      <Formik
        validateOnChange={true}
        validateOnBlur={true}
        enableReinitialize={true}
        initialValues={createInitialValues(order_items, t)}
        onSubmit={handleSubmit}
        validationSchema={createValidateSchema(order_items, t)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            <ModalBody>
              <div className="container">
                <div className="row">
                  <div className="p-1 col-4 text-left">
                    <div className={search !== "" ? "input-group" : undefined}>
                      <input
                        className="form-control"
                        placeholder={t("common.search") + "..."}
                        type="text"
                        onChange={(e) => setSearch(e.target.value || "")}
                        value={search}
                      />
                      {search !== "" && (
                        <span className="input-group-append">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => setSearch("")}
                          >
                            <i className="fa fa-remove"></i>
                          </button>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row border-bottom">
                  <div
                    className="p-1 col-1 text-left font-bold"
                    style={{ maxWidth: "3rem" }}
                  >
                    #
                  </div>
                  <div className="p-1 col-1 text-left text-nowrap font-bold">
                    {t("orders.item_number")}
                    <br />
                    <small>
                      ({t_special(t, order._gui.item_number.source)})
                    </small>
                  </div>
                  <div className="p-1 col text-left font-bold">
                    {t("orders.description")}
                  </div>
                  <div
                    className="p-1 col-1 text-right font-bold"
                    data-toggle="tooltip"
                    title={t("orders.help.1")}
                  >
                    {t("common.original")}
                  </div>
                  <div
                    className="p-1 col-1 text-right font-bold"
                    data-toggle="tooltip"
                    title={t("orders.help.1")}
                  >
                    {t("common.current")}
                  </div>
                  {/* <div
                                        className="p-1 col-1 text-right font-bold my"
                                        data-toggle="tooltip"
                                        title={t("orders.help.2")}
                                    >
                                        {t("common.confirmed")}
                                    </div> */}
                  <div
                    className="p-1 col-1 text-right font-bold"
                    data-toggle="tooltip"
                    title={t("orders.help.3")}
                  >
                    {t("common.advised")}
                  </div>
                  <div className="p-1 col-1 text-right font-bold">
                    {t("orders.unit")}
                  </div>
                </div>
                {Object.entries(values.items).map(([key, item]) => (
                  <Fragment key={key}>
                    <div
                      className="row border-bottom"
                      style={{
                        display:
                          filtered_order_items_ids.length > 0
                            ? filtered_order_items_ids.includes(item.id)
                              ? undefined
                              : "none"
                            : undefined,
                      }}
                    >
                      <div
                        className="p-1 col-1 my-auto"
                        style={{ maxWidth: "3rem" }}
                      >
                        {values.items[key].line}
                      </div>
                      <div className="p-1 col-1 text-left my-auto">
                        {values.items[key].number.current}
                      </div>
                      <div className="p-1 col my-auto">
                        {item.description_short.length > 0 &&
                          item.description_short.map((l, i) =>
                            !!l ? <div key={i}>{t(l)}</div> : <br />,
                          )}
                        {item.description_long.length > 0 &&
                          item.description_long.map((l, i) =>
                            !!l ? <div key={i}>{t(l)}</div> : <br />,
                          )}
                      </div>
                      <div className="p-1 col-1 text-right my-auto">
                        {formatNumber(values.items[key].quantity.original, 0)}
                      </div>
                      <div className="p-1 col-1 text-right my-auto">
                        {formatNumber(values.items[key].quantity.current, 0)}
                      </div>
                      <div className="p-1 col-1 text-right my-auto">
                        {formatNumber(
                          values.items[key].quantity.delivery.delivered,
                          0,
                        )}
                      </div>
                      {/* 

                                            <div className="p-1 col-1 text-right my-auto">
                                                {values.items[key].quantity.remaining}
                                            </div>
                                            <div className="p-1 col-1 text-right my-auto">
                                                {values.items[key].quantity.committed}
                                            </div> */}
                      <div className="p-1 col-1 text-right my-auto">
                        {values.items[key].unit}
                      </div>
                    </div>
                    {/*<div className="row bg-white m-b-sm border-bottom">
                                  <div className="col p-xs">
                                    {item.description_short.length > 0 &&
                                      item.description_short.map((l, i) =>
                                        !!l ? <div key={i}>{t(l)}</div> : <br />
                                      )}
                                    {item.description_long.length > 0 &&
                                      item.description_long.map((l, i) =>
                                        !!l ? <div key={i}>{t(l)}</div> : <br />
                                      )}
                                  </div>
                                </div>*/}
                  </Fragment>
                ))}
              </div>

              {DEV_MODE && (
                <pre
                  style={{
                    fontSize: "1rem",
                    padding: ".25rem .5rem",
                    overflowX: "scroll",
                  }}
                >
                  FILTERED: {JSON.stringify(filtered_order_items_ids, null, 2)}
                  ITEMS: {JSON.stringify(order_items, null, 2)}
                  VALUES: {JSON.stringify(values, null, 2)}
                  <br />
                  ERRORS: {JSON.stringify(errors, null, 2)}
                </pre>
              )}

              {!!errors.error && (
                <div className="alert alert-danger m-t-sm">{errors.error}</div>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="btn-group">
                {/* <button className="btn btn-primary" type={"submit"}>
                                    <i className="fa fa-save" /> {t("form.save")}
                                </button> */}
                <button className="btn btn-danger" onClick={() => onClose()}>
                  <i className="fa fa-close" /> {t("form.cancel")}
                </button>
              </div>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
}

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";
import { useTranslation } from "react-i18next";

import Modal, { ModalHeader } from "../../layout/Modal";
import { Tabs, Tab } from "react-bootstrap";

import OrderItemPartialsTab from "./OrderItemPartialsTab";
import OrderItemEditTab from "./OrderItemEditTab";
import OrderItemAllowancesAndChargesTab from "./OrderItemAllowancesAndChargesTab";
import OrderItemAttributesTab from "./OrderItemAttributesTab";

function OrderItemEdit({ order, item, onClose }) {
  const { t } = useTranslation();

  return (
    <Modal>
      <ModalHeader style={{ borderBottom: "5px", padding: "15px" }}>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div>
          {t("orders.item_edit_modal_header_title", { line: item.line })}
          <h4 style={{ display: "" }}>{item.number.current}</h4>
        </div>
        <small className="text-wrap">
          {item.description_short.length > 0 &&
            item.description_short.map((l, i) =>
              !!l ? <div key={i}>{t(l)}</div> : <br key={i} />,
            )}
          {item.description_long.length > 0 &&
            item.description_long.map((l, i) =>
              !!l ? <div key={i}>{t(l)}</div> : <br key={i} />,
            )}
        </small>
      </ModalHeader>
      <Tabs defaultActiveKey="edit" id="order-item" transition={false}>
        <Tab eventKey="edit" title={t("orders.item")}>
          <OrderItemEditTab order={order} item={item} onClose={onClose} />
        </Tab>
        {(item.attributes_add_allowed ||
          item.attributes.original.length > 0) && (
          <Tab eventKey="attributes" title={t("attributes.attributes")}>
            <OrderItemAttributesTab
              order={order}
              item={item}
              onClose={onClose}
            />
          </Tab>
        )}
        {item.allowances_and_charges_allowed && (
          <Tab
            eventKey="allowance_and_charge"
            title={t("orders.allowances_and_charges")}
          >
            <OrderItemAllowancesAndChargesTab
              order={order}
              item={item}
              onClose={onClose}
            />
          </Tab>
        )}
        {item.partial_shipment_allowed && (
          <Tab eventKey="partials" title={t("orders.partial_deliveries")}>
            <OrderItemPartialsTab order={order} item={item} onClose={onClose} />
          </Tab>
        )}
      </Tabs>
    </Modal>
  );
}

export default OrderItemEdit;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

const PageUnauth = (props) => {
  return (
    <div id="page-wrapper" className="gray-bg">
      {props.children}
    </div>
  );
};

export default PageUnauth;

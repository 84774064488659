/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { upsertNotifications } from "../../notifications/ducks/slice";
import request from "../../../requests";
import { upsertActivities } from "../../activities/ducks/slice";

const adapter = createEntityAdapter({
  selectId: (order) => order.slug,
});
const initialState = adapter.getInitialState({
  total: 0,
});

export const FILTERS = Object.freeze({
  PENDING: "pending",
  DUE: "due",
  REJECTED: "rejected",
  ACCEPTED: "accepted",
  ALL: "all",
});

export const fetchSome = createAsyncThunk(
  "orders/fetchBySupplierCorrelationIds",
  async (
    {
      supplier_correlation_ids = [],
      limit = 20,
      offset = 0,
      q = "",
      filter = "",
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await request
        .get(
          `/api/v1/orders?sid=${supplier_correlation_ids.join(
            "&sid=",
          )}&limit=${limit}&offset=${offset}&q=${q}&filter=${filter}`,
        )
        .accept("json");
      return { meta: response.body.meta, results: response.body.results };
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const fetchBySlug = createAsyncThunk(
  "orders/fetchBySlug",
  async ({ slug }, { rejectWithValue }) => {
    try {
      const response = await request
        .get(`/api/v1/orders/${slug}`)
        .accept("json");
      return response.body;
    } catch (e) {
      //if (!e.response) {
      throw e;
      //}
      //return rejectWithValue(e.response.body);
    }
  },
);

export const confirm = createAsyncThunk(
  "orders/confirm",
  async ({ slug, accept, remarks }, { rejectWithValue }) => {
    try {
      const response = await request
        .post(`/api/v1/orders/${slug}`)
        .field("accept", accept ? "true" : "")
        .field("remarks", remarks);
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const resendNotification = createAsyncThunk(
  "orders/resendNotification",
  async ({ slug, email }, { rejectWithValue }) => {
    try {
      const response = await request
        .post(`/api/v1/orders/${slug}/notifications`)
        .field("email", email);
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const demo = createAsyncThunk(
  "orders/demo",
  async ({ email, type, lang }, { rejectWithValue }) => {
    try {
      const response = await request
        .post(`/api/v1/orders/demo`)
        .field("email", email)
        .field("type", type)
        .field("lang", lang);
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const changeItem = createAsyncThunk(
  "order/changeItem",
  async (
    {
      slug,
      item,
      price,
      quantity,
      tax,
      remarks,
      deliveryDateStart,
      supplierOrderNumber,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await request
        .put(`/api/v1/orders/${slug}/items/${item.row}`)
        .send({ price: price })
        .send({ quantity: quantity })
        .send({ tax: tax })
        .send({ remarks: remarks })
        .send({
          deliveryDateStart: !!deliveryDateStart ? deliveryDateStart : "",
        })
        .send({ supplierOrderNumber: supplierOrderNumber })
        .set("Accept", "application/json");
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const attributesOnOrderItem = createAsyncThunk(
  "order/attributesOnItem",
  async ({ slug, item, attributes = [] }, { rejectWithValue }) => {
    try {
      const response = await request
        .put(`/api/v1/orders/${slug}/items/${item.row}/attributes`)
        .send(attributes)
        .set("Accept", "application/json");
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const allowanceAndChargesOnItem = createAsyncThunk(
  "order/allowanceAndChargesOnItem",
  async ({ slug, item, allowancesAndCharges = [] }, { rejectWithValue }) => {
    try {
      const response = await request
        .put(`/api/v1/orders/${slug}/items/${item.row}/allowances_and_charges`)
        .send(allowancesAndCharges)
        .set("Accept", "application/json");
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const allowanceAndChargesOnOrder = createAsyncThunk(
  "order/allowanceAndChargesOnOrder",
  async ({ slug, allowancesAndCharges = [] }, { rejectWithValue }) => {
    try {
      const response = await request
        .put(`/api/v1/orders/${slug}/allowances_and_charges`)
        .send(allowancesAndCharges)
        .set("Accept", "application/json");
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const partialDelivery = createAsyncThunk(
  "order/partialDelivery",
  async ({ slug, item, partials = [] }, { rejectWithValue }) => {
    try {
      const response = await request
        .put(`/api/v1/orders/${slug}/items/${item.row}/partials`)
        .send(partials)
        .set("Accept", "application/json");
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const changeOrder = createAsyncThunk(
  "order/changeOrder",
  async (
    { slug, deliveryStartDate, supplierOrderNumber },
    { rejectWithValue },
  ) => {
    try {
      const response = await request
        .put(`/api/v1/orders/${slug}`)
        .field({ deliveryStartDate: deliveryStartDate })
        .field({ deliveryEndtDate: "" })
        .field({ supplierOrderNumber: supplierOrderNumber });
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const deleteUpload = createAsyncThunk(
  "order/deleteUpload",
  async ({ slug, id }, { rejectWithValue }) => {
    try {
      const response = await request
        .delete(`/api/v1/orders/${slug}/uploads/${id}`)
        .set("Accept", "application/json");
      return response.body;
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const fetchOrderNotificationsBySlug = createAsyncThunk(
  "orders/fetchOrderNotificationsBySlug",
  async ({ slug }, { dispatch, rejectWithValue }) => {
    try {
      const response = await request
        .get(`/api/v1/orders/${slug}/notifications`)
        .accept("json");
      const notifications = response.body;
      await dispatch(upsertNotifications(notifications));
      return { slug: slug, notifications: notifications };
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const fetchOrderActivitiesBySlug = createAsyncThunk(
  "orders/fetchOrderActivitiesBySlug",
  async ({ slug }, { dispatch, rejectWithValue }) => {
    try {
      const response = await request
        .get(`/api/v1/orders/${slug}/activities`)
        .accept("json");
      const activities = response.body.results;
      await dispatch(upsertActivities(activities));
      return { slug: slug, activities: activities };
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const slice = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    upsertOrder: adapter.upsertOne,
  },
  extraReducers: {
    [fetchSome.fulfilled]: (state, action) => {
      const { meta, results } = action.payload;
      state.total = meta.total;
      if (!!meta.q || !!meta.filter) {
        adapter.setAll(state, results);
      } else {
        adapter.upsertMany(state, results);
      }
    },
    [fetchBySlug.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [partialDelivery.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [allowanceAndChargesOnItem.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [allowanceAndChargesOnOrder.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [attributesOnOrderItem.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [changeItem.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [confirm.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [resendNotification.fulfilled]: (state, action) => {
      //dadapter.upsertOne(state, action.payload);
    },
    [changeOrder.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
      //adapter.updateOne(state, {id: action.payload.slug, changes: {delivery_date: {...action.payload.delivery_date}}})
    },
    [deleteUpload.fulfilled]: (state, action) => {
      adapter.upsertOne(state, action.payload);
    },
    [demo.fulfilled]: (state, action) => {
      adapter.upsertMany(state, action.payload);
    },
    [fetchOrderNotificationsBySlug.fulfilled]: (state, action) => {
      const { slug, notifications } = action.payload;
      const changes = { notifications: notifications.map((o) => o.id) };
      adapter.updateOne(state, { id: slug, changes });
    },
    [fetchOrderActivitiesBySlug.fulfilled]: (state, action) => {
      const { slug, activities } = action.payload;
      const changes = { activities: activities.map((o) => o.id) };
      adapter.updateOne(state, { id: slug, changes });
    },
  },
});

export const selectors = adapter.getSelectors((state) => state.orders);

export const selectOrderNotifications = ({ slug }) =>
  createSelector(
    (state) => state,
    ({ orders, notifications }) => {
      const order = orders.entities[slug];
      if (order.notifications === undefined) {
        return [];
      }
      return order.notifications.map((id) => notifications.entities[id]);
    },
  );

export const selectOrderActivities = ({ slug }) =>
  createSelector(
    (state) => state,
    ({ orders, activities }) => {
      const order = orders.entities[slug];
      if (order.activities === undefined) {
        return [];
      }
      return order.activities.map((id) => activities.entities[id]);
    },
  );

export const selectOrderItems = ({ slug }) =>
  createSelector(
    (state) => state,
    ({ orders }) => {
      const order = orders.entities[slug];
      if (!order) {
        return [];
      }
      return order.items;
    },
  );

export const selectOrders = () =>
  createSelector(
    (state) => state,
    ({ orders }) => {
      return {
        orders: orders.ids.map((id) => orders.entities[id]),
        total: orders.total,
      };
    },
  );

export const { upsertOrder } = slice.actions;
const reducer = slice.reducer;
export default reducer;

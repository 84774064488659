/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

import { useTranslation } from "react-i18next";
import Language from "../components/Language";

const NavTopUnauth = ({ logo, print, supplierCorrelationLink }) => {
  const { t } = useTranslation();

  return (
    <div className="row border-bottom white-bg">
      <nav
        className="navbar navbar-expand-sm navbar-static-top"
        role="navigation"
      >
        <a href={logo.href} className="navbar-brand">
          {!!logo.img && (
            <img
              src={logo.img}
              alt="Logo"
              height={26}
              style={{ verticalAlign: "unset" }}
            />
          )}
          {!!logo.img && (
            <span className="m-l-sm align-bottom">
              <small>WebConnect for Suppliers</small>
            </span>
          )}
        </a>
        <div className="navbar-collapse collapse">
          <ul className="nav navbar-nav mr-auto">
            {false && supplierCorrelationLink && (
              <li className="active">
                <a href={supplierCorrelationLink}>
                  {t("orders.overview.menu.overview")}
                </a>
              </li>
            )}
          </ul>
          <ul className="nav navbar-top-links navbar-right">
            {supplierCorrelationLink && (
              <li className="active">
                <a
                  href={supplierCorrelationLink}
                  className="btn"
                  data-toggle="tooltip"
                  title={t("common.overview")}
                >
                  <i className="fa fa-bars fa-2x text" />
                </a>
              </li>
            )}
            {print && (
              <li>
                <button
                  className="btn"
                  data-toggle="tooltip"
                  title={t("common.print")}
                  onClick={() => window.print()}
                >
                  <i className="fa fa-print fa-2x text" />
                </button>
              </li>
            )}
            <li>
              <Language />
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default NavTopUnauth;

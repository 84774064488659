const translation = {
  attributes: {
    attribute: "Attribute",
    attributes: "Attributes",
    label: "Label",
    value: "Value",
    type: "Type",
    types: {
      text: "Text",
      number: "Number",
      date: "Date",
      time: "Time",
      select: "Select",
      errors: {
        number: "Value must be a valid number",
        date: "Value must be a valid date",
        time: "Value must be a valid time",
      },
    },
  },
  activities: {
    activity: "Activities",
    order: {
      link: {
        opened: "Link for Order No. {{number}} opened",
      },
      accepted: "Order No. {{number}} has been accepted",
      rejected: "Order No. {{number}} has been rejected",
      changed: "Order No. {{number}} has been changed",
      field: {
        changed:
          "$t({{field_t_key}}) has been changed from $t({{before}}, '') $t({{suffix}}, '') to $t({{after}}, '') $t({{suffix}}, '')",
      },
      replaced:
        'Order No. {{number}} has been replaced by buyer. New version available under the following <a class="btn btn-xs btn-light" alt="link" href="{{host.url}}ui/orders/{{descendant_slug}}"><i class="fa fa-link"></i> link</a>',
      replaces:
        'Order No. {{number}} has been replaced by buyer. Previous version available under the following <a class="btn btn-xs btn-light" alt="link" href="{{host.url}}ui/orders/{{ancestor_slug}}"><i class="fa fa-link"></i> link</a>',
      canceled: "Order No. {{number}} has been canceled by buyer",
      upload: "{{filename}} has been uploaded",
      upload_delete: "{{filename}} has been deleted",
      allowances_and_charges: {
        added:
          "{{value}} {{suffix}} $t({{aoc_t_key}}) with $t({{seq_t_key}}) No. {{seq}} and $t(common.note) $t({{note}}, 'common.empty')",
      },
      item: {
        changed: "Item No. {{number}} on position {{line}} has been changed",
        field: {
          changed:
            "$t({{field_t_key}}) has been changed from $t({{before}}, '') $t({{suffix}}, '') to $t({{after}}, '') $t({{suffix}}, '')",
        },

        partial: {
          added:
            "Partial delivery changed on position $t({{pos}}, '') $t(common.note) $t({{note}}, 'common.empty') $t({{quantity}}, '') $t({{suffix}}, '') on $t({{delivery_at}}, '')",
        },
        allowances_and_charges: {
          added:
            "{{value}} {{suffix}} $t({{aoc_t_key}}) with $t({{seq_t_key}}) No. {{seq}} applied to $t({{apply_t_key}}) with $t(common.note) $t({{note}}, 'common.empty')",
        },
        attributes: {
          added: "Attribute {{label}} with value {{value}} was added",
          changed: "Attribute {{label}} with value {{value}} was changed",
          deleted: "Attribute {{label}} with value {{value}} was deleted",
        },
      },
      notification: {
        resend:
          "Order notification has been resent to recipient {{recipient}} ",
      },
    },
  },
  despatch_advices: {
    shipmentNumber: "Shipment ID",
    shipmentUrl: "Tracking URL",
    delivery_address: "Delivery",
    order_items: "Order Items",
    addresses: "Addresses",
    delete_despatch_advice: "Delete despatch advices?",
    despatch_advices: "Despatch Advices",
    despatch_advice: "Despatch Advice",
    number: "ASN Number",
    delivery_date: "Delivery date",
    ship_date: "Shipping date",
    supplier: "Supplier",
    buyer: "Buyer",
    description: "Description",
    item_number: "Item No.",
    unit: "Unit",
    quantity: "Quantity",
    create_by_items: "Create By Items",
    create_by_delivery_date: "Create By Delivery Date",
    create_empty: "Create Empty",
    item: "Item",
    items: "Items",
    create_new: "New",
    reference: "Reference",
    reference_date: "Reference date",
    help: {
      1: "Items remaining for delivery",
      2: "Items confirmed for delivery",
      3: "Quantity for this despatch advice",
    },
    permissions: {
      edit: {
        false: "This despatch advice is locked for editing",
      },
    },
  },
  orders: {
    orders: "Orders",
    orders_overview: "Orders overview",
    overview: {
      responses: "Confirmations",
      tab: {
        info: "The search field allows you to search according to order number or supplier order number. The actions allow you to view, forward, or resend the order.",
        info_auth:
          "The search field allows you to search according to order number, supplier order number or supplier name. The actions allow you to view, forward, or resend the order.",
      },
      menu: {
        overview: "Orders overview",
        all: "All",
        due: "Expired",
        pending: "Pending",
        state: "State",
        rejected: "Rejected",
        accepted: "Accepted",
        replaced: "Replaced",
        canceled: "Canceled",
        open_order: "View order",
        resent_notification: "Resend order notification",
        supplier_recipients: "Recipient",
      },
      resend_notification: "Order notification",
    },
    permissions: {
      edit: {
        false: "This order is locked for editing",
      },
    },
    changes: {
      replaced: {
        current:
          "This order has been changed by the buyer. <br/>The original order is still available in read-only access via the Activities menu.",
        ancestor: "This order has been replaced by a new order from the buyer.",
      },
      canceled: "This order has been canceled by the buyer.",
    },
    items: "Order Items",
    order: "Order",
    number: "Order No.",
    date: "Order Date",
    supplier: "Supplier",
    supplier_order_number: "Supplier Order No.",
    buyer: "Buyer",
    invoice_address: "Invoice",
    delivery_address: "Delivery",
    description: "Description",
    item_number: "Item No.",
    unit: "Unit",
    quantity: "Quantity",
    sum: "Sum",
    total: "Total",
    net: "Net",
    gross: "Gross",
    price: "Price",
    price_unit: "Price Unit",
    total_quantity: "Total Quantity",

    partial_deliveries: "Partial delivery",
    partial_delivery_at: "Partial delivery",
    partial_delivery_title:
      "Partial delivery for item in line {{line}} with number",
    item_edit_modal_header_title: "Item in line {{line}} with number",
    delivery_date: "Delivery Date",

    confirm_order: "Are you sure to confirm the order?",
    reject_order: "Are you sure to reject the order?",
    response: "Response",

    change_delivery_date:
      "Change original delivery date  {{original_start_date}}",
    change_delivery_date_hover: "Change delivery date",

    please_enter_supplier_order_number: "Please enter supplier order number",

    order_response_created:
      "Your order confirmation has been successfully created",

    notifications: {
      title: "Notifications",
      type: "Type",
      address: "Address",
      subject: "Subject",
      status: "Status",
      sent: "Sent",
      delivered: "Delivered",
      description: "Description",
      info: "Confirm or change the recipient of the order notification.",
      resend: "Are you sure you want to resend the oder notification?",
      resend_alert:
        "A new order notification for order {{number}} has been sent successfully",
    },

    demo: {
      title: "Interested in a compact solution for your ordering processes?",
      description:
        "Using an order as a sample, the WebConnect portal demonstrates how easy it is to use the e-procurement service and manage your orders within its tidy interface. Your suppliers are conveniently able to see the products that have been ordered, can leave messages, and have the ability to confirm/reject each order, or even make changes to individual positions.\n" +
        "Take a moment to see for yourself just how effortless and convenient the WebConnect portal is – the service is quick, automated, extremely easy to use, and doesn’t require a login.",

      form: {
        footer: "Username: demo, Password: demo",
        title:
          "Enter your email address* to access the live demo for Retarus WebConnect for Suppliers:",
        email: "Your email address",
      },

      ol: {
        title:
          "As part of the demonstration, three messages will be sent to your registered email address: ",
        1: "An example of an order email, illustrating how your suppliers receive orders that have been placed.",
        2: "A confirmation message, as received by your suppliers once they have edited or confirmed the order in the WebConnect portal.",
        3: "An example of a message showing the monitoring of open orders within a defined time period. In the real-world application of the service, your employees in the purchasing department would receive these emails.",
        footer: "We wish you a lot of success with WebConnect for Suppliers!",
      },

      footer: {
        title:
          "Should you have any further questions about WebConnect for Suppliers, please feel free to <0>contact us</0> at any time.",
      },

      quote: {
        1: "*You can rest assured that we use your email address exclusively for demonstrating Retarus’ WebConnect for Suppliers; it will not be saved or used for marketing purposes.",
      },

      items: {
        1: {
          unit: "Piece",
          description:
            "High performance V-belt SUPER XE-POWER PRO XPA 1180 Lw (1135 Li / 1198 La) raw edge, moulded cogged",
        },
        2: {
          unit: "Piece",
          description: "Flexible connector Open-ended V-belts Z/10",
        },
        3: {
          unit: "Piece",
          description:
            "Classic V-belts DIN2215 / ISO4184 A55 13 x 1400 Li (1430 Lw / 1450 La) wrapped; set constant (S=C PLUS)",
        },
        4: {
          unit: "Piece",
          description: "Open-ended V-belts DIN2216, punched A/13",
        },
      },
    },

    uploads: {
      click_me: "Click me to upload files",
      delete_btn: "Delete uploaded file",
    },

    vat: "VAT",
    vat_id: "VAT-ID",

    item: "Item",

    allowances_and_charges: "Allowances and Charges",
    amount: "Amount",
  },
  form: {
    message_placeholder: "Leave your message...",
    confirm: "Confirm",
    confirmed: "Confirmed",
    reject: "Reject",
    rejected: "Rejected",
    save: "Save",
    cancel: "Cancel",
    send: "Send",

    placeholder: {
      email: "Your email address",
    },
  },

  common: {
    demo: "Demo",
    from: "from",
    to: "to",

    overview: "Overview",
    actions: "Actions",
    modified: "Modified",
    reload: "Reload",
    restore: "Restore",
    advised: "Advised",
    current: "Current",
    ordered: "Ordered",
    confirmed: "Confirmed",
    delivered: "Delivered",
    not_available: "N/A",
    add: "Add",
    search: "Search",
    committed: "Committed",
    remaining: "Remaining",
    delete: "Delete",
    completed: "Completed",
    change_lang: "Change language",
    date: "Date",
    note: "Note",
    attachments: "Downloads",
    uploads: "Uploads",
    at: "at",
    print: "Print",
    imprint: "Imprint",
    privacy_policy: "Privacy Policy",
    number: "Number",
    additional: "Additional",
    original: "Original",
    value: "Value",
    empty: "Empty",
    allowance: "Allowance",
    charge: "Charge",
    subtotal: "Subtotal",

    sequence: "Sequence",
    description: "Description",
    apply: "Apply",
    type: "Type",
    per: "per",
    discount: "Discount",
    freight_charge: "Freight Charge",
    edit: "Edit",
    please_enter: "Please enter",
    ean: "EAN",
    draft: "Draft",
    number_of_decimal_places: "Number of decimal places",
    submit: "Submit",
  },

  errors: {
    not_found: "Resource not found",
    token_expired:
      "The link is no longer valid. Access the order overview again via an individual order.",
    rate_limit_reached: "Rate limit reached",
    email: "{{attr}} must correspond to valid e-mail address",
    url: "{{attr}} must correspond to valid URL",
    uuid: "{{attr}} must correspond to valid UUID",
    lowercase: "{{attr}} must be lowercase",
    uppercase: "{{attr}} must be capitalized",
    integer: "{{attr}} must be integer",
    positive: "{{attr}} must be positive",
    negative: "{{attr}} must be negative",
    matches: "{{attr}} must correspond to schema {{value}}",
    length: "{{attr}} must be {{value}} characters long",
    eq: "{{attr}} must be equal to {{value}}",
    gt: "{{attr}} must be greater than {{value}}",
    gte: "{{attr}} must be greater than or equal {{value}}",
    lte: "{{attr}} must be less than or equal {{value}}",
    valid: "Valid {{type}} required",
    required: "{{attr}} is required",
    irregular: {
      "too many files": "To many files",
    },
  },
};

export default translation;

import React from "react";
import { addBodyClass, removeBodyClass } from "../theme/helper";
import { NavLink } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { toggleMenuLeft } from "../features/navigation/ducks/slice";
import SearchForm from "./SearchForm";
import Language from "../components/Language";

const NavTopAuth = () => {
  //const [toggle, setToggle] = useState(true);

  //const me = useSelector((state) => state.auth.me);

  //const isMobile = window.innerWidth <= 768;
  const minimized = useSelector((state) => state.nav.menu.left.minimized);

  const dispatch = useDispatch();
  //const history = useHistory();

  if (minimized) {
    addBodyClass("mini-navbar");
  } else {
    removeBodyClass("mini-navbar");
  }

  return (
    <div className="row border-bottom">
      <nav
        className="navbar navbar-static-top white-bg"
        role="navigation"
        style={{ marginBottom: 0 }}
      >
        <div className="navbar-header">
          <button
            className="navbar-minimalize minimalize-styl-2 btn"
            onClick={() => dispatch(toggleMenuLeft())}
          >
            <i
              className="fa fa-bars"
              style={{ fontSize: "1.5rem", color: "#989ca2" }}
            />
          </button>
        </div>
        <ul className="nav navbar-top-links navbar-right">
          <li>
            <Language />
          </li>
          <li>
            <NavLink to={`/auth/logout`}>
              <i className="fa fa-sign-out"></i>
              <span className="d-none d-md-inline"> Log out</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavTopAuth;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React, { useState } from "react";
import PropTypes from "prop-types";

const Image = (props) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <img
        src={props.source}
        alt={props.alternative}
        className={props.className}
        onLoad={() => setShow(true)}
        onError={() => setShow(false)}
        {...props}
        style={{ ...props.style, display: !show ? "none" : undefined }}
      />
    </>
  );
};

Image.propTypes = {
  source: PropTypes.string.isRequired,
};

export default Image;

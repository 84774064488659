/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import Field from "../../components/Field";
import { useTranslation, Trans } from "react-i18next";
import { confirm, demo } from "./ducks/slice";
import { useDispatch } from "react-redux";
import avatar from "../../assets/avatar.png";
import communicationLogo from "../../assets/icon_communication.png";
import { usePageClass } from "../../theme/helper";
import retarusLogo from "../../logo.png";

const initialValues = {
  email: "",
  error: null,
};

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

export default function DespatchAdviceDemo({ onSetLogo }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();

  usePageClass(["order-demo-gradient"]);

  useEffect(() => {
    onSetLogo({ href: null, img: retarusLogo });
  }, []);

  const handleSubmit = async (values, { setErrors }) => {
    const resultAction = await dispatch(
      demo({
        ...values,
        lang:
          !!i18n.language && i18n.language.length >= 2
            ? i18n.language.substring(0, 2)
            : "en",
      })
    );
    if (demo.fulfilled.match(resultAction)) {
      history.push(`/despatch_advices/${resultAction.payload.slug}`);
    } else {
      if (resultAction.payload) {
        setErrors(resultAction.payload.field_errors);
      }
    }
  };

  return (
    <div className="container p-md">
      <div className="row">
        <div className="col">
          <h1 className="display-4 font-weight-bolder">
            {t(`despatch_advices.demo.title`)}
          </h1>
          <div className="m-t-xl">
            <p style={{ fontSize: "1.35em", lineHeight: "normal" }}>
              {t(`despatch_advices.demo.description`)}
            </p>
          </div>
        </div>
      </div>

      <div className="row m-t-sm">
        <div className="col">
          <div className="ibox p-xl m-n">
            <div
              className="ibox-title text-center"
              style={{ padding: "0px 0px 1px", backgroundColor: "#445063" }}
            >
              <h1 className="font-weight-bolder text-white p-xl">
                {t(`despatch_advices.demo.form.title`)}
              </h1>
            </div>
            <div
              className="ibox-content p-xl"
              style={{ backgroundColor: "#445063" }}
            >
              <Formik
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  submitForm,
                  setFieldValue,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form>
                    <Field
                      error={errors.error}
                      htmlFor="email"
                      placeholder={`${t("despatch_advices.demo.form.email")}`}
                      className="m-t-md"
                    />

                    <div className="text-center">
                      <button
                        className="p-sm btn btn-lg btn-danger"
                        type="submit"
                        style={{ borderRadius: "0px" }}
                      >
                        <span className="font-bold p-md">
                          {t("common.submit")}
                        </span>
                      </button>
                    </div>
                    {false && (
                      <pre
                        className="m-t-xl"
                        style={{
                          fontSize: "1rem",
                          padding: ".25rem .5rem",
                          overflowX: "scroll",
                        }}
                      >
                        VALUES: {JSON.stringify(values, null, 2)}
                        <br />
                        ERRORS: {JSON.stringify(errors, null, 2)}
                      </pre>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <div className="row m-t-xs">
        <div className="col">
          <h2 className="font-weight-bolder">
            {t(`despatch_advices.demo.ol.title`)}
          </h2>
          <div
            className="font-weight-bolder m-t-xl"
            style={{ fontSize: "1.3em", lineHeight: "normal" }}
          >
            <div className="row m-t-lg">
              <div className="col-md-1 font-bold m-r-md">
                <div
                  style={{
                    backgroundColor: "#3f4c60",
                    color: "#ffffff",
                    padding: "10px 30px",
                  }}
                >
                  1
                </div>
              </div>
              <div className="col">{t(`despatch_advices.demo.ol.1`)}</div>
            </div>
            <div className="row m-t-lg">
              <div className="col-md-1 font-bold m-r-md">
                <div
                  style={{
                    backgroundColor: "#3f4c60",
                    color: "#ffffff",
                    padding: "10px 30px",
                  }}
                >
                  2
                </div>
              </div>
              <div className="col">{t(`despatch_advices.demo.ol.2`)}</div>
            </div>
            <div className="row m-t-lg">
              <div className="col-md-1 font-bold m-r-md">
                <div
                  style={{
                    backgroundColor: "#3f4c60",
                    color: "#ffffff",
                    padding: "10px 30px",
                  }}
                >
                  3
                </div>
              </div>
              <div className="col">{t(`despatch_advices.demo.ol.3`)}</div>
            </div>
          </div>
          <h2 className="font-weight-bolder m-t-xl">
            {t(`despatch_advices.demo.ol.footer`)}
          </h2>
        </div>
      </div>

      <div className="row m-t-xl">
        <div className="col-3 text-center">
          {i18n.language === "de" ? (
            <img src={avatar} alt="Ferdinand Teuber" className="img-fluid" />
          ) : (
            <img
              src={communicationLogo}
              alt="Contact us"
              className="img-fluid"
            />
          )}
        </div>
        <div className="col-7 m-l-xl">
          <h2 className="font-weight-bolder">
            <Trans i18nKey="orders.demo.footer.title">
              <a href="https://www.retarus.com/contact/">contact us</a>
            </Trans>
          </h2>
          {i18n.language === "de" && (
            <address
              style={{ fontSize: "1.3em", lineHeight: "1.3em" }}
              className="m-t-md"
            >
              <b>Ferdinand Teuber</b>
              <br />
              Director Sales, Business Integration
              <br />
              <a
                href="mailto:ferdinand.teuber@retarus.de"
                className="text-dark"
              >
                ferdinand.teuber@retarus.de
              </a>
              <br />
              <a href="tel:+498955281128" className="text-dark">
                +49 89 5528-1128
              </a>
              <br />
            </address>
          )}
        </div>
      </div>
      <div className="row m-t-xl">
        <div className="col">
          <p className="font-weight-lighter">
            {t(`despatch_advices.demo.quote.1`)}
          </p>
        </div>
      </div>
    </div>
  );
}

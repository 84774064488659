/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { upsertNotifications } from "../../notifications/ducks/slice";
import request from "../../../requests";
import { upsertActivities } from "../../activities/ducks/slice";

const adapter = createEntityAdapter({
  selectId: (order) => order.id,
});
const initialState = adapter.getInitialState({
  total: 0,
});

export const FILTERS = Object.freeze({
  PENDING: "pending",
  DUE: "due",
  REJECTED: "rejected",
  ACCEPTED: "accepted",
  ALL: "all",
});

export const fetchSome = createAsyncThunk(
  "auth/orders/fetchSome",
  async (
    { limit = 20, offset = 0, q = "", filter = "" },
    { rejectWithValue },
  ) => {
    try {
      const response = await request
        .get(
          `/api/v1/auth/orders?limit=${limit}&offset=${offset}&q=${q}&filter=${filter}`,
        )
        .accept("json");
      return { meta: response.body.meta, results: response.body.results };
    } catch (e) {
      if (!e.response) {
        throw e;
      }
      return rejectWithValue(e.response.body);
    }
  },
);

export const slice = createSlice({
  name: "orders_auth",
  initialState: initialState,
  reducers: {
    upsertOrder: adapter.upsertOne,
  },
  extraReducers: {
    [fetchSome.fulfilled]: (state, action) => {
      const { meta, results } = action.payload;
      state.total = meta.total;
      if (!!meta.q || !!meta.filter) {
        adapter.setAll(state, results);
      } else {
        adapter.upsertMany(state, results);
      }
    },
  },
});

export const selectors = adapter.getSelectors((state) => state.orders_auth);

export const selectOrders = () =>
  createSelector(
    (state) => state,
    ({ orders_auth }) => {
      return {
        orders: orders_auth.ids.map((id) => orders_auth.entities[id]),
        total: orders_auth.total,
      };
    },
  );

export const { upsertOrder } = slice.actions;
const reducer = slice.reducer;
export default reducer;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

import { Formik, Form, FieldArray, Field, getIn, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import { parse } from "date-fns";

import { DEV_MODE } from "../../constants";
import { ModalFooter } from "../../layout/Modal";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import { attributesOnOrderItem } from "./ducks/slice";

const createInitialValues = (attributes, t) => ({
  attributes: attributes.map((attr) => ({
    ...attr,
    deleted: !!attr.deleted_at,
    //value: attr.data?.multiple ? attr.data.values : attr.data.values[0],
    type_t: t(`attributes.types.${attr.type}`),
  })),
  error: null,
});

const createValidationSchema = (t) =>
  Yup.object().shape({
    attributes: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required(
          t("errors.required", { attr: t("attributes.label") }),
        ),
        type: Yup.string()
          .oneOf(["text", "number", "date", "time", "select"])
          .required(),
        pending: Yup.bool(),
        deleted: Yup.bool(),
        data: Yup.object().when(["type", "deleted"], (type, deleted) =>
          Yup.object().shape({
            choices: Yup.array().of(Yup.string()),
            multiple: Yup.bool().nullable(),
            validators: Yup.object().shape({
              required: Yup.bool().nullable(),
              min: Yup.mixed().nullable(),
              max: Yup.mixed().nullable(),
              length: Yup.number().nullable(),
              matches: Yup.string().nullable(),
              email: Yup.bool().nullable(),
              url: Yup.bool().nullable(),
              uuid: Yup.bool().nullable(),
              lowercase: Yup.bool().nullable(),
              uppercase: Yup.bool().nullable(),
              //less_than: Yup.number().nullable(),
              //more_than: Yup.number().nullable(),
              positive: Yup.bool().nullable(),
              negative: Yup.bool().nullable(),
              integer: Yup.bool().nullable(),
            }),
            values: Yup.array().when(
              ["choices", "multiple", "validators"],
              (choices, multiple, validators) => {
                let validateStrict = false;
                let arrayMin = null;
                let arrayMax = null;

                let value = null;
                switch (type) {
                  case "number":
                    value = Yup.number().typeError(
                      t("attributes.types.errors.number"),
                    );

                    if (!!validators.integer) {
                      value = value.integer(
                        t("errors.integer", { attr: t("attributes.value") }),
                      );
                    }
                    if (!!validators.positive) {
                      value = value.positive(
                        t("errors.positive", { attr: t("attributes.value") }),
                      );
                    } else if (!!validators.negative) {
                      value = value.negative(
                        t("errors.negative", { attr: t("attributes.value") }),
                      );
                    }

                    if (Number.isFinite(validators.min)) {
                      value = value.min(
                        validators.min,
                        t("errors.gte", {
                          attr: t("attributes.value"),
                          value: validators.min,
                        }),
                      );
                    }

                    if (Number.isFinite(validators.max)) {
                      value = value.max(
                        validators.max,
                        t("errors.lte", {
                          attr: t("attributes.value"),
                          value: validators.max,
                        }),
                      );
                    }
                    break;

                  case "select":
                    value = Yup.string();
                    if (!multiple) {
                      value = value.oneOf(choices);
                    }

                    if (Number.isFinite(validators.min)) {
                      // store this for later init
                      arrayMin = validators.min;
                    }

                    if (Number.isFinite(validators.max)) {
                      // store this for later init
                      arrayMax = validators.max;
                    }

                    break;

                  case "text":
                    value = Yup.string();
                    if (!!validators.lowercase) {
                      value = value.lowercase(
                        t("errors.lowercase", { attr: t("attributes.value") }),
                      );
                      validateStrict = true;
                    } else if (!!validators.uppercase) {
                      value = value.uppercase(
                        t("errors.uppercase", { attr: t("attributes.value") }),
                      );
                      validateStrict = true;
                    }

                    if (!!validators.email) {
                      value = value.email(
                        t("errors.email", { attr: t("attributes.value") }),
                      );
                    } else if (!!validators.uuid) {
                      value = value.uuid(
                        t("errors.uuid", { attr: t("attributes.value") }),
                      );
                    } else if (!!validators.url) {
                      value = value.url(
                        t("errors.url", { attr: t("attributes.value") }),
                      );
                    } else if (
                      !!validators.matches &&
                      validators.matches.length > 0
                    ) {
                      value = value.matches(new RegExp(validators.matches), {
                        message: t("errors.matches", {
                          attr: t("attributes.value"),
                          value: validators.matches,
                        }),
                        excludeEmptyString: true,
                      });
                    }

                    if (
                      Number.isInteger(validators.length) &&
                      validators.length > 0
                    ) {
                      value = value.length(
                        validators.length,
                        t("errors.length", {
                          attr: t("attributes.value"),
                          value: validators.length,
                        }),
                      );
                    } else {
                      if (Number.isFinite(validators.min)) {
                        value = value.min(
                          validators.min,
                          t("errors.gte", {
                            attr: t("attributes.value"),
                            value: validators.min,
                          }),
                        );
                      }

                      if (Number.isFinite(validators.max)) {
                        value = value.max(
                          validators.max,
                          t("errors.lte", {
                            attr: t("attributes.value"),
                            value: validators.max,
                          }),
                        );
                      }
                    }
                    break;
                  case "date":
                    value = Yup.date().typeError(
                      t("attributes.types.errors.date"),
                    );

                    if (!!validators.min) {
                      value = value.min(
                        validators.min,
                        t("errors.gte", {
                          attr: t("attributes.value"),
                          value: parse(
                            validators.min,
                            "yyyy-mm-dd",
                            new Date(),
                          ),
                        }),
                      );
                    }

                    if (!!validators.max) {
                      value = value.max(
                        validators.max,
                        t("errors.lte", {
                          attr: t("attributes.value"),
                          value: parse(
                            validators.max,
                            "yyyy-mm-dd",
                            new Date(),
                          ),
                        }),
                      );
                    }

                    break;
                  case "time":
                    value = Yup.string();
                    break;
                  default:
                    value = Yup.string();
                }

                if (deleted) {
                  value = value.nullable().optional();
                } else {
                  if (!!validators.required) {
                    value = value.required(
                      t("errors.required", { attr: t("attributes.value") }),
                    );
                  }
                }

                let array = Yup.array(value);

                if (Number.isFinite(arrayMin)) {
                  array = array.min(
                    arrayMin,
                    t("errors.gte", {
                      attr: !multiple
                        ? t("attributes.value")
                        : t("attributes.types.select"),
                      value: arrayMin,
                    }),
                  );
                }

                if (Number.isFinite(arrayMax)) {
                  array = array.max(
                    arrayMax,
                    t("errors.lte", {
                      attr: !multiple
                        ? t("attributes.value")
                        : t("attributes.types.select"),
                      value: arrayMax,
                    }),
                  );
                }

                if (validateStrict) {
                  array = array.strict();
                }

                return array;
              },
            ),
          }),
        ),
      }),
    ),
  });

function OrderItemAttributesTab({ order, item, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (values, { setErrors }) => {
    const resultAction = await dispatch(
      attributesOnOrderItem({
        slug: order.slug,
        item: item,
        attributes: values.attributes.map((attr) => ({
          ...attr,
          data: {
            ...attr.data,
            // convert it back to values list if single value expected
            //values: Array.isArray(attr.value) ? attr.value : [attr.value],
          },
        })),
      }),
    );
    if (attributesOnOrderItem.fulfilled.match(resultAction)) {
      onClose();
    } else {
      if (resultAction.payload) {
        setErrors({
          error: !!resultAction.payload.t
            ? t(resultAction.payload.t.id, {
                attr: t(resultAction.payload.t.args.attr),
                value: resultAction.payload.t.args.value,
              })
            : resultAction.payload.message,
        });
      }
    }
  };

  //const attributes = item.attributes;

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={false}
      enableReinitialize={false}
      initialValues={createInitialValues(item.attributes.current, t)}
      onSubmit={handleSubmit}
      validationSchema={createValidationSchema(t)}
    >
      {({
        values,
        errors,
        // touched,
        // handleChange,
        // handleBlur,
        // handleSubmit,
        // submitForm,
        // resetForm,
        setFieldValue,
        setValues,
        // isSubmitting,
        isValid,
      }) => (
        <Form>
          <ModalBody>
            <FieldArray
              name="attributes"
              render={(arrayHelpers) => (
                <div className="container">
                  <div className="row">
                    <div className="p-1 col-1">
                      <button
                        className="btn"
                        style={{ paddingTop: "0", paddingBottom: "0" }}
                        type={"button"}
                        data-toggle="tooltip"
                        title={t("common.reload")}
                        onClick={() =>
                          setValues(
                            createInitialValues(item.attributes.original, t),
                            false,
                          )
                        }
                      >
                        <i className="fa fa-refresh text-warning" />
                      </button>
                    </div>
                    <div className="p-1 col-2 text-left">
                      {t("attributes.label")}
                    </div>
                    <div className="p-1 col-2 text-left">
                      {t("attributes.type")}
                    </div>
                    <div className="p-1 col text-left">
                      {t("attributes.value")}
                    </div>
                  </div>
                  {values.attributes.map((attr, index) => (
                    <div className="row" key={index}>
                      <div className="p-1 col-1">
                        {attr.data.permissions.delete && (
                          <button
                            title={
                              values.attributes[index].deleted
                                ? t("common.restore")
                                : t("common.delete")
                            }
                            data-toggle="tooltip"
                            className="btn"
                            type={"button"}
                            disabled={
                              !attr.data.permissions.delete ||
                              // disable delete if item is one of xml and not valid
                              (!!getIn(
                                errors,
                                `attributes.${index}.data.values`,
                              ) &&
                                index < item.attributes.original.length)
                            }
                            //onClick={() => arrayHelpers.remove(index)}
                            onClick={() =>
                              index < item.attributes.original.length
                                ? setFieldValue(
                                    `attributes.${index}.deleted`,
                                    !values.attributes[index].deleted,
                                  )
                                : arrayHelpers.remove(index)
                            }
                          >
                            {values.attributes[index].deleted ? (
                              <i className={`fa fa-plus text-navy`} />
                            ) : index < item.attributes.original.length ? (
                              <i
                                className={`fa fa-remove text-${
                                  attr.data.permissions.delete
                                    ? "danger"
                                    : "gray"
                                }`}
                              />
                            ) : (
                              <i
                                className={`fa fa-trash text-${
                                  attr.data.permissions.delete
                                    ? "danger"
                                    : "gray"
                                }`}
                              />
                            )}
                          </button>
                        )}

                        <button
                          className="btn"
                          style={{ paddingTop: "0", paddingBottom: "0" }}
                          type={"button"}
                          data-toggle="tooltip"
                          title={t("common.reload")}
                          disabled={values.attributes[index].deleted}
                          onClick={() => {
                            setFieldValue(
                              `attributes.${index}.label`,
                              index < item.attributes.original.length
                                ? item.attributes.original[index].label
                                : "",
                            );
                            setFieldValue(
                              `attributes.${index}.data.values`,
                              index < item.attributes.original.length
                                ? item.attributes.original[index].data.values
                                : "",
                            );
                          }}
                        >
                          <i className="fa fa-refresh text-warning" />
                        </button>
                      </div>
                      <div className="p-1 col-2">
                        <Field
                          name={`attributes.${index}.label`}
                          placeholder={`${t("attributes.label")}...`}
                          disabled={
                            attr.deleted
                              ? true
                              : attr.data?.permissions?.edit_label !== undefined
                              ? !attr.data.permissions.edit_label
                              : false
                          }
                          value={order.is_demo ? t(attr.label) : attr.label}
                          className={`form-control ${
                            getIn(errors, `attributes.${index}.label`)
                              ? "is-invalid"
                              : ""
                          }`}
                        />
                        <ErrorMessage
                          name={`attributes.${index}.label`}
                          render={(msg) => (
                            <div className="order-last invalid-feedback text-left">
                              {msg}
                            </div>
                          )}
                        />
                      </div>
                      <div className="p-1 col-2">
                        {attr.pending ? (
                          <select
                            className="form-control"
                            onChange={(e) =>
                              setFieldValue(
                                `attributes.${index}.type`,
                                e.target.value,
                              )
                            }
                            value={values.attributes[index].type}
                          >
                            <option value="text">
                              {t("attributes.types.text")}
                            </option>
                            <option value="number">
                              {t("attributes.types.number")}
                            </option>
                            <option value="date">
                              {t("attributes.types.date")}
                            </option>
                            <option value="time">
                              {t("attributes.types.time")}
                            </option>
                          </select>
                        ) : (
                          <Field
                            value={attr.type_t}
                            disabled={true}
                            className="form-control"
                          />
                        )}
                      </div>
                      <div className="p-1 col">
                        {attr.type !== "select" ? (
                          <Field
                            as="input"
                            // we use text for numbers to ensure common handling control
                            type={attr.type === "number" ? "text" : attr.type}
                            name={`attributes.${index}.data.values`}
                            onChange={(e) =>
                              setFieldValue(
                                `attributes.${index}.data.values`,
                                !!values.attributes[index].data?.multiple
                                  ? [...e.target.options]
                                      .filter((o) => o.selected)
                                      .map((o) => o.value)
                                  : [e.target.value],
                              )
                            }
                            value={values.attributes[index].data.values}
                            disabled={
                              attr.deleted
                                ? true
                                : attr.data?.permissions?.edit_values !==
                                  undefined
                                ? !attr.data.permissions.edit_values
                                : false
                            }
                            className={`form-control ${
                              getIn(errors, `attributes.${index}.data.values`)
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                        ) : (
                          <Field
                            as="select"
                            name={`attributes.${index}.data.values`}
                            onChange={(e) =>
                              setFieldValue(
                                `attributes.${index}.data.values`,
                                !!values.attributes[index].data?.multiple
                                  ? [...e.target.options]
                                      .filter((o) => o.selected)
                                      .map((o) => o.value)
                                  : [e.target.value],
                              )
                            }
                            value={
                              !!values.attributes[index].data?.multiple
                                ? values.attributes[index].data.values
                                : values.attributes[index].data.values[0]
                            }
                            disabled={
                              attr.deleted
                                ? true
                                : attr.data?.permissions?.edit_values !==
                                  undefined
                                ? !attr.data.permissions.edit_values
                                : false
                            }
                            multiple={!!values.attributes[index].data?.multiple}
                            className={`form-control ${
                              getIn(errors, `attributes.${index}.data.values`)
                                ? "is-invalid"
                                : ""
                            }`}
                          >
                            {values.attributes[index].data.choices.map((c) => (
                              <option key={c} value={c}>
                                {c}
                              </option>
                            ))}
                          </Field>
                        )}
                        <ErrorMessage
                          name={`attributes.${index}.data.values`}
                          render={(msg) => (
                            <div className="order-last invalid-feedback text-left">
                              {msg}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  ))}
                  {item.attributes_add_allowed && (
                    <div className="row">
                      <div className="p-1 col-1">
                        <button
                          className="btn"
                          type={"button"}
                          title={t("common.add")}
                          data-toggle="tooltip"
                          onClick={() =>
                            arrayHelpers.push({
                              id: Date.now().toString(),
                              label: "",
                              type: "text",
                              pending: true,
                              deleted: false,
                              data: {
                                value: "",
                                validators: {
                                  required: true,
                                },
                                highlight: false,
                                permissions: {
                                  delete: true,
                                  edit_label: true,
                                  edit_value: true,
                                },
                              },
                            })
                          }
                        >
                          <i className="fa fa-plus text-navy" />
                        </button>
                      </div>
                      <div className="p-1 col-2">
                        <Field
                          value=""
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                      <div className="p-1 col-2">
                        <Field
                          value=""
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                      <div className="p-1 col">
                        <Field
                          value=""
                          disabled={true}
                          className="form-control"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            />
            {DEV_MODE && (
              <pre
                className="m-t-md"
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}
            {!!errors.error && (
              <div className="alert alert-danger m-t-sm">{errors.error}</div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="btn-group">
              <button
                className="btn btn-primary"
                type={"submit"}
                disabled={!isValid}
              >
                <i className="fa fa-save" /> {t("form.save")}
              </button>
              <button className="btn btn-danger" onClick={() => onClose()}>
                <i className="fa fa-close" /> {t("form.cancel")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

export default OrderItemAttributesTab;

/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import React from "react";

import { useTranslation } from "react-i18next";

import Modal, { ModalHeader } from "../../layout/Modal";
import { Tabs, Tab } from "react-bootstrap";

//import OrderEditTab from "./OrderEditTab";
import OrderAllowancesAndChargesTab from "./OrderAllowancesAndChargesTab";
import OrderEditTab from "./OrderEditTab";
import OrderItemsTab from "./OrderItemsTab";
import OrderNotificationResendTab from "./OrderNotificationResendTab";

export const TABS = {
  RESEND: "resend",
};

function OrdersModal({ order, onClose }) {
  const { t } = useTranslation();

  return (
    <Modal>
      <ModalHeader style={{ borderBottom: "5px", padding: "15px" }}>
        <button type="button" className="close" onClick={onClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div>
          {t("orders.order")}:{" "}
          <h4 style={{ display: "inline" }}>{order.number}</h4>
        </div>
      </ModalHeader>
      <Tabs defaultActiveKey={TABS.RESEND} id="order" transition={false}>
        <Tab
          eventKey={TABS.RESEND}
          title={t("orders.overview.resend_notification")}
        >
          <OrderNotificationResendTab order={order} onClose={onClose} />
        </Tab>
      </Tabs>
    </Modal>
  );
}

export default OrdersModal;

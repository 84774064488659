/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */
import React, { useEffect } from "react";

import { Formik, Form, FieldArray, Field, getIn, ErrorMessage } from "formik";
import { startOfDay, addDays, formatISO, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import Datepicker from "../../components/Datepicker";
import { DEV_MODE } from "../../constants";
import Modal, { ModalFooter, ModalHeader } from "../../layout/Modal";
import { ModalBody } from "react-bootstrap";
import { useDispatch } from "react-redux";
//import { changeDesadv } from "./ducks/slice";
import { formatNumber } from "../../translations/intl";
import { changeDesadv } from "./ducks/slice";

const createInitialValues = (desadv) => ({
  deliveryDateStart: !!desadv.delivery_date_start
    ? parseISO(desadv.delivery_date_start.iso)
    : "",
  shipDateStart: !!desadv.shipment.ship_date_start
    ? parseISO(desadv.shipment.ship_date_start.iso)
    : "",
  number: !!desadv.number ? desadv.number : "",
  shipmentNumber: !!desadv.shipment.number ? desadv.shipment.number : "",
  shipmentUrl: !!desadv.shipment.url ? desadv.shipment.url : "",
  orderNumber: !!desadv.order_number ? desadv.order_number : "",
  orderDate: !!desadv.order_date ? parseISO(desadv.order_date.iso) : "",
  reference: !!desadv.reference ? desadv.reference : "",
  referenceDate: !!desadv.reference_date
    ? parseISO(desadv.reference_date.iso)
    : "",

  error: null,
});

const createValidateSchema = (desadv, t) =>
  Yup.object().shape({
    number: Yup.string()
      .trim()
      .max(
        64,
        t("errors.lte", {
          attr: t("despatch_advices.number"),
          value: 64,
        }),
      )
      .required(
        t("errors.required", {
          attr: t("despatch_advices.number"),
        }),
      ),
    reference: Yup.string()
      .trim()
      .max(
        2048,
        t("errors.lte", {
          attr: t("despatch_advices.reference"),
          value: 2048,
        }),
      ),
    shipmentNumber: Yup.string()
      .trim()
      .max(
        256,
        t("errors.lte", {
          attr: t("despatch_advices.shipmentNumber"),
          value: 256,
        }),
      ),
    shipmentUrl: Yup.string()
      .trim()
      .max(
        256,
        t("errors.lte", {
          attr: t("despatch_advices.shipmentUrl"),
          value: 256,
        }),
      ),
  });

export default function DespatchAdviceEditTab({ desadv, onClose }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSubmit = async (values, { setErrors }) => {
    const resultAction = await dispatch(
      changeDesadv({
        slug: desadv.slug,
        number: values.number,
        deliveryDateStart: !!values.deliveryDateStart
          ? formatISO(values.deliveryDateStart, {
              representation: "date",
            })
          : "",
        shipDateStart: !!values.shipDateStart
          ? formatISO(values.shipDateStart, {
              representation: "date",
            })
          : "",
        shipmentNumber: values.shipmentNumber,
        shipmentUrl: values.shipmentUrl,
        orderNumber: values.orderNumber,

        orderDate: !!values.orderDate
          ? formatISO(values.orderDate, {
              representation: "date",
            })
          : "",
        reference: values.reference,
        referenceDate: !!values.referenceDate
          ? formatISO(values.referenceDate, {
              representation: "date",
            })
          : "",
      }),
    );
    if (changeDesadv.fulfilled.match(resultAction)) {
      onClose();
    } else {
      if (resultAction.payload) {
        setErrors({ error: resultAction.payload.error });
      }
    }
  };

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      initialValues={createInitialValues(desadv)}
      onSubmit={handleSubmit}
      validationSchema={createValidateSchema(desadv, t)}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        submitForm,
        setFieldValue,
        isSubmitting,
      }) => (
        <Form>
          <ModalBody>
            <div className="container">
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  <span className="text-danger">*</span>{" "}
                  {t("despatch_advices.number")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={"input-group " + (errors.number && "has-error")}
                  >
                    <Field
                      name="number"
                      className={`form-control ${
                        errors.number ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="number"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("despatch_advices.shipmentNumber")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={
                      "input-group " + (errors.shipmentNumber && "has-error")
                    }
                  >
                    <Field
                      name="shipmentNumber"
                      className={`form-control ${
                        errors.shipmentNumber ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="shipmentNumber"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("orders.number")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={
                      "input-group " + (errors.orderNumber && "has-error")
                    }
                  >
                    <Field
                      name="orderNumber"
                      disabled={!!desadv.order}
                      className={`form-control ${
                        errors.orderNumber ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="reference"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("orders.date")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <Datepicker
                    htmlFor="orderDate"
                    disabled={!!desadv.order}
                    selected={values.orderDate}
                    //showWeekNumbers
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    onChange={(date) => setFieldValue("orderDate", date)}
                    className={`form-control ${
                      !!errors.orderDate ? "is-invalid" : ""
                    }`}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("despatch_advices.delivery_date")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <Datepicker
                    htmlFor="deliveryDateStart"
                    selected={values.deliveryDateStart}
                    //showWeekNumbers
                    dateFormat="yyyy-MM-dd"
                    minDate={values.shipDateStart || new Date()}
                    onChange={(date) =>
                      setFieldValue("deliveryDateStart", date)
                    }
                    className={`form-control ${
                      !!errors.deliveryDateStart ? "is-invalid" : ""
                    }`}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("despatch_advices.ship_date")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <Datepicker
                    htmlFor="shipDateStart"
                    selected={values.shipDateStart}
                    //showWeekNumbers
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    onChange={(date) => setFieldValue("shipDateStart", date)}
                    className={`form-control ${
                      !!errors.shipDateStart ? "is-invalid" : ""
                    }`}
                  />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("despatch_advices.reference")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={
                      "input-group " + (errors.reference && "has-error")
                    }
                  >
                    <Field
                      name="reference"
                      className={`form-control ${
                        errors.reference ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="reference"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("despatch_advices.reference_date")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <Datepicker
                    htmlFor="referenceDate"
                    selected={values.referenceDate}
                    //showWeekNumbers
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    onChange={(date) => setFieldValue("referenceDate", date)}
                    className={`form-control ${
                      !!errors.referenceDate ? "is-invalid" : ""
                    }`}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-2 offset-lg-3 text-right mb-auto mt-auto">
                  {t("despatch_advices.shipmentUrl")}
                </div>
                <div className="col-6 col-lg-4 text-left">
                  <div
                    className={
                      "input-group " + (errors.shipmentUrl && "has-error")
                    }
                  >
                    <Field
                      name="shipmentUrl"
                      className={`form-control ${
                        errors.shipmentUrl ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="shipmentUrl"
                      render={(msg) => (
                        <div className="order-last invalid-feedback">{msg}</div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            {DEV_MODE && (
              <pre
                style={{
                  fontSize: "1rem",
                  padding: ".25rem .5rem",
                  overflowX: "scroll",
                }}
              >
                VALUES: {JSON.stringify(values, null, 2)}
                <br />
                ERRORS: {JSON.stringify(errors, null, 2)}
              </pre>
            )}

            {!!errors.error && (
              <div className="alert alert-danger m-t-sm">{errors.error}</div>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="btn-group">
              <button className="btn btn-primary" type={"submit"}>
                <i className="fa fa-save" /> {t("form.save")}
              </button>
              <button className="btn btn-danger" onClick={() => onClose()}>
                <i className="fa fa-close" /> {t("form.cancel")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
}

import request from "superagent";

for (let k of ["post", "put", "patch", "delete"]) {
  let f = request[k];
  request[k] = function () {
    if (window._csrf !== undefined && window._csrf !== "{{ csrf_token() }}") {
      return f.apply(this, arguments).set("X-CSRFToken", window._csrf);
    } else {
      return f.apply(this, arguments);
    }
  };
}

export default request;

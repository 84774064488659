/*
 * author = "Reimund Klain"
 * email = "reimund.klain@condevtec.de"
 */

import { t } from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";

import {
  fetchOrderActivitiesBySlug,
  selectOrderActivities,
} from "./ducks/slice";

function OrderActivity() {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const activities = useSelector(selectOrderActivities({ slug: slug }));

  useEffect(() => {
    dispatch(fetchOrderActivitiesBySlug({ slug }));
    const id = setInterval(
      () => dispatch(fetchOrderActivitiesBySlug({ slug })),
      5000,
    );
    return () => clearInterval(id);
  }, []);

  const rows = activities.map((a, i) => (
    <div key={a.id} className="stream">
      <div className="stream-badge">
        <i
          className={`fa fa-${!!a.gui.icon ? a.gui.icon : "info-circle"} bg-${
            !!a.gui.category ? a.gui.category : "default"
          }`}
        ></i>
      </div>
      <div className="stream-panel">
        <div className="stream-info">
          <div>
            <i
              className="fa fa-clock-o"
              data-toggle="tooltip"
              title={a.at.fmt}
            />
            <span className="m-l-xs">{a.at.fmt}</span>
            {a.remarks.length > 0 && a.remarks[0].t_args.address && (
              <>
                <i
                  className="fa fa-wifi m-l-sm"
                  data-toggle="tooltip"
                  title={a.at.fmt}
                />
                <span className="m-l-xs">{a.remarks[0].t_args.address}</span>
              </>
            )}
            {a.gui.labels.map((l, i) => (
              <span key={i} className="badge badge-default badge-sm m-l-sm">
                {t(l)}
              </span>
            ))}

            {/*<span>{ t(a.name) }</span>*/}
            {/*<span className="date">{ a.at.iso }</span>*/}
          </div>
        </div>
        <ul className="list-unstyled m-t-xs">
          {a.remarks.map((r) => (
            <li key={r.id}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t(r.t_key, { ...r.t_args, ...r.t_ctx }),
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  ));

  return (
    <div className="activity-stream">
      {rows}
      {/*<div className="stream">
        <div className="stream-badge">
          <i className="fa fa-pencil"></i>
        </div>
        <div className="stream-panel">
          <div className="stream-info">
            <a href="#">
              <span>Karen Miggel</span>
              <span className="date">Today at 01:32:40 am</span>
            </a>
          </div>
          Add new note to the <a href="#">Martex</a> project.
        </div>
      </div>

      <div className="stream">
        <div className="stream-badge">
          <i className="fa fa-commenting-o"></i>
        </div>
        <div className="stream-panel">
          <div className="stream-info">
            <a href="#">
              <span>John Mikkens</span>
              <span className="date">Yesterday at 10:00:20 am</span>
            </a>
          </div>
          Commented on <a href="#">Ariana</a> profile.
        </div>
      </div>
      <div className="stream">
        <div className="stream-badge">
          <i className="fa fa-circle"></i>
        </div>
        <div className="stream-panel">
          <div className="stream-info">
            <a href="#">
              <span>Mike Johnson, Monica Smith and Karen Dortmund</span>
              <span className="date">Yesterday at 02:13:20 am</span>
            </a>
          </div>
          Changed status of third stage in the <a href="#">Vertex</a> project.
        </div>
      </div>
      <div className="stream">
        <div className="stream-badge">
          <i className="fa fa-circle"></i>
        </div>
        <div className="stream-panel">
          <div className="stream-info">
            <a href="#">
              <span>Jessica Smith</span>
              <span className="date">Yesterday at 08:14:41 am</span>
            </a>
          </div>
          Add new files to own file sharing place.
        </div>
      </div>
      <div className="stream">
        <div className="stream-badge">
          <i className="fa fa-send bg-primary"></i>
        </div>
        <div className="stream-panel">
          <div className="stream-info">
            <a href="#">
              <span>Martha Farter and Mike Rodgers</span>
              <span className="date">Yesterday at 04:18:13 am</span>
            </a>
          </div>
          Sent email to all users participating in new project.
        </div>
      </div>
  */}
    </div>
  );
}

export default OrderActivity;
